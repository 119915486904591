import { faDoorOpen, faEnvelopeOpenText, faShoppingBasket, faStore, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { DoAtualizarGrupos, DoLogIn } from '../../actions'
import User from '../../models/user'
import Container from '../container'

const user = new User();

export default function Menu() {
    const grupos = useSelector(state => state.grupos)
    const dispatch = useDispatch()

    const mostrarParcerias = () => {
        if (grupos?.includes("FORNECEDORES"))
            return <li className="list-group-item"><Link to={"/parcerias"}><FontAwesomeIcon icon={faStore} /> Minhas parcerias</Link></li>

        else
            return null
    }
    return (
        <Container>
            <h5>Opções</h5>
            <ul className="list-group">
                <li className="list-group-item"><Link to={"/usuarios/editar"}><FontAwesomeIcon icon={faUser} /> Meu perfil</Link></li>
                <li className="list-group-item"><Link to={"/pedidos"}><FontAwesomeIcon icon={faShoppingBasket} /> Meus pedidos</Link></li>
                {mostrarParcerias()}
                <li className="list-group-item">
                    <a href="mailto:contato@caseira.app?subject=Contato - caseira.app">
                        <FontAwesomeIcon icon={faEnvelopeOpenText} /> Contato
                    </a>
                </li>
                <li className="list-group-item"><Link
                    to={"/"}
                    className="text-danger"
                    onClick={() => {
                        dispatch(DoAtualizarGrupos(null))
                        user.logOut();
                        dispatch(DoLogIn({ loggedin: false }));
                    }}
                >
                    <FontAwesomeIcon icon={faDoorOpen} /> Sair
                  </Link>
                </li>
            </ul>
        </Container>
    )
}
