import React from "react"
import PropTypes from "prop-types";

export class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props)
      this.state = { hasError: false }
    }
  
    componentDidCatch() {
      // Display fallback UI
      this.setState({ hasError: true })
      // You can also log the error to an error reporting service
      //logErrorToMyService(error, info)
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
          <div className="bg-white rounded shadow p-3 m-3">
            <h1>Oooops...</h1>
            <p>Algo não saiu como esperado. Pedimos desculpas. :(</p>
            <p>Envie um email relatando o ocorrido para: <a href="mailto:contato@caseira.app">contato@caseira.app</a></p>
            <p>Ou, por favor, tente novamente mais tarde.</p>
            <p><a href="/">Clique aqui para voltar.</a></p>
          </div>)
      }
      return this.props.children
    }
  }

  ErrorBoundary.propTypes = {
    children: PropTypes.any,
  }