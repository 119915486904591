import React from "react";
import { Link } from "react-router-dom";
import Loading from "../loading";

import tiposIngredientes from "../../models/tipos.ingredientes";
import User from "../../models/user";
import PropTypes from "prop-types";

const user = new User();

export default class TableRowIngredientes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
    };
  }
  render() {
    const { processing } = this.state.processing;

    return (
      <tr>
        <td>{this.props.obj.nome}</td>
        <td>{this.getTipo()}</td>
        <td>
          <Link
            to={`/ingredientes/editar/${this.props.obj._id}`}
            className="btn btn-primary text-white"
          >
            Editar
          </Link>
        </td>
        <td>
          {this.canDelete()}
          {processing ? <Loading /> : null}
        </td>
      </tr>
    );
  }

  canDelete() {
    if (user.IsLoggedin) {
      return (
        <button
          onClick={() => {
            this.props.ingredienteExcluido(this.props.obj._id);
          }}
          className="btn btn-danger"
        >
          Excluir
        </button>
      );
    } else return null;
  }

  getTipo() {
    const tipo = tiposIngredientes.tiposIngredientes.find((i) => {
      return i.value === this.props.obj.tipo;
    });
    return typeof tipo === "undefined" ? "Não Encontrado" : tipo.label;
  }
}

TableRowIngredientes.propTypes = {
  obj: PropTypes.object,
  ingredienteExcluido: PropTypes.func,
};
