import React from "react";
import {  toast } from "react-toastify";
import comidaApi from "../../services/comida.api";
import Container from "../container";
import DescriptionReceita from "./description.receita.ingrediente";
import ModoPreparo from "./modoPreparo";
import NomeReceita from "./nomeReceita";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

var pesquisaTimmer

export default class CreateReceita extends React.Component {
  constructor(props) {
    super(props);

    this.onChangeNomeIngrediente = this.onChangeNomeIngrediente.bind(this);
    this.onIngredienteAdicionado = this.onIngredienteAdicionado.bind(this);
    this.onIngredienteExcluido = this.onIngredienteExcluido.bind(this);
    this.aoClicarAdicionar = this.aoClicarAdicionar.bind(this);
    this.onPreparoChange = this.onPreparoChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      receita: null,
      nome: "",
      ingredientes: [],
      preparo: "",
      adicionarIngredienteVisivel: false,
    };
  }

  aoClicarAdicionar() {
    this.setState({
      adicionarIngredienteVisivel: true,
    });
  }

  onChangeNomeIngrediente(e) {
    const nome = e.target.value;

    this.setState({
      nome
    });

    clearTimeout(pesquisaTimmer)

    pesquisaTimmer = setTimeout(() => {
      comidaApi.get(`receitas/existe/?q=${nome}`).then((resposta) => {
        this.setState({
          receita: resposta.data
        })
      }).catch(() => { })
    }, 500);
  }

  onSubmit(e) {
    e.preventDefault();

    const obj = {
      nome: this.state.nome,
      preparo: this.state.preparo,
      ingredientes: this.state.ingredientes,
    };

    comidaApi
      .post("receitas", obj)
      .then((resultado) => {
        toast.info("Receita criada com sucessso!");

        this.props.history.push("/receitas/" + resultado.data.slug);
      })
      .catch((erro) => {
        toast.warn(
          erro.response ? erro.response.data : "Erro ao cadastrar receita"
        );

        this.setState({
          processing: false,
        });
      });
  }

  onIngredienteAdicionado(e) {
    const ingrediente = this.state.ingredientes.find((i) => i._id === e._id);
    if (ingrediente) {
      toast.warn("Ingrediente existe na receita. Verifique.");
    } else {
      this.setState((s) => ({
        ingredientes: s.ingredientes.concat(e),
      }));
    }
  }

  onIngredienteExcluido(e) {
    this.setState({
      ingredientes: this.state.ingredientes?.filter((_, i) => i !== e),
    });
  }

  onPreparoChange(e) {
    this.setState({
      preparo: e.target.value,
    });
  }

  render() {
    return (
      <Container>
        <Helmet>
          <title>Nova Receita - caseira.app</title>
        </Helmet>
        
        <h5>
          <FontAwesomeIcon icon={faPencilAlt} /> Receita
        </h5>
        <form onSubmit={this.onSubmit}>
          <NomeReceita
            className="mb-3 col-md-10"
            onChange={this.onChangeNomeIngrediente}
            value={this.state.nome}
          />
          {this.state.receita &&
            <div className="alert alert-warning">
              Já existem uma receita com esse nome. Por favor, escolha outro nome.
            </div>
          }
          <div className="mb-3 col-md-10">
            <DescriptionReceita
              ingredientes={this.state.ingredientes}
              onIngredienteExcluido={this.onIngredienteExcluido}
              onIngredienteAdicionado={this.onIngredienteAdicionado}
            />
          </div>
          <ModoPreparo
            className="mb-3 col-md-10"
            value={this.state.preparo}
            onChange={this.onPreparoChange}
          />
          <div className="mb-3 col-md-10">
            <button type="submit" className="btn btn-primary text-white">
              Criar
            </button>
          </div>
        </form>
      </Container>
    );
  }
}

CreateReceita.propTypes = {
  history: PropTypes.object,
};
