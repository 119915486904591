import React from "react";
import comidaApi from "../../services/comida.api";
import { DoAtualizarMenu, DoLogIn, DoOcultarMenu } from "../../actions";
import { connect } from "react-redux";
import User from "../../models/user";
import Email from "../login/email";
import FormValidator from "../FormValidator";
import Message from "../message";
import PropTypes from "prop-types";
import { validacaoEmail, validacaoNome, validacaoSenha } from "./validacoes";
import { Link } from "react-router-dom";
import BotaoFacebookLogin from "./facebook.login.button";
import Container from "../container";
import queryString from "query-string";

const user = new User();

function mapDispatchToProps(dispatch) {
  return {
    DoLogIn: (nome, grupos) =>
      dispatch(DoLogIn({ loggedin: true, nome, grupos })),
    DoOcultarMenu: () => dispatch(DoOcultarMenu()),
    DoAtualizarMenu: () => dispatch(DoAtualizarMenu())
  };
}

class ConnectedSignin extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.facebookResponse = this.facebookResponse.bind(this);

    let validacoes = [];

    validacoes = validacoes.concat(validacaoNome);

    validacoes = validacoes.concat(validacaoEmail);

    validacoes = validacoes.concat(validacaoSenha);

    this.validator = new FormValidator(validacoes);

    this.state = {
      nome: "",
      email: "",
      password: "",
      validation: this.validator.valid(),
      message: "",
      processing: false,
    };

    this.submitted = false;
  }

  componentDidMount() {
    this.props.DoOcultarMenu()
  }

  componentWillUnmount() {
    this.props.DoAtualizarMenu();
  }

  handleInputChange(e) {
    e.preventDefault();

    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      message: "",
      processing: true,
    });

    let validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      const obj = {
        nome: this.state.nome,
        email: this.state.email,
        password: this.state.password,
      };

      this.cadastrar(obj);
    } else {
      this.setState({
        processing: false,
      });
    }
  }

  cadastrar(obj) {
    comidaApi
      .post("users", obj)
      .then((res) => {
        const destino = queryString.parse(this.props.location.search).q;

        user.Information = res.data;
        this.props.DoLogIn(res.data.user.nome, res.data.user.grupos);
        if (destino) this.props.history.push(destino);
        else this.props.history.push("/planejamento");
      })
      .catch((erro) => {
        this.setState({
          message: erro.response
            ? erro.response.data
            : "Erro ao cadastrar usuário. Tente novamente mais tarde.",
          processing: false,
        });
      });
  }

  facebookResponse(resultado) {
    this.props.DoLogIn(resultado.data.user.nome, resultado.data.user.grupos);
    this.props.history.push("/usuarios/editar");
  }

  render() {
    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    return (
      <Container>
        <h3>Cadastre-se</h3>
        <p className="text-secondary mb-4">
          Comece a <strong>planejar sua alimentação</strong> de uma maneira simples e prática.<br />É grátis!
        </p>
        <div className={`mb-4`}>
          <BotaoFacebookLogin aoLogar={this.facebookResponse} />
        </div>
        <form onSubmit={this.onSubmit}>
          <small className="d-block mb-3">ou cadatre-se com:</small>
          <div className="row">
            <div className="mb-3 col-md-6">
              <label className="w-100">
                Nome Completo
                <input
                  name="nome"
                  type="text"
                  className="form-control"
                  value={this.state.nome}
                  onChange={this.handleInputChange}
                />
              </label>
              <span className="text-danger">{validation.nome?.message}</span>
            </div>
          </div>
          <div className="row">
            <Email
              name="email"
              onChangeEmail={this.handleInputChange}
              email={this.state.email}
              validation={validation.email?.message}
            />
          </div>
          <div className={this.state.editando ? "d-none" : "row"}>
            <div className="mb-3 col-md-6">
              <label className="w-100">
                Escolha uma Senha
                <input
                  name="password"
                  type="password"
                  className="form-control"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                />
              </label>
              <span className="text-danger">
                {validation.password?.message}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="mb-3 col-md-6">
              <Message message={this.state.message} />
              <button
                type="submit"
                className="btn btn-primary text-white"
                disabled={this.state.processing}
              >
                {this.state.editando ? "Atualizar" : "Cadastrar"}
              </button>
            </div>
          </div>
        </form>
        <address className="text-secondary small">
          Ao clicar em Cadastrar, você concorda com nossa{" "}
          <Link to="/politica">política de uso de dados</Link> e{" "}
          <Link to="/termo">termos gerais</Link>.
        </address>
      </Container>
    );
  }
}

ConnectedSignin.propTypes = {
  DoLogIn: PropTypes.func,
  DoOcultarMenu: PropTypes.func,
  DoAtualizarMenu: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
};

const SignIn = connect(null, mapDispatchToProps)(ConnectedSignin);

export default SignIn;
