import React from "react";
import PropTypes from "prop-types";

export default class Email extends React.Component {
  render() {
    return (
      <div className="mb-3 col-md-6">
        <label className="w-100">
          E-mail
          <input
            name={this.props.name}
            type="email"
            className="form-control"
            value={this.props.email}
            onChange={this.props.onChangeEmail}
            onBlur={this.onBlur}
          />
        </label>
        <span className="text-danger">{this.props.validation}</span>
      </div>
    );
  }
}

Email.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  onChangeEmail: PropTypes.func,
  validation: PropTypes.string,
};
