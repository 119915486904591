import React from 'react'
import PropTypes from 'prop-types'

function NomeReceita(props) {
    return (
        <div className={props.className || "mb-3 col-md-6"}>
              <label className="w-100">
                Nome da receita
                <input
                  name="nome"
                  type="text"
                  className="form-control"
                  value={props.value}
                  onChange={props.onChange}
                  disabled={props.disabled}
                />
              </label>
            </div>
    )
}

NomeReceita.propTypes = {
    disabled: PropTypes.bool,
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default NomeReceita

