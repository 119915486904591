import React from "react"
import IngredienteReceita from "./receita.ingrediente"
import { Card } from "react-bootstrap"
import AddIngrediente from "../ingredientes/add.ingrediente"
import PropTypes from 'prop-types'
import BotaoRetirar from "../botaoRetirar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCarrot } from "@fortawesome/free-solid-svg-icons"

export default class DescriptionReceita extends React.Component {

  render() {
    return (
      <ul className="list-group list-group-flush">
        {this.getIngredientes()}
      </ul>
    )
  }

  getIngredientes() {

    return (
      <Card>
        <Card.Header><FontAwesomeIcon icon={faCarrot} /> Ingredientes</Card.Header>
        <Card.Body>
          <AddIngrediente onIngredienteAdicionado={this.props.onIngredienteAdicionado} />
          {
            this.props.ingredientes?.map((item, i) => (<li key={item._id + i} className="list-group-item d-flex justify-content-between align-items-center">
              <IngredienteReceita ingrediente={item} /><BotaoRetirar onClick={() => this.props.onIngredienteExcluido(i)} />
            </li>))
          }
        </Card.Body>
      </Card>
    )
  }
}

DescriptionReceita.propTypes = {
  onIngredienteAdicionado: PropTypes.func,
  onIngredienteExcluido: PropTypes.func,
  ingredientes: PropTypes.array,
}