import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Toast } from "react-bootstrap";
import AddReceita from "../receitas/add.receita";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

function PersonalizarCardapio(props) {
  const [alerta, setAlerta] = useState({ visivel: false });
  const [item, setItem] = useState({ fornecedor: props.fornecedor });

  useEffect(() => {
    if (props.value) {
      setItem({
        ...props.value,
      });
    }
  }, [props.value]);

  return (
    <Modal show={props.visivel} onHide={props.aoOcultar}>
      <Modal.Header closeButton>
        <Modal.Title>{item.nome}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Toast
          className="bg-warning"
          show={alerta.visivel}
          onClose={() => {
            setAlerta({ visivel: false });
          }}
        >
          <Toast.Header>
            <strong className="me-auto">Erro</strong>
          </Toast.Header>
          <Toast.Body>{alerta.mensagem}</Toast.Body>
        </Toast>
        <form onSubmit={enviar}>
          <p>
            <span className="text-info">{item.descricao}</span>
          </p>
          {item.opcoesPeso?.length > 1 ? (
            <label>
              Qual tamanho?
              <Select
                options={item.opcoesPeso.map((p) => ({
                  label: `${p} gramas`,
                  value: p,
                }))}
              />
            </label>
          ) : null}
          <div className="mb-3">
            <label className="w-100">
              Como você quer esta refeição?
              <br />
              <small>Escolha os itens dessa refeição.</small>
            </label>
            <AddReceita
              onChange={(e) => {
                setItem({
                  ...item,
                  personalizacao: e.map((r) => {
                    return { _id: r.value, nome: r.label };
                  }),
                });
              }}
            />
            <div className="text-success mt-4 text-right">
              <strong>
                {item.preco ? (
                  item.preco.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                ) : (
                  <span className="text-info">A combinar</span>
                )}
              </strong>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary text-white" onClick={enviar}>
          <span>
            <FontAwesomeIcon icon={faCheck} /> Adicionar
          </span>
        </Button>
        <Button variant="outline-secondary" onClick={props.aoOcultar}>
          <FontAwesomeIcon icon={faTimes} /> Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function enviar(evento) {
    evento.preventDefault();

    if (!item.personalizacao || item.personalizacao.length === 0)
      setAlerta({
        visivel: true,
        mensagem: "Selecione quais itens você quer nessa refeição.",
      });

    if (props.aoAdicionar) props.aoAdicionar(item);
  }
}

PersonalizarCardapio.propTypes = {
  visivel: PropTypes.bool,
  aoOcultar: PropTypes.func,
  fornecedor: PropTypes.string,
  aoAdicionar: PropTypes.func,
  value: PropTypes.object,
};

export default PersonalizarCardapio;
