import React from "react";
import PropTypes from "prop-types";
import User from "../models/user";
import { Link } from "react-router-dom";

const user = new User();

function Promocao(props) {
  if (props.promocao)
    return (
      <div className="alert alert-success">
        <h5 className="alert-heading">{props.promocao.titulo}</h5>
        <hr />
        <p>
          {user.IsLoggedin ? mostrarPromocao() :
          mostrarAcesse()}
        </p>
        <p>
          <small>{props.promocao.observacao}</small>
        </p>
      </div>
    );
  else return null;

  function mostrarPromocao() {
    return props.promocao.descricao
      .split("#")
      .map((conteudo, index) => index % 2 === 0 ? (
        <span>{conteudo}</span>
      ) : (
          <strong>{conteudo}</strong>
        )
      );
  }

  function mostrarAcesse() {
    return <Link to={`/login?q=${props.location.pathname}`} className="alert-success"><strong>Clique aqui,</strong> acesse e saiba mais.</Link>
  }
}

Promocao.propTypes = {
  promocao: PropTypes.object,
  location: PropTypes.object
};

export default Promocao;
