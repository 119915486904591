import axios from "axios";
import User from "../models/user";

const user = new User();

const api = axios.create({
  baseURL: getBase(),
});

function getBase() {
  if (process.env.REACT_APP_ENV !== "development")
    return "https://api.caseira.app/";
  else return `http://192.168.15.156:5500/`;
}

api.interceptors.request.use(async (config) => {
  if (user.IsLoggedin) {
    config.headers.Authorization = `Bearer ${user.Information.token}`;
  }

  return config;
});

export function agruparItens(itens) {
  let retorno = itens?.reduce((agrupamento, item) => {
      const existe = agrupamento[item.grupo];
      if (!existe && item.grupo && item.grupo !== "")
        agrupamento[item.grupo] = item.grupo;
      return agrupamento;
    }, {})

  return Object.values(retorno || {});
}

export default api;
