import React from "react";
import comidaApi from "../../services/comida.api";
import {  toast } from "react-toastify";
import Loading from "../loading";
import Select from "react-select";
import tiposIngredientes from "../../models/tipos.ingredientes";
import Container from "../container";

export default class CreateIngredientes extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeNomeIngrediente = this.onNomeIngrediente.bind(this);
    this.onChangeTipoIngrediente = this.onTipoIngrediente.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      nome: "",
      tipo: "",
      processing: false,
    };
  }

  onNomeIngrediente(e) {
    this.setState({
      nome: e.target.value,
    });
  }

  onTipoIngrediente(e) {
    this.setState({
      tipo: e.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({ processing: true });

    const obj = {
      nome: this.state.nome,
      tipo: this.state.tipo,
    };

    comidaApi.post("ingredientes", obj).then(() => {
      toast.info("Ingrediente criado com sucesso!");

      this.setState({ processing: false });
    });

    this.setState({
      nome: "",
      tipo: "",
    });
  }

  render() {
    const { processing } = this.state.processing;
    const { tipo } = this.state.tipo;

    return (
      <Container>
        
        <h5>Novo Ingrediente</h5>
        <form onSubmit={this.onSubmit}>
          <div className="mb-3">
            <label className="w-100">
              Nome
              <input
                type="text"
                className="form-control"
                value={this.state.nome}
                onChange={this.onChangeNomeIngrediente}
              />
            </label>
          </div>
          <div className="mb-3">
            <Select
              value={tipo}
              placeholder="Selecione um tipo..."
              onChange={this.onChangeTipoIngrediente}
              options={tiposIngredientes.tiposIngredientes}
            />
          </div>
          <div className="mb-3">
            <button
              type="submit"
              className="btn btn-primary text-white w-25"
              disabled={processing}
            >
              Cadastrar
            </button>
            {processing ? <Loading /> : null}
          </div>
        </form>
      </Container>
    );
  }
}
