import React, { useState } from "react"
import comidaApi from "../../services/comida.api"
import PropTypes from "prop-types"
import { Popover, OverlayTrigger, Button } from "react-bootstrap"
import { Draggable } from "react-beautiful-dnd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle, faTrash } from "@fortawesome/free-solid-svg-icons"


export default function ItemReceita(props) {
  const [visivel, setVisivel] = useState(true);

  return (
    <Draggable
      draggableId={`${props.receita._id._id}_${props.dia}_${props.receita.refeicao}`}
      index={props.index}
    >
      {(provided) => (
        <div
          className={visivel ? "card shadow p-2 mb-2" : "d-none"}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <input
                type="number"
                className="text-center"
                style={{ width: "3.5rem" }}
                value={props.receita.quantidade}
                onFocus={(event) => {
                  event.target.select();
                }}
                onChange={(event) => {
                  const valor = parseFloat(event.target.value);

                                    if (!Number.isNaN(valor)) {
                                        comidaApi.put(`planejamento/${props.planejamento}/${props.refeicao}/${props.receita._id._id}/${event.target.value}/${props.user._id}`)
                                            .then(() => {
                                                if (props.aoAtualizarQuantidade)
                                                    return props.aoAtualizarQuantidade(props.receita, valor)
                                            })
                                            .catch(() =>{
                                                if (props.aoAtualizarQuantidade)
                                                    return props.aoAtualizarQuantidade(props.receita, 0)
                                            })
                                    }
                                    else {
                                        if (props.aoAtualizarQuantidade)
                                            props.aoAtualizarQuantidade(props.receita, 0)
                                    }
                                }} />
                            {props.receita._id.rendimento && props.quantidadePlanejada % props.receita._id.rendimento !== 0
                                ? <OverlayTrigger
                                    trigger="click"
                                    placement="bottom"
                                    overlay={PopOver}
                                    popperConfig={
                                        {
                                            rendimento: props.receita._id?.rendimento,
                                            selecionado: props.quantidadePlanejada
                                        }
                                    }>
                                    <Button variant="outline-light" size="sm">
                                        <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning" />
                                    </Button>
                                </OverlayTrigger>
                                : null}
                        </div>
                        <div className="w-100 mx-1 text-center btn" onClick={props.aoSelecionarReceita}><small>{props.receita.nome}</small></div>
                        <Button
                            variant="outline-light"
                            size="sm"
                            onClick={() => {
                                comidaApi.delete(`planejamento/${props.planejamento}/${props.refeicao}/${props.receita._id._id}/${props.user._id}`)
                                    .then(() => {
                                        setVisivel(false)
                                        props.receitaDeletada(props.receita)
                                    })
                                    .catch(() => {
                                        props.receitaDeletada(undefined)
                                    })
                            }}>
                            <FontAwesomeIcon icon={faTrash} className="text-danger" />
                        </Button>
                    </div>
                </div>
            )
            }
        </Draggable >
    )
}

function PopOver(props) {
  let rendimento = 0;
  let selecionado = 0;
  if (props.popper.state) {
    rendimento = props.popper.state.options.rendimento;
    selecionado = props.popper.state.options.selecionado;
  }

  const conteudo = (
    <Popover.Body>
      <p>
        Essa receita{" "}
        <strong>
          vai render {rendimento * Math.ceil(selecionado / rendimento)} porções
        </strong>{" "}
        e você <strong>planejou só {selecionado}.</strong>
      </p>
      <ul>
        <li>Considere utilizar esta receita outros dias.</li>
        <li>Algumas receitas podem ser congeladas para outras semanas.</li>
      </ul>
    </Popover.Body>
  );

  return (
    <Popover id="popover-basic" {...props}>
      <Popover.Header as="h3">Vai sobrar...</Popover.Header>
      {conteudo}
    </Popover>
  );
}

PopOver.propTypes = {
  porcoes: PropTypes.number,
  rendimento: PropTypes.number,
  popper: PropTypes.any,
};

ItemReceita.propTypes = {
  receita: PropTypes.object,
  aoSelecionarReceita: PropTypes.func,
  quantidadeAtualizada: PropTypes.func,
  receitaExcluida: PropTypes.func,
  planejamento: PropTypes.string,
  refeicao: PropTypes.string,
  quantidadePlanejada: PropTypes.number,
  aoAtualizarQuantidade: PropTypes.func,
  dia: PropTypes.string,
  index: PropTypes.number,
  user: PropTypes.object,
  receitaDeletada: PropTypes.func,
  popper: PropTypes.any,
};
