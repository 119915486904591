import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from "prop-types";
import { ConsultarQuantidadeTotalSacola } from '../actions/cardapio'

export default function BotaoSacola(props) {
    const sacola = useSelector(state => state.sacola)

    return (
        <Link to="/sacola" className={props.className || "text-primary"} style={props.style}>
            <FontAwesomeIcon icon={faShoppingBasket} size="lg" />
            <span className="badge bg-primary">
                {ConsultarQuantidadeTotalSacola(sacola)}
            </span>
        </Link>
    )
}

BotaoSacola.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}
