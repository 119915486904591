import React from "react";
import comidaApi from "../../services/comida.api";
import User from "../../models/user";
import Email from "./email";
import { DoAtualizarMenu, DoLogIn, DoOcultarMenu } from "../../actions";
import { connect } from "react-redux";
import FormValidator from "../FormValidator";
import Message from "../message";
import queryString from "query-string";
import { LoadingButton } from "../loading.button";
import PropTypes from "prop-types";
import RecuperarSenha from "./recuperarSenha";
import BotaoFacebookLogin from "./facebook.login.button";
import Container from "../container";
import { Link } from "react-router-dom";

const user = new User();

function mapDispatchToProps(dispatch) {
  return {
    DoLogIn: (nome, grupos) => dispatch(DoLogIn({ loggedin: true, nome, grupos })),
    DoOcultarMenu: () => dispatch(DoOcultarMenu()),
    DoAtualizarMenu: () => dispatch(DoAtualizarMenu())
  };
}

class ConnectedIndexLogin extends React.Component {
  constructor(props) {
    super(props);

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeSenha = this.onChangeSenha.bind(this);
    this.facebookResponse = this.facebookResponse.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.validator = new FormValidator([
      {
        field: "email",
        method: "isEmpty",
        validWhen: false,
        message: "Informe seu e-mail.",
      },
      {
        field: "email",
        method: "isEmail",
        validWhen: true,
        message: "Informe um e-mail válido.",
      },
      {
        field: "password",
        method: "isEmpty",
        validWhen: false,
        message: "Informe uma senha para seu acesso.",
      },
    ]);

    this.state = {
      email: "",
      password: "",
      validation: this.validator.valid(),
      message: "",
      processing: false,
      loginTerceiros: { facebook: false },
    };

    this.submitted = false;
  }

  componentDidMount() {
    this.props.DoOcultarMenu();
  }

  componentWillUnmount() {
    this.props.DoAtualizarMenu();
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangeSenha(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    let validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      this.setState({
        processing: true,
      });

      const obj = {
        email: this.state.email,
        password: this.state.password,
      };

      this.login(obj);
    }
  }

  login(obj) {
    comidaApi
      .post("login", obj)
      .then((res) => {
        let values = queryString.parse(this.props.location.search);

        user.Information = res.data;
        this.props.DoLogIn(res.data.user.nome, res.data.user.grupos);
        if (values.q) this.props.history.push(values.q);
        else this.props.history.push("/planejamento");
      })
      .catch((erro) => {
        this.setState({
          mensagem: erro.response
            ? erro.response.data
            : "Erro ao realizar o login.",
          processing: false,
        });
      });
  }

  showMessage() {
    if (this.state.mensagem) {
      return (
        <div className="alert alert-warning" role="alert">
          {this.state.mensagem}
        </div>
      );
    }
  }

  render() {
    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    return (
      <Container>
        <h3>Já possui cadastro?</h3>
        <p className="text-secondary mb-4">
          Então preencha os campos abaixo para continuar.
        </p>
        {this.showMessage()}
        <form onSubmit={this.onSubmit}>
          <div className="row">
            <Email
              onChangeEmail={this.onChangeEmail}
              validation={validation.email.message}
            />
          </div>
          <div className="row">
            <div className="mb-3 col-md-6">
              <label className="w-100">
                Senha
                <input
                  type="password"
                  className="form-control"
                  value={this.state.password}
                  onChange={this.onChangeSenha}
                />
              </label>
              <span className="text-danger">{validation.password.message}</span>
            </div>
          </div>
          <div className="row">
            <div className="mb-3 col-md-6">
              <button
                type="submit"
                className="btn btn-primary text-white"
                disabled={this.state.processing}
              >
                {this.state.processing ? LoadingButton() : "Acessar"}
              </button>
              <RecuperarSenha />
            </div>
          </div>
          <Message message={this.state.message} />
        </form>
        <div className={`mb-2 small`}>
          Ainda não se cadastrou? <Link to={`/usuarios/cadastro?q=${queryString.parse(this.props.location.search).q || ""}`}>Clique aqui</Link>.
        </div>
        <div className={`mb-2 border-top border-primary`}>
          <span className="small">ou acesse com:</span>
          <br />
          <BotaoFacebookLogin aoLogar={this.facebookResponse} />
        </div>
      </Container>
    );
  }

  facebookResponse(resultado) {
    let values = queryString.parse(this.props.location.search);
    this.props.DoLogIn(resultado.data.user.nome, resultado.data.user.grupos);
    if (values.q) this.props.history.push(values.q);
    else this.props.history.push("/planejamento");
  }
}

ConnectedIndexLogin.propTypes = {
  location: PropTypes.object,
  DoLogIn: PropTypes.func,
  DoOcultarMenu: PropTypes.func,
  DoAtualizarMenu: PropTypes.func,
  history: PropTypes.object,
};

const IndexLogin = connect(null, mapDispatchToProps)(ConnectedIndexLogin);

export default IndexLogin;
