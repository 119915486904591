export default class PlanoReceita {

    get Nome() {
        return this.nome;
    }

    set Nome(value) {
        this.nome = value;
    }

    get ID() {
        return this._id;
    }

    set ID(value) {
        this._id = value;
    }

    get Refeicao() {
        return this.refeicao;
    }

    set Refeicao(value) {
        this.refeicao = value;
    }

    get Quantidade() {
        return this.quantidade;
    }

    set Quantidade(value) {
        this.quantidade = value;
    }
}
