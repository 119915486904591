import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

function SelecaoOpcaoPeso(props) {
  const [peso, setPeso] = useState(null);

  return (
    <Modal show={props.visivel} onHide={props.aoOcultar} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Qual tamanho?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-light">
          <thead>
            <tr>
              <th scope="col">Peso</th>
              <th scope="col">Preço</th>
              <th scope="col">Selecionar</th>
            </tr>
          </thead>
          <tbody>{props.value ? mostrarOpcoes() : null}</tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary text-white"
          onClick={() =>
            props.enviar ? props.enviar(peso) : props.aoOcultar()
          }
        >
          <FontAwesomeIcon icon={faCheck} /> Adicionar
        </Button>
        <Button variant="outline-secondary" onClick={props.aoOcultar}>
          <FontAwesomeIcon icon={faTimes} /> Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function mostrarOpcoes() {
    let linhas = [];

    for (const tamanho in props.value) {
      if (Object.prototype.hasOwnProperty.call(props.value, tamanho)) {
        let t = props.value[tamanho];
        t.tamanho = tamanho;

        if (t.peso && t.peso !== "")
          linhas.push(
            <tr key={t.peso}>
              <td>
                {t.peso}{" "}
                <small>
                  g<span className="d-none d-sm-inline">ramas</span>
                </small>
              </td>
              <td>
                {t.preco.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
              <td>
                <input type="radio" name="selecao" onClick={() => setPeso(t)} />
              </td>
            </tr>
          );
      }
    }

    return linhas;
  }
}

SelecaoOpcaoPeso.propTypes = {
  visivel: PropTypes.bool,
  aoOcultar: PropTypes.func,
  value: PropTypes.object,
  enviar: PropTypes.func,
};

export default SelecaoOpcaoPeso;
