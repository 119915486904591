import React, { useEffect, useState } from "react";
import Container from "../container";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AdicionarSacola from "../cardapios/adicionar.sacola";
import ItemCardapio from "../cardapios/item.cardapio";
import { DoExcluirItemSacola, DoIncluirItemSacola, DoLimparSacola } from "../../actions/cardapio";
import { Button } from "react-bootstrap";
import User from "../../models/user";
import { consultarFornecedores } from "../../routes";
import InformacoesAdicionais from "../pedidos/informacoesAdicionais";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";

const user = new User();

function mapDispatchToProps(dispatch) {
  return {
    DoIncluirItemSacola: (item) => dispatch(DoIncluirItemSacola(item)),
    DoExcluirItemSacola: (item) => dispatch(DoExcluirItemSacola(item)),
    DoLimparSacola: () => dispatch(DoLimparSacola()),
  };
}

function ConnectedIndexSacola(props) {
  const sacola = useSelector((state) => state.sacola);
  const [total, setTotal] = useState(0);
  const [complementando, setComplementando] = useState(false);

  let itens = Array.prototype.concat(...Object.values(sacola || {}));

  function quantidadeAlterada(item) {
    return (valor) => {
      if (valor > 0)
        props.DoIncluirItemSacola(item)
      else
        props.DoExcluirItemSacola(item)
    };
  }

  useEffect(() => {
    setTotal(
      itens ? itens.reduce((total, item) => (total += (item.quantidade || 1) * item.preco), 0) : 0
    );
  }, [itens]);

  function mostrarItens() {
    return (
      <div>
        <div className="row">
          {itens.map((item, index) => {
            return (
              <div key={index} className="col-6 col-md-4 col-lg-3 p-2">
                <ItemCardapio item={item} className="h-100">
                  <AdicionarSacola
                    value={item.quantidade || 1}
                    onChange={quantidadeAlterada(item)}
                  />
                </ItemCardapio>
              </div>
            );
          })}
        </div>
        <p className="p-3">
          <small>Total: </small>
          <strong>
            {total.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </strong>
        </p>
        <div className="text-right">
          <Button
            variant="outline-success"
            className="m-2"
            onClick={() => {
              props.history.push(consultarFornecedores());
            }}
          >
            Continuar Comprando
          </Button>
          <Link className="btn btn-outline-success m-2" to={`/ingredientes/lista/${dayjs().format("YYYY-MM-DD")}/`}>
            Ver Lista de Compras
          </Link>
          <Button
            className="m-2 text-white"
            onClick={() => {
              if (!user.IsLoggedin) {
                props.history.push(`/login?q=${props.location.pathname}`);
                return;
              }

              setComplementando(true);
            }}
          >
            <strong>Enviar Pedido</strong>
          </Button>
          <InformacoesAdicionais
            visivel={complementando}
            {...props}
            aoOcultar={() => setComplementando(false)}
            user={user.Information.user._id}
            itens={itens}
          />
        </div>
      </div>
    );
  }

  function mostrarMensagemSemItens() {
    return (
      <div className="alert alert-info">
        <p><strong>Sua sacola está vazia.</strong></p>
        <p>Conheça nossos parceiros e faça seu pedido.{" "}
          <Link to={consultarFornecedores()}>Clique aqui.</Link>
        </p>
      </div>
    );
  }

  return (
    <Container>
      <Helmet>
        <title>Sacola de Compras - caseira.app</title>
      </Helmet>
      <h5>
        <FontAwesomeIcon icon={faShoppingBasket} /> Sacola de Compras
      </h5>
      <div>{itens && itens.length ? mostrarItens() : mostrarMensagemSemItens()}</div>
    </Container>
  );
}

ConnectedIndexSacola.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  DoExcluirItemSacola: PropTypes.func,
  DoIncluirItemSacola: PropTypes.func,
};

const IndexSacola = connect(null, mapDispatchToProps)(ConnectedIndexSacola);

export default IndexSacola;
