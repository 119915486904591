import React from "react";
import comidaApi from "../../services/comida.api";
import Loading from "../loading";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Toast } from "react-bootstrap";
import Plano from "../../models/plano";
import PlanoReceita from "../../models/planoReceita";
import PropTypes from "prop-types";
import AddReceita from "../receitas/add.receita";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

export default class AdicionarPlano extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processando: false,
      diaSelecionado: new Date().toISOString().substring(0, 10),
      alerta: {
        visivel: false,
        titulo: "",
        mensagem: "",
      },
      receitas: [],
      refeicaoSelecionada: null,
      receitasSelecionadas: [],
    };

    this.aoCarregarReceitas = this.aoCarregarReceitas.bind(this);
    this.ocultarAlerta = this.ocultarAlerta.bind(this);
    this.aoSelecionarRefeicao = this.aoSelecionarRefeicao.bind(this);
    this.enviar = this.enviar.bind(this);
    this.cardapioAtualizado = this.cardapioAtualizado.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.receitasIniciais &&
      this.props.receitasIniciais[0]?.value !==
        prevProps.receitasIniciais[0]?.value
    ) {
      const receitasSelecionadas = this.state.receitasSelecionadas;
      receitasSelecionadas.push(...this.props.receitasIniciais);
      this.setState({
        receitasSelecionadas,
      });
    }
  }

  aoSelecionarRefeicao(refeicaoSelecionada) {
    this.setState({
      refeicaoSelecionada: refeicaoSelecionada,
    });
  }

  aoCarregarReceitas(valor) {
    return new Promise((resolve) => {
      resolve(
        comidaApi
          .get(`receitas?q=${valor}`)
          .then((res) => {
            return res.data.docs.map((r) => ({ value: r._id, label: r.nome }));
          })
          .catch(() => {
            alert(
              `Erro ao consultar receitas.\nHouve um erro ao consultar receitas com nome ${valor}. Por favor, tente novamente mais tarde.`
            );
          })
      );
    });
  }

  ocultarAlerta() {
    this.setState({
      alerta: { visivel: false },
    });
  }

  cardapioAtualizado(receitas) {
    this.setState({
      receitasSelecionadas: receitas,
    });
  }

  enviar() {
    this.setState(
      {
        processando: true,
      },
      () => {
        const refeicao =
          this.props.refeicao || this.state.refeicaoSelecionada?.value;

        if (!this.state.receitasSelecionadas.length || !refeicao)
          this.setState({
            alerta: {
              visivel: true,
              titulo: "Planejamento incompleto.",
              mensagem: `Selecione a Refeição e o Cardápio.`,
            },
          });
        else {
          const receitas = this.state.receitasSelecionadas.map((receita) => {
            let plano = new PlanoReceita();
            plano.ID = receita.value;
            plano.Nome = receita.label;
            plano.Refeicao = refeicao;
            plano.Quantidade = receita.quantidade;

            return plano;
          });

          const obj = new Plano();
          obj.Dia = this.props.diaSelecionado || this.state.diaSelecionado;
          obj.Receitas = receitas;

          if (this.props.user) {
            if (typeof this.props.user === "object")
              obj.User = this.props.user._id;
            else obj.User = this.props.user;
          }

          comidaApi
            .post("planejamento", obj)
            .then(() => {
              if (this.props.planoCriado) this.props.planoCriado(obj);
            })
            .catch((erro) => {
              this.setState({
                alerta: {
                  visivel: true,
                  titulo: "Erro - Planejamento",
                  mensagem:
                    erro.response?.data ||
                    "Houve um erro ao criar este plano. Por favor, tente novamente mais tarde.",
                },
              });
            });
        }
      }
    );
  }

  render() {
    const diaHabilitado = this.props.diaSelecionado ? false : true;
    const diaSelecionando = diaHabilitado
      ? this.state.diaSelecionado
      : dayjs(this.props.diaSelecionado.substring(0, 10)).format("YYYY-MM-DD");

    const refeicaoHabilitada = this.props.refeicao ? false : true;
    const refeicao = refeicaoHabilitada
      ? this.state.refeicaoSelecionada
      : this.props.refeicao && {
          label: this.props.refeicao,
          value: this.props.refeicao,
        };

    return (
      <div className={this.props.className}>
        <Modal show={this.props.visivel} onHide={this.props.aoOcultar}>
          <Modal.Header closeButton>
            <Modal.Title>Planejar refeição</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Toast
              show={this.state.alerta.visivel}
              onClose={this.ocultarAlerta}
            >
              <Toast.Header>
                <strong className="me-auto">{this.state.alerta.titulo}</strong>
              </Toast.Header>
              <Toast.Body>{this.state.alerta.mensagem}</Toast.Body>
            </Toast>
            <form>
              <div className="mb-3">
                <label className="w-100">
                  Quando?
                  {diaHabilitado ? (
                    <input
                      type="date"
                      className="form-control"
                      value={diaSelecionando}
                      onChange={(e) => {
                        this.setState({
                          diaSelecionado: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <strong>
                      <br />
                      {dayjs(diaSelecionando)
                        .locale("pt-br")
                        .format("dddd, DD MMMM")}
                    </strong>
                  )}
                </label>
              </div>
              <div className="mb-3">
                <label className="w-100">
                  Em qual refeição?
                  {refeicaoHabilitada ? (
                    <Select
                      value={refeicao}
                      noOptionsMessage={() => "Sem opções"}
                      placeholder="Ex.: Almoço"
                      options={this.props.refeicoes.map((r) => ({
                        value: r.nome,
                        label: r.nome,
                      }))}
                      onChange={this.aoSelecionarRefeicao}
                    ></Select>
                  ) : (
                    <strong>
                      <br />
                      {refeicao.label}
                    </strong>
                  )}
                </label>
              </div>
              <div className="mb-3">
                <label className="w-100">
                  Cardápio <br />
                  <small>(selecione os alimentos desta refeição)</small>
                </label>
                <div
                  className={
                    this.props.receitasIniciais ||
                    this.state.receitasSelecionadas.length > 0
                      ? `d-flex justify-content-between px-4`
                      : "d-none"
                  }
                >
                  <small>Pessoas</small>
                  <small>Receita</small>
                  <small>Ação</small>
                </div>
                <AddReceita
                  receitasIniciais={this.props.receitasIniciais}
                  onChange={(receitas) => this.cardapioAtualizado(receitas)}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary text-white"
              disabled={this.state.processing}
              onClick={this.enviar}
            >
              {this.state.processing ? (
                <Loading />
              ) : (
                <span>
                  <FontAwesomeIcon icon={faCheck} /> Adicionar
                </span>
              )}
            </Button>
            <Button variant="outline-secondary" onClick={this.props.aoOcultar}>
              <FontAwesomeIcon icon={faTimes} /> Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

AdicionarPlano.propTypes = {
  receitasIniciais: PropTypes.array,
  refeicao: PropTypes.string,
  diaSelecionado: PropTypes.string,
  user: PropTypes.object,
  visivel: PropTypes.bool,
  aoOcultar: PropTypes.func,
  refeicoes: PropTypes.array,
  planoCriado: PropTypes.func,
  className: PropTypes.string,
};
