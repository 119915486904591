import React from "react";
import Routes from "./routes";
import Menu from "./components/menu";
import { ErrorBoundary } from "./components/error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import assinarNotificao from "./services/push";
import { ToastContainer } from "react-toastify";

class App extends React.Component {
  componentDidMount() {
    assinarNotificao();
  }

  render() {
    return (
      <div className="d-flex flex-column h-100 container-fullwidth">
        <header>
          <Menu />
        </header>
        <main className="flex-shrink-0" role="main">
          <ToastContainer
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            limit={3} />
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </main>
        <footer className="footer mt-auto py-3 bg-white">
          <div className="container">
            <a
              href="https://facebook.com/caseiraapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} /> caseiraapp
            </a>
            <a
              href="https://instagram.com/caseira.app"
              target="_blank"
              rel="noopener noreferrer"
              className="ms-3"
            >
              <FontAwesomeIcon icon={faInstagram} /> caseira.app
            </a>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
