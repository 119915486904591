import comidaApi from "./comida.api";

const publicVapidKey =
  "BI4Wp23nIDSzDLBbOYRgSePXySTrKjPrK9H5Gb8l7KEngt_vMB4TxIWjA-UCLb0MOtG4aovNYmpwKJGpNQDCvJk";

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = typeof window != "undefined" ? window.atob(base64) : null;
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export default async function assinarNotificao() {
  if (navigator.serviceWorker) {
    await navigator.serviceWorker.register("/notificacaoServiceWorker.js", {
      scope: "/",
    });

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready
        .then(function (registration) {
          if (!registration.pushManager) {
            return;
          }

          registration.pushManager
            .getSubscription()
            .then(function (existedSubscription) {
              if (existedSubscription === null) {
                registration.pushManager
                  .subscribe({
                    applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
                    userVisibleOnly: true,
                  })
                  .then(function (newSubscription) {
                    enviarAssinatura(newSubscription);
                  })
                  .catch(function (e) {
                    console.error(e);
                  });
              } else {
                enviarAssinatura(existedSubscription);
              }
            });
        })
        .catch(function (e) {
          console.error(e);
        });
    }
  }
}

function enviarAssinatura(subscription) {
  comidaApi
    .post(`notificacoes/assinar`, { subscription })
    .then((resultado) => resultado)
    .catch(() => {
      console.log("Erro ao enviar notificação");
    });
}
