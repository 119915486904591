import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default function BotaoRetirar(props) {
    return (
        <button onClick={(evento) => props.onClick(evento)} className={`btn btn-light ${props.className}`}>
            <FontAwesomeIcon icon={faTimes} />
            <span className="d-none d-sm-inline"> retirar</span>
        </button>
    )
}

BotaoRetirar.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
}