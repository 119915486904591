import React, { useEffect, useState } from "react";
import comidaApi from "../services/comida.api";
import PropTypes from "prop-types";
import Select from 'react-select';

function SelecaoEstado(props) {
  const [estados, setEstados] = useState([]);

  useEffect(() => {
    comidaApi.get("estados/").then((estados) => {
        setEstados(estados.data)
    });
  }, []);

  return (
    <div className="row">
      <div className={props.className || "mb-3 col-md-6"}>
        <label className="w-100">
          Estado
          <Select
            placeholder="Em qual estado você mora?"
            noOptionsMessage={() => "Sem opções"}
            name="estado"
            value={props.value}
            options={estados.map((e) => ({
              label: e.Nome,
              value: e.ID,
            }))}
            onChange={props.onChange}
          />
        </label>
      </div>
    </div>
  );
}

SelecaoEstado.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    className: PropTypes.string,
  };

export default SelecaoEstado;
