import React from "react"
const espacoReservado = "/espacoReservadoReceita.jpg"
import { Image } from "react-bootstrap"
import PropTypes from 'prop-types'

const imagemReceita = {objectFit: "cover", height: "17rem", maxWidth: "100%"}
function CardImagemReceita(props) {
    return (
        <Image src={props.imagem || espacoReservado} style={props.style || imagemReceita} />
    )
}

CardImagemReceita.propTypes = {
    imagem: PropTypes.string,
    style: PropTypes.object,
}

export default CardImagemReceita
