import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useHistory } from 'react-router-dom';
import { Fab } from 'react-tiny-fab';

export default function BotaoFabReceita() {
    const history = useHistory();

    if (typeof window === "undefined")
        return null

    return (
        <Fab
            event="click"
            mainButtonStyles={{ backgroundColor: "#D35500" }}
            style={{ bottom: '40px', right: '25px' }}
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => { history.push("/receitas/criar") }}
        ></Fab>
    )
}
