import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import IndexPlanejamento from "./components/planejamento/index.planejamento";

import CreateReceita from "./components/receitas/create.receita";
import IndexReceita from "./components/receitas/index.receitas";
import TiposReceita from "./components/receitas/tipos.receita";
import DetailReceita from "./components/receitas/detail.receita";
import EditReceita from "./components/receitas/edit.receita";

import CreateIngrediente from "./components/ingredientes/create.ingredientes";
import EditIngrediente from "./components/ingredientes/edit.ingredientes";
import IndexIngrediente from "./components/ingredientes/index.ingredientes";
import ListIngrediente from "./components/ingredientes/list.ingrediente";

import CreateLogin from "./components/login/create.login";
import UserDetail from "./components/usuarios/detail.user";
import NovaSenha from "./components/login/novaSenha";

import IndexLogin from "./components/login/index.login";

import Home from "./components/home";
import Termo from "./components/termo";
import Politica from "./components/politica";
import User from "./models/user";

import PropTypes from "prop-types";
import MenuPlanejamento from "./components/planejamento/menu.planejamento";
import IndexFornecedores from "./components/fornecedores/index.fornecedores";
import Localizacao from "./components/localizacao";
import DetailFornecedor from "./components/fornecedores/detail.fornecedor";
import EditarCardapio from "./components/cardapios/editar.cardapio";
import IndexSacola from "./components/sacolas/index.sacolas";
import IndexPedidos from "./components/pedidos/index.pedidos";
import DetalhesPedidos from "./components/pedidos/detalhes.pedidos";
import SignIn from "./components/login/signin";
import EditarProdutos from "./components/produtos/editar.produtos";
import Menu from "./components/usuarios/menu";

const user = new User();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      user.IsLoggedin ? (
        <Component {...props} />
      ) : (
          <Redirect
            to={`/login?q=${encodeURIComponent(props.location.pathname)}`}
          />
        )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
};

const AnonymousRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !user.IsLoggedin ? <Component {...props} /> : <Redirect to={"/"} />
    }
  />
);

AnonymousRoute.propTypes = {
  component: PropTypes.object,
};

const Routes = () => (
  <Switch>
    <PrivateRoute path="/receitas/editar/:id" component={EditReceita} />
    <PrivateRoute path="/receitas/criar" component={CreateReceita} />
    <Route path="/receitas/tipos/:tipo" component={IndexReceita} />
    <Route path="/receitas/usuarios/:user" component={IndexReceita} />
    <Route path="/receitas/tipos" component={IndexReceita} />
    <Route path="/receitas/busca" component={IndexReceita} />
    <Route path="/receitas/:id" component={DetailReceita} />
    <Route path="/receitas" component={TiposReceita} />

    <PrivateRoute path="/ingredientes/editar/:id" component={EditIngrediente} />
    <PrivateRoute path="/ingredientes/criar" component={CreateIngrediente} />
    <PrivateRoute
      path="/ingredientes/lista/:data/:user?"
      component={ListIngrediente}
    />
    <Route path="/ingredientes" component={IndexIngrediente} />

    <PrivateRoute path="/planejamento/menu" component={MenuPlanejamento} />
    <PrivateRoute path="/planejamento/:user?" component={IndexPlanejamento} />

    <AnonymousRoute path="/usuarios/cadastro" component={SignIn} />
    <PrivateRoute path="/usuarios/editar" component={CreateLogin} />
    <PrivateRoute path="/usuarios/:id" component={UserDetail} />

    <Route path="/termo" render={(props) => <Termo {...props} />} />
    <Route path="/politica" render={(props) => <Politica {...props} />} />

    <Route
      path="/fornecedores/cardapios/:fornecedor"
      render={(props) => <EditarCardapio {...props} />}
    />
    <Route
      path="/fornecedores/produtos/:fornecedor"
      render={(props) => <EditarProdutos {...props} />}
    />
    <Route
      path="/fornecedores/:estado/:cidade"
      render={(props) => <IndexFornecedores {...props} />}
    />
    <Route
      path="/fornecedores/:id"
      render={(props) => <DetailFornecedor {...props} />}
    />
    <Route
      path="/fornecedores"
      render={(props) => <IndexFornecedores {...props} />}
    />
    <Route path="/localizacao" render={(props) => <Localizacao {...props} />} />
    <Route path="/parcerias" render={(props) => <IndexFornecedores {...props} />} />

    <Route path="/sacola" component={IndexSacola} />
    <PrivateRoute
      path="/pedidos/detalhes/:numero/:cliente?"
      component={DetalhesPedidos}
    />
    <PrivateRoute path="/pedidos" component={IndexPedidos} />
    <PrivateRoute path="/menu" component={Menu} />

    <Route path="/senhas" render={(props) => <NovaSenha {...props} />} />
    <AnonymousRoute path="/login" component={IndexLogin} />

    <Route exact path="/" component={Home} />
  </Switch>
);

export function consultarFornecedores() {
  if (user.IsLoggedin) return "/fornecedores";
  else return "/localizacao";
}

export default Routes;
