import React from "react";
import comidaApi from "../../services/comida.api";
import Select from "react-select";
import tiposIngredientes from "../../models/tipos.ingredientes";
import Loading from "../loading";
import PropTypes from "prop-types";
import Container from "../container";

export default class EditIngrediente extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeNomeIngrediente = this.onChangeNomeIngrediente.bind(this);
    this.onChangeTipoIngrediente = this.onChangeTipoIngrediente.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      nome: "",
      tipo: "",
      processing: false,
    };
  }

  componentDidMount() {
    comidaApi
      .get("ingredientes/" + this.props.match.params.id)
      .then((response) => {
        this.setState({
          nome: response.data.nome,
          tipo:
            typeof response.data.tipo !== "undefined" ? response.data.tipo : "",
        });
      });
  }

  onChangeNomeIngrediente(e) {
    this.setState({
      nome: e.target.value,
    });
  }

  onChangeTipoIngrediente(e) {
    this.setState({
      tipo: e.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      processing: true,
    });
    const obj = {
      nome: this.state.nome,
      tipo: this.state.tipo,
    };
    comidaApi
      .put("ingredientes/" + this.props.match.params.id, obj)
      .then(() => {
        this.props.history.push("/planejamento");
      })
      .catch((res) => {
        this.props.history.push({
          pathname: "/error",
          state: { errorMessage: res },
        });
      });
  }

  render() {
    return (
      <Container>
        <h5>Alterar Ingrediente</h5>
        <form onSubmit={this.onSubmit}>
          <div className="mb-3">
            <label className="w-100">
              Name
              <input
                type="text"
                className="form-control"
                value={this.state.nome}
                onChange={this.onChangeNomeIngrediente}
              />
            </label>
          </div>
          <div className="mb-3">
            <Select
              placeholder="Selecione um tipo..."
              value={tiposIngredientes.tiposIngredientes.find((i) => {
                return i.value === this.state.tipo;
              })}
              onChange={this.onChangeTipoIngrediente}
              options={tiposIngredientes.tiposIngredientes}
            />
          </div>
          <div className="mb-3">
            <button
              type="submit"
              disabled={this.state.processing}
              className="btn btn-primary text-white w-25"
            >
              Salvar
            </button>
            {this.state.processing ? <Loading /> : null}
          </div>
        </form>
      </Container>
    );
  }
}

EditIngrediente.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};
