import React, { useEffect, useState } from "react";
import comidaApi, { agruparItens } from "../../services/comida.api";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Container from "../container";
import Skeleton from "react-loading-skeleton";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faIdCard,
  faMapMarkedAlt,
  faPencilRuler,
  faSearch,
  faShoppingBasket,
  faShoppingCart,
  faTruck,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import User from "../../models/user";
import ItemCardapio from "../cardapios/item.cardapio";
import AdicionarSacola from "../cardapios/adicionar.sacola";
import { connect, useSelector } from "react-redux";
import Promocao from "../promocao";
import { Link, useParams } from "react-router-dom";
const espacoReservadoFornecedor = "/espacoReservadoFornecedor.jpg";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import EditarFornecedor from "./edit.fornecedor";
import PersonalizarCardapio from "../cardapios/personalizar.cardapio";
import SelecaoOpcaoPeso from "../cardapios/selecaoOpcaoPeso";
import { consultarFornecedor } from "../../reducers";
import { Helmet } from "react-helmet";
import ItemProduto from "../produtos/itemProduto";
import { consultarQuantidadeSacola } from "../sacolas/consultarSacola";
import BotaoFabSacola from "../botao.fab.sacola";
import { ChecarItemPersonalizavel, ConsultarItemSacola, DoExcluirItemSacola, DoIncluirItemSacola } from "../../actions/cardapio";
import RemoveItemPersonalizado from "../sacolas/removeItemPersonalizado";
import SelecionaVariacao from "../sacolas/selecionaVariacao";

const user = new User();

const removerAcentos = (texto) => {
  if (!texto)
    return ""

  return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()
}

function ConnectedDetailFornecedor(props) {
  const [fornecedor, setFornecedor] = useState({ ...props.dadosIniciais });
  const sacola = useSelector((state) => state.sacola);
  const { id: idFornecedor } = useParams();
  const [promocoes, setPromocoes] = useState([]);
  const [editando, setEditando] = useState(false);
  const [personalizando, setPersonalizando] = useState(false);
  const [itemPersonalizado, setItemPersonalizado] = useState({});
  const [indicePersonalizado, setIndicePersonalizado] = useState(-1);
  const [selecionandoPeso, setSelecionandoPeso] = useState(false);
  const [selecionandoVariacao, setSelecionandoVariacao] = useState(false);
  const [itensNaSacola, setItensNaSacola] = useState(null);
  const [gruposCardapio, setGruposCardapio] = useState([]);
  const [gruposProdutos, setGruposProdutos] = useState([]);
  const [itensOriginais, setItensOriginais] = useState({
    produtos: null,
    cardapio: null
  })

  var cardapioTimer;
  var produtosTimer;

  useEffect(() => {
    comidaApi
      .get(`fornecedores/${idFornecedor}`)
      .then((resultado) => {
        setFornecedor(resultado.data);

        setGruposCardapio(agruparItens(resultado.data.cardapio));
        setGruposProdutos(agruparItens(resultado.data.produtos))
      })
      .catch((erro) => {
        toast.warn(
          erro.response?.data ||
          "Houve um erro ao consultar este parceiro. Por favor, tente novamente mais tarde."
        );
      });

    comidaApi
      .get(`promocoes/fornecedor/${idFornecedor}`)
      .then((resultado) => {
        if (user.IsLoggedin) setPromocoes(resultado.data);
        else if (resultado.data.length > 0) {
          const temPromocao = {
            titulo: "Tem promoção!",
            descricao: "#Acesse# e saiba mais...",
          };

          setPromocoes([temPromocao]);
        }
      })
      .catch((erro) => {
        toast.warn(erro.response?.data || "Houve um erro ao consultar promoções.", {
        });
      });
  }, [idFornecedor, editando]);

  const mapearItemCardapio = (item, index) => {
    const quantidade = consultarQuantidadeSacola(sacola, item);
    return mostrarItemCardapio(index, item, quantidade);
  };

  const filtrarCardapio = (evento) => {
    clearTimeout(cardapioTimer)

    const texto = evento.target.value

    cardapioTimer = setTimeout(() => {

      let itens = [...fornecedor.cardapio];

      if (!itensOriginais.cardapio)
        setItensOriginais({
          ...itensOriginais,
          cardapio: itens
        });

      else
        itens = itensOriginais.cardapio;

      const cardapio = itens.filter((i) => i.disponivel && (new RegExp(texto, "i").test(i.grupo) || new RegExp(texto, "i").test(i.nome)))

      if (cardapio.length > 0)
        setFornecedor({
          ...fornecedor,
          cardapio
        });
      else
        toast.info(`Nenhum item encontrado com '${texto}'`)
    }, 500);
  };

  const regexItem = (texto) => (new RegExp(removerAcentos(texto), "i"))

  const filtrarProdutos = (evento) => {
    clearTimeout(produtosTimer)

    const texto = evento.target.value

    produtosTimer = setTimeout(() => {
      let itens = [...fornecedor.produtos];

      if (!itensOriginais.produtos)
        setItensOriginais({
          ...itensOriginais,
          produtos: itens
        });

      else
        itens = itensOriginais.produtos;

      const produtos = itens.filter((i) => i.disponivel && (regexItem(texto).test(removerAcentos(i.grupo)) || regexItem(texto).test(removerAcentos(i.nome))))

      if (produtos.length > 0)
        setFornecedor({
          ...fornecedor,
          produtos
        });
      else
        toast.info(`Nenhum produto encontrado com '${texto}'`)
    }, 500);
  };

  const mostrarCardapio = (
    <Container>
      <h5>
        <FontAwesomeIcon icon={faUtensils} /> Cardápio
      </h5>
      <div>
        <Button variant="outline-link" disabled><FontAwesomeIcon icon={faSearch} /></Button><input type='text' placeholder='Pesquisar item...' onChange={filtrarCardapio} />
      </div>
      <PersonalizarCardapio
        value={itemPersonalizado}
        visivel={personalizando}
        aoOcultar={() => setPersonalizando(false)}
        aoAdicionar={(item) => {
          setPersonalizando(false);
          quantidadeAlterada(item, indicePersonalizado, 1);
        }}
      />
      <div className="row">
        {fornecedor.cardapio
          ?.filter(
            (item) => item.disponivel && (!item.grupo || item.grupo === "")
          )
          .map(mapearItemCardapio)}
      </div>
      {gruposCardapio.map((g) => {
        const itens = fornecedor.cardapio?.filter(
          (item) => item.disponivel && item.grupo === g
        );

        if (itens && itens.length > 0)
          return (
            <div key={g}>
              <h3 className="text-secondary">{g}</h3>
              <div className="row">
                {itens.map(mapearItemCardapio)}
              </div>
            </div>
          );
      })}
    </Container>
  );

  const mapearGruposProdutos = (g) => {
    const itens = fornecedor.produtos?.filter(
      (item) => item.disponivel && item.grupo === g
    );

    if (itens && itens.length > 0)
      return (
        <div key={g}>
          <h3 className="text-secondary">{g}</h3>
          <div className="row">
            {itens.map(mapearItemProduto)}
          </div>
        </div>
      );
  };

  const filtrarDisponiveisSemGrupo = (item) => item.disponivel && (!item.grupo || item.grupo === "");

  const mapearItemProduto = (item, index) => {
    const quantidade = consultarQuantidadeSacola(sacola, item);
    return mostrarItemProduto(index, item, quantidade);
  };

  const mostrarProdutos = (
    <Container>
      <h5>
        <FontAwesomeIcon icon={faShoppingCart} /> Produtos
      </h5>
      <div>
        <Button variant="outline-link" disabled><FontAwesomeIcon icon={faSearch} /></Button><input type='text' placeholder='Pesquisar produto...' onChange={filtrarProdutos} />
      </div>
      <div className="row">
        {fornecedor.produtos
          ?.filter(filtrarDisponiveisSemGrupo)
          .map(mapearItemProduto)}
      </div>
      {gruposProdutos.map(mapearGruposProdutos)}
    </Container>
  );

  const administrador = fornecedor.administradores?.includes(
    user.Information.user._id
  );

  const produtos = fornecedor.produtos?.filter((p) => p.disponivel);
  const cardapio = fornecedor.cardapio?.filter((c) => c.disponivel);

  const mensagemCompartilhamento = `Experimente ${fornecedor.nome} - ${fornecedor.categoria}`;
  const hashtagCompartilhamento = `#caseiraapp ${fornecedor.categoria && "#" + fornecedor.categoria.replace(" ", "")
    }`;

  const titulo = `${fornecedor.nome} | ${fornecedor.categoria} - caseira.app`;

  return (
    <div>

      <Helmet>
        <meta property="og:title" content={titulo} />
        <meta name="description" content={fornecedor.descricao} />
        <meta property="og:description" content={fornecedor.descricao} />
        <meta
          property="og:image"
          content={fornecedor.foto || "https://www.caseira.app/applogo.png"}
        />
        <meta
          property="og:image:alt"
          content={`Logotipo do parceiro ${fornecedor.nome}`}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta
          property="og:url"
          content={`https://caseira.app${props.location.pathname}`}
        />
        <title>{titulo}</title>
      </Helmet>
      <SelecaoOpcaoPeso
        visivel={selecionandoPeso}
        value={itemPersonalizado.opcoesPeso}
        enviar={(opcao) => {
          let novoItem = { ...itemPersonalizado };
          novoItem.peso = opcao.peso;
          novoItem.preco = opcao.preco;
          novoItem.tamanho = opcao.tamanho;

          setItemPersonalizado(novoItem)
          setSelecionandoPeso(false);
          if (itemPersonalizado.personalizavel) setPersonalizando(true);
          else quantidadeAlterada(novoItem, indicePersonalizado, 1);
        }}
        aoOcultar={() => setSelecionandoPeso(false)}
      />
      <SelecionaVariacao
        item={itemPersonalizado}
        visivel={selecionandoVariacao}
        aoOcultar={() => {
          setSelecionandoVariacao(false)
        }}
      />
      <RemoveItemPersonalizado
        visivel={itensNaSacola ? true : false}
        aoOcultar={() => setItensNaSacola(null)}
        enviar={(item) => {
          props.DoExcluirItemSacola(item)
          setItensNaSacola(null)
        }}
        itens={itensNaSacola}
      />
      <Container>
        <div className="row">
          <div className="col-3">
            <img
              alt="logotipo do fornecedor"
              src={fornecedor.foto || espacoReservadoFornecedor}
              style={{
                height: "auto",
                width: "auto",
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "contain",
              }}
            />
          </div>
          <div className="col-9">
            <h4>
              {fornecedor.nome || <Skeleton count={6} />}
              <br />
              <small className="text-info">{fornecedor.categoria}</small>
            </h4>
            <div>
              <small className="text-secondary">Compartilhar </small>
              <FacebookShareButton
                url={typeof window != "undefined" ? window.location.href : null}
                quote={mensagemCompartilhamento}
                hashtag={hashtagCompartilhamento}
              >
                <FacebookIcon size="24" round={true} />
              </FacebookShareButton>
              <WhatsappShareButton
                url={typeof window != "undefined" ? window.location.href : null}
                title={mensagemCompartilhamento}
              >
                <WhatsappIcon size="24" round={true} />
              </WhatsappShareButton>
            </div>
            <div className="mb-3">{fornecedor.descricao?.split("\n").map((linha, i) => (<span key={i}>{linha}<br /></span>))}</div>
            <div>
              <FontAwesomeIcon icon={faMapMarkedAlt} /> Cidades
              {fornecedor.cidades?.map((c) => {
                return <span key={c.cidade + c.estado} className="badge badge-info mx-2">{c.cidade}/{c.estado}</span>
              })}
            </div>
            {fornecedor.diaEntrega && (
              <div className="mb-3">
                <FontAwesomeIcon icon={faTruck} /> Entrega
                <ul className="list-group list-group-flush">
                  {fornecedor.diaEntrega?.split("\n").map((linha, i) => (<li key={i} className="list-group-item">{linha}</li>))}
                </ul>
              </div>
            )}
            {fornecedor.observacao && (
              <div className="mb-3 text-secondary">{fornecedor.observacao?.split("\n").map((linha, i) => (<span key={i}>{linha}<br /></span>))}</div>
            )}
            <div className="mb-3">
              {promocoes.map((p, index) => {
                return <Promocao {...props} promocao={p} key={index} />;
              })}
            </div>
          </div>
        </div>
      </Container>
      {administrador && mostarAdministracao()}
      {cardapio?.length > 0 && mostrarCardapio}
      {produtos?.length > 0 && mostrarProdutos}
      <BotaoFabSacola />
    </div>
  );

  function mostrarItemProduto(index, item, quantidade) {
    const aoAdicionarProduto = (valor) => {
      if (valor <= 0) {
        if (ChecarItemPersonalizavel(item))
          setItensNaSacola(ConsultarItemSacola(sacola, item))
        else
          quantidadeAlterada(item, index, valor)
      }
      else {
        item.tipo = "produto";
        selecionarItemAtual(index, item);
        if (item.opcoesPeso) {
          setSelecionandoPeso(true);
        } else if (item.personalizavel) {
          setPersonalizando(true);
        } else if (item.variavel) {
          setSelecionandoVariacao(true)
        } else {
          quantidadeAlterada(item, index, valor);
        }
      }
    };

    const mostrarAdicionar = () => {
      if (!item.url)
        return <AdicionarSacola
          value={quantidade}
          onChange={aoAdicionarProduto}
        />
      else
        return <a
          className="btn btn-success"
          href={item.url}
          target="_blank"
          rel="noopener noreferrer">
          Comprar <FontAwesomeIcon icon={faExternalLinkAlt} />
        </a>
    }

    return (
      <div key={index} className="col-6 col-md-4 col-lg-3 p-2">
        <ItemProduto
          item={item}
          className={`h-100 ${item.personalizavel && "border-success"}`}
        >
          {mostrarAdicionar()}
        </ItemProduto>
      </div>
    );
  }

  function mostrarItemCardapio(index, item, quantidade) {
    const aoAdicionarItemCardapio = (valor) => {
      if (valor <= 0) {
        if (ChecarItemPersonalizavel(item))
          setItensNaSacola(ConsultarItemSacola(sacola, item))
        else
          quantidadeAlterada(item, index, valor)
      } else {
        item.tipo = "cardápio"
        selecionarItemAtual(index, item);
        if (item.opcoesPeso) {
          setSelecionandoPeso(true);
        } else if (item.personalizavel) {
          setPersonalizando(true);
        } else {
          quantidadeAlterada(item, index, valor);
        }
      }
    };

    return (
      <div key={index} className="col-6 col-md-4 col-lg-3 p-2">
        <ItemCardapio
          item={item}
          className={`h-100 ${item.personalizavel && "border-success"}`}
        >
          <AdicionarSacola
            value={quantidade}
            onChange={aoAdicionarItemCardapio}
          />
        </ItemCardapio>
      </div>
    );
  }

  function selecionarItemAtual(index, item) {
    setIndicePersonalizado(index);
    setItemPersonalizado(item);
  }

  function quantidadeAlterada(item, index, valor) {
    if (valor > 0)
      props.DoIncluirItemSacola(item);
    else
      props.DoExcluirItemSacola(item);

    let novoCardapio = { ...fornecedor };
    let itemAlterado = fornecedor.cardapio[index];

    novoCardapio.cardapio.splice(index, 1, {
      ...itemAlterado,
      personalizacao: null,
    });

    setFornecedor(novoCardapio);
  }

  function mostarAdministracao() {
    return (
      <Container>
        <h5>
          <FontAwesomeIcon icon={faPencilRuler} /> Administração
        </h5>
        <EditarFornecedor
          visivel={editando}
          value={fornecedor}
          aoOcultar={() => setEditando(false)}
          aoAtualizar={() => {
            setEditando(false);
          }}
        />
        <Button
          variant="outline-secondary me-2"
          onClick={() => {
            setEditando(true);
          }}
        >
          <FontAwesomeIcon icon={faIdCard} /> Informações
        </Button>
        <Button
          variant="outline-secondary me-2"
          onClick={() => {
            props.history.push(`/fornecedores/cardapios/${fornecedor._id}`);
          }}
        >
          <FontAwesomeIcon icon={faUtensils} /> Cardápio
        </Button>
        <Button
          variant="outline-secondary me-2"
          onClick={() => {
            props.history.push(`/fornecedores/produtos/${fornecedor._id}`);
          }}
        >
          <FontAwesomeIcon icon={faShoppingCart} /> Produtos
        </Button>
        <Link to="/pedidos" className="btn btn-outline-success">
          <FontAwesomeIcon icon={faShoppingBasket} /> Pedidos
        </Link>
      </Container>
    );
  }
}

ConnectedDetailFornecedor.propTypes = {
  match: PropTypes.object,
  DoIncluirItemSacola: PropTypes.func,
  DoExcluirItemSacola: PropTypes.func,
  history: PropTypes.object,
  dadosIniciais: PropTypes.object,
  location: PropTypes.object,
};

ConnectedDetailFornecedor.consultarServidor = consultarFornecedor;

const mapStateToProps = (state) => ({
  dadosIniciais: state.fornecedor,
});

function mapDispatchToProps(dispatch) {
  return {
    DoExcluirItemSacola: (item) => dispatch(DoExcluirItemSacola(item)),
    DoIncluirItemSacola: (item) => dispatch(DoIncluirItemSacola(item)),
    consultarFornecedor,
  };
}

const DetailFornecedor = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedDetailFornecedor);

export default DetailFornecedor;
