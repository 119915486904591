import React from 'react'
import PropTypes from 'prop-types'
import Fraction from 'fraction.js';

function TituloIngrediente(props) {
    const item = props.item
    const quantidade = transformarQuantidade(item.medida, item.resto || item.quantidade);
    const temQuantidade = quantidade.toString() !== "0";
    const mostrarMedida =
        temQuantidade && item.medida && item.medida !== "Unidade";

    return <>
        {temQuantidade ? quantidade + " " : null}
        <small>
            {mostrarMedida ? item.medida.toLowerCase() + " " : null}
        </small>
        <strong>{item.nome}</strong>
    </>;

    function transformarQuantidade(medida, quantidade) {
        const fracoes = [
            "Unidade",
            "Xícara",
            "Colher de Café",
            "Colher de Chá",
            "Colher de Sopa",
            "",
        ];

        if (!item.quantidade)
            return ""

        if (!item.quantidade.toString().includes("/") && (!medida || fracoes.includes(medida)))
            return new Fraction(parseFloat(quantidade)).toFraction(true);
        else return quantidade;
    }
}

TituloIngrediente.propTypes = {
    item: PropTypes.object

}

export default TituloIngrediente

