import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

function AdicionarSacola(props) {

  if (props.value)
    return (
      <div className="border d-inline-block">
        <Button
          variant="success"
          onClick={() => {
            if (props.onChange) props.onChange(-1);
          }}
        >
          <strong>
            <FontAwesomeIcon icon={faMinus} />
          </strong>
        </Button>
        <span className="px-3">{props.value}</span>
        <Button
          variant="success"
          onClick={() => {
            if (props.onChange) props.onChange(+1);
          }}
        >
          <strong>
            <FontAwesomeIcon icon={faPlus} />
          </strong>
        </Button>
      </div>
    );
  else
    return (
      <Button
        variant="success"
        onClick={() => {
          if (props.onChange) props.onChange(+1);
        }}
      >
        <strong>
          <FontAwesomeIcon icon={faShoppingBasket} /> Adicionar
        </strong>
      </Button>
    );
}

AdicionarSacola.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any
};

export default AdicionarSacola;
