import { ATUALIZAR_GRUPOS, ATUALIZAR_MENU, LOG_IN, OCULTAR_MENU } from "../constants/action-types";

export const DoLogIn = (payload) => ({
  type: LOG_IN,
  payload,
});

export const DoAtualizarMenu = () => ({
  type: ATUALIZAR_MENU
})

export const DoOcultarMenu = () => ({
  type: OCULTAR_MENU
})

export const DoAtualizarGrupos = (payload) => ({
  type: ATUALIZAR_GRUPOS,
  payload,
});

export const DoConsultarReceitas = (payload) => {
  return {
    type: "receitas/consultarDados",
    payload,
  };
};
