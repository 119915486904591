import React from "react";
import comidaApi from "../../services/comida.api";
import IngredienteReceita from "../receitas/receita.ingrediente";
import { Popover, OverlayTrigger, Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import BotaoRetirar from "../botaoRetirar";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckSquare,
  faClipboardList,
  faExclamation,
  faQuestionCircle,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import Container from "../container";
import Promocao from "../promocao";
import User from "../../models/user";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ItemProduto from "../produtos/itemProduto";
import TituloIngrediente from "./titulo.ingrediente";
import AdicionarSacola from "../cardapios/adicionar.sacola";
import { connect } from "react-redux";
import { consultarQuantidadeSacola } from "../sacolas/consultarSacola";
import SelecaoOpcaoPeso from "../cardapios/selecaoOpcaoPeso";
import BotaoFabSacola from "../botao.fab.sacola";
import { ChecarItemPersonalizavel, ConsultarItemSacola, DoExcluirItemSacola, DoIncluirItemSacola } from "../../actions/cardapio";
import SelecionaVariacao from "../sacolas/selecionaVariacao";
import RemoveItemPersonalizado from "../sacolas/removeItemPersonalizado";

const user = new User();

const mapStateToProps = (state) => {
  return {
    sacola: state.sacola,
  };
};

class ConnectedListIngredientes extends React.Component {
  constructor(props) {
    super(props);

    this.onIngredienteExcluido = this.onIngredienteExcluido.bind(this);

    this.state = {
      user: null,
      carregando: false,
      ingredientes: [],
      comprados: [],
      promocoes: [],
      comprando: false,
      itemAtual: null,
      produtoAtual: {},
      itensNaSacola: null,
      selecionandoPeso: false,
      selecionandoVariacao: false,
    };
  }

  componentDidMount() {
    this.setState({ carregando: true }, () => {
      comidaApi
        .get(
          `lista/${this.props.match.params.data}/${this.props.match.params.user || ""
          }`
        )
        .then((response) => {
          const comprados = response.data.ingredientes?.filter(
            (i) => i.comprado
          );
          const naoComprados = response.data.ingredientes?.filter(
            (i) => !i.comprado
          );

          this.setState({
            ingredientes: naoComprados,
            comprados: comprados,
            carregando: false,
          });
        })
        .catch((erro) => {
          this.setState({
            carregando: false,
          });
          toast.warn(
            erro.response?.data ||
            "Houve um erro ao consultar esta lista de compras. Por favor, tente novamente mais tarde."
          );
        });

      if (user.IsLoggedin)
        comidaApi
          .get(`users/${user.Information.user._id}`)
          .then((resultado) => {
            this.setState({
              user: resultado.data,
            });
          })
          .catch(() => { });
    });

    comidaApi
      .get(`promocoes`)
      .then((resposta) => {
        this.setState({
          promocoes: resposta.data,
        });
      })
      .catch((erro) => {
        toast.warn(
          erro.response?.data ||
          "Houve um erro ao consultar promoções. Por favor, tente novamente mais tarde."
        );
      });
  }

  onIngredienteExcluido(indice, comprado) {
    let comprados = this.state.comprados;
    let ingredientes = this.state.ingredientes;
    let item = {};

    if (comprado) {
      item = ingredientes.splice(indice, 1)[0];
      item.user = this.props.match.params.user;

      comidaApi
        .post("lista", item)
        .then((resultado) => {
          item.comprados = [resultado.data];
          comprados.push(item);

          this.setState({
            ingredientes: ingredientes,
            comprados: comprados,
          });
        })
        .catch((erro) => {
          toast.warn(
            erro.response?.data ||
            "Houve um erro ao atualizar este item. Por favor, tente novamente mais tarde."
          );
        });
    } else {
      item = comprados.splice(indice, 1)[0];
      item.user = this.props.match.params.user;

      comidaApi
        .delete(`lista/${item.comprados[0]._id}/${item.user || ""}`)
        .then(() => {
          item.comprados = [];
          ingredientes.push(item);

          this.setState({
            ingredientes: ingredientes,
            comprados: comprados,
          });
        })
        .catch((erro) => {
          toast.warn(
            erro.response?.data ||
            "Houve um erro ao atualizar este item. Por favor, tente novamente mais tarde."
          );
        });
    }
  }

  quantidadeAlterada(item, valor) {
    if (valor > 0)
      this.props.DoIncluirItemSacola(item);
    else
      this.props.DoExcluirItemSacola(item);
  }

  render() {
    const mostrarProdutos = () => {
      let itemAtual = { ...this.state.itemAtual }
      return itemAtual?.produtos?.map((produto) => {

        const fornecedor = itemAtual?.fornecedores.find((f) => f._id === produto.fornecedor)
        const quantidade = consultarQuantidadeSacola(this.props.sacola, produto);

        return <ItemProduto item={produto} key={produto._id}>
          <div>
            Vendido por <Link to={`/fornecedores/${fornecedor.slug}`}>{fornecedor.nome}</Link>
          </div>
          <AdicionarSacola
            value={quantidade}
            onChange={(valor) => {
              if (valor <= 0) {
                if (ChecarItemPersonalizavel(produto))
                  this.setState({
                    itensNaSacola: ConsultarItemSacola(this.props.sacola, produto)
                  })
                else
                  this.quantidadeAlterada(produto, valor)
              } else {
                produto.tipo = "produto"
                this.setState({
                  produtoAtual: produto
                })
                if (produto.opcoesPeso) {
                  this.setState({
                    selecionandoPeso: true
                  });
                } else if (produto.variavel) {
                  this.setState({
                    selecionandoVariacao: true
                  });
                } else {
                  this.props.DoIncluirItemSacola(produto);
                }
              }
            }}
          />
        </ItemProduto>
      })
    };

    return (
      <div>
        <Helmet>
          <title>Lista de Compras - caseira.app</title>
        </Helmet>
        <Container>
          <h5>
            <FontAwesomeIcon icon={faClipboardList} /> Lista de Compras
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popover}
            >
              <Button variant="none">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Button>
            </OverlayTrigger>
          </h5>
          <SelecionaVariacao
            item={this.state.produtoAtual}
            visivel={this.state.selecionandoVariacao}
            aoOcultar={() => {
              this.setState({
                selecionandoVariacao: false
              })
            }}
          />
          <RemoveItemPersonalizado
            visivel={this.state.itensNaSacola ? true : false}
            aoOcultar={() => this.setState({
              itensNaSacola: null
            })}
            enviar={(item) => {
              this.props.DoExcluirItemSacola(item)
              this.setState({
                itensNaSacola: null
              })
            }}
            itens={this.state.itensNaSacola}
          />
          <SelecaoOpcaoPeso
            visivel={this.state.selecionandoPeso}
            value={this.state.produtoAtual?.opcoesPeso}
            enviar={(opcao) => {
              let novoItem = { ...this.state.produtoAtual, ...opcao };
              this.setState({ selecionandoPeso: false });
              this.props.DoIncluirItemSacola(novoItem);
            }}
            aoOcultar={() => this.setState({ selecionandoPeso: false })}
          />
          <Modal
            show={this.state.comprando} onHide={() => this.setState({
              comprando: false
            })}>
            <Modal.Header>
              <Modal.Title>Comprando<br /><TituloIngrediente item={this.state.itemAtual} /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {mostrarProdutos()}
              <Button
                className="fixed-top ms-auto mt-4 me-4 p-3"
                variant="light"
                onClick={() => {
                  this.setState({
                    comprando: false
                  })
                }}>
                <FontAwesomeIcon icon={faCheck} /> OK
              </Button>
            </Modal.Body>
          </Modal>
          {this.state.user &&
            (!this.state.user.cidade || !this.state.user.estado) ? (
            <div className="alert alert-warning">
              <FontAwesomeIcon icon={faExclamation} />{" "}
              <Link to="/usuarios/editar" className="alert-warning">
                <strong>Clique aqui</strong> e complete seu cadastro para
                descobrir se temos parceiros na sua cidade.
              </Link>
            </div>
          ) : null}

          {this.state.promocoes && (
            <div>
              {this.state.promocoes.map((promocao, index) => (
                <Promocao {...this.props} promocao={promocao} key={index} />
              ))}
            </div>
          )}
          <ul className="list-group list-group-flush">
            {this.state.carregando
              ? this.mostrarSkeleton()
              : this.mostrarIngredientes()}
          </ul>
        </Container>
        {this.state.comprados.length > 0 ? (
          <Container>
            <h6 className="mt-3">
              <FontAwesomeIcon icon={faCheckSquare} /> Itens já comprados
            </h6>

            <ul className="list-group list-group-flush mt-2 font-italic bg-light text-secondary">
              {this.mostrarComprados()}
            </ul>
          </Container>
        ) : null}
        <BotaoFabSacola />
      </div>

    );
  }

  mostrarSkeleton() {
    return (
      <div style={{ lineHeight: "4.6rem", fontSize: "3.6rem" }}>
        <Skeleton count={10} />
      </div>
    );
  }

  mostrarAvisoSemLista() {
    return (
      <div className="alert alert-info">
        <p>
          <strong>Sua lista de compras está vazia.</strong>
        </p>
        <p>Planeje algumas das suas refeições e depois volte aqui.</p>
      </div>
    );
  }

  mostrarComprados() {
    return this.state.comprados.map((ingrediente, indice) =>
      this.mostrarItemLista(indice, ingrediente, false)
    );
  }

  mostrarIngredientes() {
    if (!this.state.ingredientes.length > 0 && !this.state.comprados.length > 0)
      return this.mostrarAvisoSemLista();
    else
      return this.state.ingredientes.map((ingrediente, indice) =>
        this.mostrarItemLista(indice, ingrediente, true)
      );
  }

  mostrarItemLista(indice, ingrediente, pendente) {
    const mostrarComprar = () => {
      if (pendente && ingrediente.produtos.length > 0) {
        const temLink = ingrediente.produtos[0].url ? true : false

        if (temLink)
          return <a
            className="btn btn-success"
            href={ingrediente.produtos[0].url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => this.onIngredienteExcluido(indice, pendente)}
          >
            <FontAwesomeIcon icon={faShoppingCart} /> Comprar
      </a>
        else
          return <Button variant="success" onClick={() => {
            this.setState({
              comprando: true,
              itemAtual: ingrediente
            })
          }}>Comprar</Button>
      }
      else return null
    };

    return (
      <li key={ingrediente._id + ingrediente.medida} className="list-group-item">
        <IngredienteReceita ingrediente={ingrediente} />
        <div className="text-right">
          {mostrarComprar()}
          <BotaoRetirar
            className="botao-fechar"
            onClick={() => this.onIngredienteExcluido(indice, pendente)}
          />
        </div>
      </li>
    );
  }
}

const popover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Hora das compras</Popover.Header>
    <Popover.Body>
      <p>Esta é a lista de compras baseada no seu planejamento.</p>
      <p>
        Clique em <BotaoRetirar /> para retirar um item da lista.
      </p>
    </Popover.Body>
  </Popover>
);

ConnectedListIngredientes.propTypes = {
  match: PropTypes.object,
  sacola: PropTypes.object,
  DoIncluirItemSacola: PropTypes.func,
  DoExcluirItemSacola: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    DoIncluirItemSacola: (item) => dispatch(DoIncluirItemSacola(item)),
    DoExcluirItemSacola: (item) => dispatch(DoExcluirItemSacola(item)),
  };
}

const ListIngredientes = connect(mapStateToProps, mapDispatchToProps)(ConnectedListIngredientes)

export default ListIngredientes