import React from "react";
import PropTypes from "prop-types";
import { faListOl } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ModoPreparo(props) {
  return (
    <div className={props.className || "mb-3 col-md-6"}>
      <label className="w-100">
        <FontAwesomeIcon icon={faListOl} /> Modo de Preparo <small className="d-block mb-3">Utilize uma linha para cada instrução da receita.</small>
        <textarea
          className="form-control"
          value={props.value}
          onChange={props.onChange}
          style={{ height: "300px" }}
          placeholder="Como se prepara essa receita?"
        />
      </label>
    </div>
  );
}

ModoPreparo.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default ModoPreparo;
