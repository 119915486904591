import React, { useEffect, useState } from "react";
import Container from "../container";
import { useParams } from "react-router-dom";
import comidaApi, { agruparItens } from "../../services/comida.api";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPencilAlt,
  faPlus,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import AdicionarProduto from "./adicionar.produto";
import FotoUploadProduto from "./foto.upload.produto";
import ItemProduto from "./itemProduto";

function EditarProdutos() {
  const [produtos, setProdutos] = useState(new Array(10));
  const { fornecedor } = useParams();
  const [adicionando, setAdicionando] = useState(false);
  const [editando, setEditando] = useState(false);
  const [itemAtual, setItemAtual] = useState(null);
  const [grupos, setGrupos] = useState([]);
  const [itensOriginais, setItensOriginais] = useState(null)

  var produtosTimmer;

  useEffect(() => {
    comidaApi
      .get(`produtos/fornecedores/${fornecedor}`)
      .then((resultado) => {
        setProdutos(resultado.data);
      })
      .catch((erro) => {
        toast.warn(
          erro.response?.data ||
          "Houve um erro ao consultar produtos. Por favor, tente novamente mais tarde."
        );
      });

    comidaApi
      .get(`gruposprodutos/${fornecedor}`)
      .then((resultado) => {
        setGrupos(resultado.data);
      })
      .catch((erro) => {
        alert('grupos nok')
        toast.warn(
          erro.response?.data ||
          "Houve um erro ao consultar os grupos de produtos. Por favor, tente novamente mais tarde.",
        );
      });
  }, [fornecedor]);

  const gruposEmUso = agruparItens(produtos);

  const converterGrupos = (g) => {
    return {
      label: g.nome,
      value: g._id,
    };
  };

  const editar = (itemAtualizado) => {
    const produtosAtualizados = [...produtos];
    const indice = produtosAtualizados.findIndex(
      (c) => c._id === itemAtualizado._id
    );
    produtosAtualizados.splice(indice, 1, itemAtualizado);
    setProdutos(produtosAtualizados);
    setEditando(false);
  };

  const incluir = (novoItem) => {
    const produtosAtualizados = [...produtos];
    produtosAtualizados.push(novoItem);
    setProdutos(produtosAtualizados);
    setAdicionando(false);
  };

  const filtrarProdutos = (evento) => {
    clearTimeout(produtosTimmer)

    const texto = evento.target.value

    produtosTimmer = setTimeout(() => {
      let itens = [...produtos]

      if (!itensOriginais)
        setItensOriginais([...produtos]);
      else
        itens = itensOriginais;

      const itensFiltrados = itens.filter((i) => (new RegExp(texto, "i").test(i.grupo) || new RegExp(texto, "i").test(i.nome)))

      if (itensFiltrados.length > 0)
        setProdutos(itensFiltrados);
      else
        toast.info(`Nenhum produto encontrado com '${texto}'`)
    }, 500);
  };

  return (
    <div>
      <Helmet>
        <title>Editar Produtos - caseira.app</title>
      </Helmet>
      
      <Container>
        <h4>Editar Produtos</h4>
        <div className="m-2">
          <Button variant="outline-link" disabled><FontAwesomeIcon icon={faSearch} /></Button><input type='text' placeholder='Pesquisar produto...' onChange={filtrarProdutos} />
        </div>
        <Button
          onClick={() => {
            setAdicionando(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> Novo Item
        </Button>
        <AdicionarProduto
          visivel={adicionando}
          aoOcultar={() => {
            setAdicionando(false);
          }}
          fornecedor={fornecedor}
          grupos={grupos.map(converterGrupos)}
          aoAtualizarGrupos={converterItem}
          aoAdicionar={incluir}
        />
        <AdicionarProduto
          grupos={grupos.map(converterGrupos)}
          aoAtualizarGrupos={converterItem}
          visivel={editando}
          value={itemAtual}
          aoOcultar={() => {
            setEditando(false);
          }}
          fornecedor={fornecedor}
          aoAdicionar={editar}
        />
      </Container>
      <Container>
        <h5>
          Itens <strong className="text-success">Disponíveis</strong>
        </h5>
        <div className="row">
          {produtos
            ?.filter(
              (item) => item.disponivel && (!item.grupo || item.grupo === "")
            )
            .map(mostrarItens)}
        </div>
        {gruposEmUso?.map((g) => {
          const itens = produtos?.filter(
            (item) => item.disponivel && item.grupo === g
          );

          if (itens && itens.length > 0)
            return (
              <div key={g}>
                <h3 className="text-secondary">{g}</h3>
                <div className="row">{itens.map(mostrarItens)}</div>
              </div>
            );
        })}
      </Container>
      <Container>
        <h5>
          Itens <strong className="text-danger">Indisponíveis</strong>
        </h5>
        <div className="row">
          {produtos?.filter((item) => !item.disponivel).map(mostrarItens)}
        </div>
      </Container>
    </div>
  )

  function converterItem(grupos) {
    setGrupos(
      grupos.map((g) => {
        return {
          _id: g.value,
          nome: g.label,
          fornecedor,
        };
      })
    );
  }

  function mostrarItens(item, index) {
    const atualizarFoto = (foto) => {
      const produtosAtualizados = [...produtos];
      const indice = produtosAtualizados.findIndex(
        (c) => c._id === item._id
      );
      item.foto = foto;

      produtosAtualizados.splice(indice, 1, item);
      setProdutos(produtosAtualizados);
    };
    return (
      <div key={index} className="col-6 col-md-4 col-lg-3 p-2">
        <ItemProduto item={item} className="h-100">
          <div>
            <FotoUploadProduto
              id={item._id}
              aoAtualizarImagem={atualizarFoto}
            />
            <Button
              variant="outline-primary"
              className="m-2"
              onClick={() => {
                setItemAtual(item);
                setEditando(true);
              }}
            >
              <FontAwesomeIcon icon={faPencilAlt} />{" "}
              <span className="d-none d-sm-inline">Editar</span>
            </Button>
            {item.disponivel ? (
              <Button
                variant="outline-danger"
                className="m-2"
                onClick={editarDisponibilidade(item)}
              >
                <FontAwesomeIcon icon={faTimes} />{" "}
                <span className="d-none d-sm-inline">Retirar</span>
              </Button>
            ) : (
                <Button variant="success" onClick={editarDisponibilidade(item)}>
                  <FontAwesomeIcon icon={faCheck} />{" "}
                  <span className="d-none d-sm-inline">Retornar</span>
                </Button>
              )}
          </div>
        </ItemProduto>
      </div>
    );
  }

  function editarDisponibilidade(item) {
    return () => {
      comidaApi
        .put(`produtos/disponibilidade/${item._id}`)
        .then((resultado) => {
          const produtosAtualizados = [...produtos];
          const indice = produtosAtualizados.findIndex(
            (i) => i._id === item._id
          );
          produtosAtualizados.splice(indice, 1, resultado.data);
          setProdutos(produtosAtualizados);
        })
        .catch((erro) => {
          toast.warn(
            erro.response?.data ||
            "Houve um erro ao atualizar este item. Por favor, tente novamente mais tarde.",
          );
        });
    };
  }
}

export default EditarProdutos;
