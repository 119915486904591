const carregarFacebookLogin = (callback) => {
  const scriptExiste = document.getElementById("facebookLogin");
  if (!scriptExiste) {
    const script = document.createElement("script");
    script.src = "https://connect.facebook.net/pt_BR/sdk.js#xfbml=1&version=v8.0&appId=764140047492666&autoLogAppEvents=1";
    script.id = "facebookLogin";
    script.nonce="aOYU6Kx4"
    script.defer="true"
    script.async="true"
    script.crossOrigin="annoymous"
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (scriptExiste && callback) callback();
};
export default carregarFacebookLogin;
