import { ChecarItemPersonalizavel, ConsultarItemSacola } from "../../actions/cardapio";

export function consultarQuantidadeSacola(sacola, item) {
    if (!sacola)
        return 0
        
    const itemSacola = ConsultarItemSacola(sacola, item);

    let quantidade = 0

    if (itemSacola && itemSacola.length > 0) {
        const amostra = itemSacola[0]

        if (ChecarItemPersonalizavel(amostra))
            quantidade = itemSacola.length
        else
            quantidade = amostra.quantidade
    }

    return quantidade;
}