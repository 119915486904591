import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import comidaApi from "../services/comida.api";

function SelecaoTipoAlimentacao(props) {
  const [mensagem, setMensagem] = useState("");
  const [tiposAlimentacao, setTiposAlimentacao] = useState([]);

  useEffect(() => {
    comidaApi
      .get("alimentacao/")
      .then((alimentacao) => {
        setTiposAlimentacao(alimentacao.data.map((a) => ({
          label: a.nome,
          value: a.slug
        })));
      })
      .catch((erro) => {
        setMensagem(
          erro.response?.data || "Erro ao consultar tipos de alimentação."
        );
      });
  }, []);

  const onChange = (e) => {
    if (e)
      for (let i = 0; i < e.length; i++) {
        const opcao = e[i];
        if (opcao.label === "Sem Restrições") {
          e = [opcao];
          break;
        }
      }

    props.onChange(e);
  };
  return (
    <div className={props.className}>
      <span className="text-alert">{mensagem}</span>
      <label className="w-100">
        Tipo de alimentação <br />
        <small>Segue alguma dieta? Se não, escolha Sem Restrições</small>
        <Select
          isMulti
          placeholder="Ex.: Vegana, Diet..."
          noOptionsMessage={() => "Sem opções"}
          name="tipoAlimentacao"
          options={tiposAlimentacao}
          className="basic-multi-select"
          classNamePrefix="select"
          value={props.value}
          onChange={onChange}
        />
      </label>
    </div>
  );
}

SelecaoTipoAlimentacao.propTypes = {
  className: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default SelecaoTipoAlimentacao;
