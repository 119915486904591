import React, { useEffect, useState } from "react";
import Container from "../container";
import { useParams } from "react-router-dom";
import comidaApi, { agruparItens } from "../../services/comida.api";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPencilAlt,
  faPlus,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import AdicionarCardapio from "./adicionar.cardapio";
import ItemCardapio from "./item.cardapio";
import FotoUploadCardapio from "./foto.upload.cardapio";
import { Helmet } from "react-helmet";

function EditarCardapio() {
  const [cardapio, setCardapio] = useState(new Array(10));
  const { fornecedor } = useParams();
  const [adicionando, setAdicionando] = useState(false);
  const [editando, setEditando] = useState(false);
  const [itemAtual, setItemAtual] = useState(null);
  const [grupos, setGrupos] = useState([]);
  const [itensOriginais, setItensOriginais] = useState(null)

  var cardapiosTimmer;

  useEffect(() => {
    comidaApi
      .get(`cardapios/fornecedores/${fornecedor}`)
      .then((resultado) => {
        setCardapio(resultado.data);
      })
      .catch((erro) => {
        toast.warn(
          erro.response?.data ||
            "Houve um erro ao consultar este cardápio. Por favor, tente novamente mais tarde."
        );
      });

    comidaApi
      .get(`gruposcardapio/${fornecedor}`)
      .then((resultado) => {
        setGrupos(resultado.data);
      })
      .catch((erro) => {
        toast.warn(
          erro.response?.data ||
            "Houve um erro ao consultar os grupos deste cardápio. Por favor, tente novamente mais tarde."
        );
      });
  }, [fornecedor]);

  const gruposEmUso = agruparItens(cardapio);

  const filtrarCardapios = (evento) => {
    clearTimeout(cardapiosTimmer)

    const texto = evento.target.value

    cardapiosTimmer = setTimeout(() => {
      let itens = [...cardapio]

      if (!itensOriginais)
        setItensOriginais([...cardapio]);
      else
        itens = itensOriginais;

      const itensFiltrados = itens.filter((i) => new RegExp(texto, "i").test(i.grupo) || new RegExp(texto, "i").test(i.nome))

      if (itensFiltrados.length > 0)
        setCardapio(itensFiltrados);
      else
        toast.info(`Nenhum item encontrado com '${texto}'`)
    }, 500);
  };

  return (
    <div>
      <Helmet>
        <title>Editar Cardápio - caseira.app</title>
      </Helmet>
      
      <Container>
        <h4>Editar Cardápio</h4>
        <div className="m-2">
          <Button variant="outline-link" disabled><FontAwesomeIcon icon={faSearch} /></Button><input type='text' placeholder='Pesquisar cardápio...' onChange={filtrarCardapios} />
        </div>
        <Button
          onClick={() => {
            setAdicionando(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> Novo Item
        </Button>
        <AdicionarCardapio
          visivel={adicionando}
          aoOcultar={() => {
            setAdicionando(false);
          }}
          fornecedor={fornecedor}
          grupos={grupos.map((g) => {
            return {
              label: g.nome,
              value: g._id,
            };
          })}
          aoAtualizarGrupos={converterGrupos}
          aoAdicionar={(novoItem) => {
            const cardapioAtualizado = [...cardapio];
            cardapioAtualizado.push(novoItem);
            setCardapio(cardapioAtualizado);
            setAdicionando(false);
          }}
        />
        <AdicionarCardapio
          grupos={grupos.map((g) => {
            return {
              label: g.nome,
              value: g._id,
            };
          })}
          aoAtualizarGrupos={converterGrupos}
          visivel={editando}
          value={itemAtual}
          aoOcultar={() => {
            setEditando(false);
          }}
          fornecedor={fornecedor}
          aoAdicionar={(itemAtualizado) => {
            const cardapioAtualizado = [...cardapio];
            const indice = cardapioAtualizado.findIndex(
              (c) => c._id === itemAtualizado._id
            );
            cardapioAtualizado.splice(indice, 1, itemAtualizado);
            setCardapio(cardapioAtualizado);
            setEditando(false);
          }}
        />
      </Container>
      <Container>
        <h5>
          Itens <strong className="text-success">Disponíveis</strong>
        </h5>
        <div className="row">
          {cardapio
            ?.filter(
              (item) => item.disponivel && (!item.grupo || item.grupo === "")
            )
            .map(mostrarItens())}
        </div>
        {gruposEmUso.map((g) => {
          const itens = cardapio?.filter(
            (item) => item.disponivel && item.grupo === g
          );

          if (itens && itens.length > 0)
            return (
              <div key={g}>
                <h3 className="text-secondary">{g}</h3>
                <div className="row">{itens.map(mostrarItens())}</div>
              </div>
            );
        })}
      </Container>
      <Container>
        <h5>
          Itens <strong className="text-danger">Indisponíveis</strong>
        </h5>
        <div className="row">
          {cardapio?.filter((item) => !item.disponivel).map(mostrarItens())}
        </div>
      </Container>
    </div>
  );

  function converterGrupos(grupos) {
    setGrupos(
      grupos.map((g) => {
        return {
          _id: g.value,
          nome: g.label,
          fornecedor,
        };
      })
    );
  }

  function mostrarItens() {
    return function ElementoCardapio(item, index) {
      return (
        <div key={index} className="col-6 col-md-4 col-lg-3 p-2">
          <ItemCardapio item={item} className="h-100">
            <div>
              <FotoUploadCardapio
                className="m-2"
                id={item._id}
                aoAtualizarImagem={(foto) => {
                  const novoCardapio = [...cardapio];
                  const indice = novoCardapio.findIndex(
                    (c) => c._id === item._id
                  );
                  item.foto = foto;

                  novoCardapio.splice(indice, 1, item);
                  setCardapio(novoCardapio);
                }}
              />
              <Button
                variant="outline-primary"
                className="m-2"
                onClick={() => {
                  setItemAtual(item);
                  setEditando(true);
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />{" "}
                <span className="d-none d-sm-inline">Editar</span>
              </Button>
              {item.disponivel ? (
                <Button
                  variant="outline-danger"
                  className="m-2"
                  onClick={editarDisponibilidade(item)}
                >
                  <FontAwesomeIcon icon={faTimes} />{" "}
                  <span className="d-none d-sm-inline">Retirar</span>
                </Button>
              ) : (
                <Button variant="success" onClick={editarDisponibilidade(item)}>
                  <FontAwesomeIcon icon={faCheck} />{" "}
                  <span className="d-none d-sm-inline">Retornar</span>
                </Button>
              )}
            </div>
          </ItemCardapio>
        </div>
      );
    };
  }

  function editarDisponibilidade(item) {
    return () => {
      comidaApi
        .put(`cardapios/disponibilidade/${item._id}`)
        .then((resultado) => {
          const cardapioAtualizado = [...cardapio];
          const indice = cardapioAtualizado.findIndex(
            (i) => i._id === item._id
          );
          cardapioAtualizado.splice(indice, 1, resultado.data);
          setCardapio(cardapioAtualizado);
        })
        .catch((erro) => {
          toast.warn(
            erro.response?.data ||
              "Houve um erro ao atualizar este item. Por favor, tente novamente mais tarde."
          );
        });
    };
  }
}

export default EditarCardapio;
