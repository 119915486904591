import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import ItemCardapio from '../cardapios/item.cardapio'

function RemoveItemPersonalizado(props) {
    return (
        <Modal show={props.visivel} onHide={props.aoOcultar} keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Removendo item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {mostrarItens(props.itens)}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary text-white"
                    onClick={props.aoOcultar}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    )

    function mostrarItens(itens) {
        return itens?.map((item, index) => {
            return (
                <div key={index} className="p-2">
                    <ItemCardapio item={item} className="h-100">
                        <Button
                            variant="primary text-white"
                            onClick={() =>
                                props.enviar ? props.enviar(item) : props.aoOcultar()
                            }>
                            <FontAwesomeIcon icon={faTrash} /> Remover
                                    </Button>
                    </ItemCardapio>
                </div>
            );
        })
    }
}


RemoveItemPersonalizado.propTypes = {
    visivel: PropTypes.bool,
    aoOcultar: PropTypes.func,
    itens: PropTypes.any,
    enviar: PropTypes.func
}

export default RemoveItemPersonalizado

