import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'

export default function Busca(props) {
    const [valor, setValor] = useState(null)
    const history = useHistory();

    const enviar = (evento) => {
        evento.preventDefault();

        history.push(
            `/receitas/busca?q=${valor}`,
            [{ query: valor }]
        );
    };

    return (
        <form
            className={props.className || "d-flex"}
            onSubmit={enviar}
        >
            <input
                className="form-control"
                type="search"
                placeholder="Pesquisar..."
                aria-label="Pesquisar"
                onChange={(texto) => {
                    setValor(texto.target.value)
                }}
            />
            <button
                className="btn btn-outline-secondary me-sm-5"
                type="submit"
            >
                <FontAwesomeIcon icon={faSearch} />
            </button>
        </form>
    )
}

Busca.propTypes = {
    className: PropTypes.string
}
