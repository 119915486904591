import React from "react";
import queryString from "query-string";
import { Link } from "react-router-dom";
import comidaApi from "../../services/comida.api";
import CardReceita from "./card.receita";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Container from "../container";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import Loading from "../loading";
import BotaoFabReceita from "../botao.fab.receita";

export default class IndexReceitas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alimentacao: [],
      receitas: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      pagination: {
        activePage: 1,
        totalPages: 1,
      },
      tipoAlimentacao: "",
      query: "",
      carregando: true,
    };

    this.loader = React.createRef()
  }

  componentDidMount() {
    const opcoes = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0
    }

    const observer = new IntersectionObserver((entidades) => {
      const alvo = entidades[0]
      if (alvo.isIntersecting) {

        const { activePage, totalPages } = this.state.pagination

        if (activePage < totalPages)
          this.paginaAlterada(activePage + 1)

      }
    }, opcoes)

    if (this.loader.current) {
      observer.observe(this.loader.current)
    }

    this.atualizarDados();
  }

  componentDidUpdate(prevProps) {
    if (
      !this.state.carregando &&
      prevProps.location.state &&
      prevProps.location.state[0] !== this.props.location.state[0]
    )
      this.atualizarDados();
  }

  atualizarDados() {
    this.setState({ carregando: true }, () => {
      const tipoAlimentacao = this.props.match.params.tipo;
      const query = queryString.parse(this.props.location.search).q;
      const user = this.props.match.params.user;

      comidaApi
        .get(
          `receitas?user=${user || ""}&tipo=${tipoAlimentacao || ""}&q=${query || ""
          }&limite=12&pagina=${this.state.pagination.activePage}&completas=true`
        )
        .then((response) => {
          this.setState({
            receitas: this.state.pagination.activePage === 1
              ? response.data.docs
              : [...this.state.receitas, ...response.data.docs],
            tipoAlimentacao: response.data.tipo,
            query,
            pagination: {
              activePage: response.data.page,
              totalPages: response.data.totalPages,
            },
            carregando: false,
          });
        })
        .catch(() => {
          this.setState({ carregando: false });
          toast.warn(
            "Houve um erro ao consultar os tipos de alimentaçao. Por favor, tente novamente mais tarde.",
          );
        });
    });
  }

  paginaAlterada(numero) {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          activePage: numero,
        },
      },
      () => {
        this.atualizarDados(numero);
      }
    );
  }

  render() {
    return (
      <Container>
        <Helmet>
          <title>Receitas - caseira.app</title>
        </Helmet>
        <h5>
          <FontAwesomeIcon icon={faBook} /> Receitas{" "}
          {this.state.tipoAlimentacao}
        </h5>
        

        <div className="row">
          {this.state.receitas.length > 0
            ? this.exibirReceitas()
            : this.exibirSemReceitas()}
        </div>

        <div className={this.state.pagination.activePage < this.state.pagination.totalPages ? "alert alert-info" : "d-none"} ref={this.loader}>
          <Loading>
            <p>Consultando mais receitas...</p>
          </Loading>
        </div>
        <BotaoFabReceita />
      </Container>
    );
  }

  exibirReceitas() {
    return this.state.receitas.map((receita, index) => {
      return (
        <div
          className="col-sm-6 col-md-4 col-lg-3 p-2"
          key={receita._id || index}
        >
          <Link to={`/receitas/${receita.slug}`}>
            <CardReceita receita={receita} />
          </Link>
        </div>
      );
    });
  }

  exibirSemReceitas() {
    return (
      <div className="alert alert-info m-2" role="alert">
        <p>
          Ainda não temos receitas{" "}
          {this.state.tipoAlimentacao || ''}{" "}
          {this.state.query
            ? `com nome "${this.state.query}" ou com "${this.state.query}" em seus ingredientes`
            : ""}
          .
        </p>
        <p>
          Conhece uma receita assim? Ensina pra gente!{" "}
          <Link to="/receitas/criar">Clique aqui</Link>
        </p>
      </div>
    );
  }
}

IndexReceitas.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};