import React, { useCallback, useRef } from "react";
import caseiraApi from "../../services/comida.api";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import Loading from "../loading";
import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

const pixelRatio =
  typeof window != "undefined" ? window.devicePixelRatio || 1 : null;

function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
}

export default function FotoUploadReceita(props) {
  const [visivel, setVisivel] = useState(false);
  const [crop, setCrop] = useState({
    aspect: 4 / 3,
    unit: "%",
    width: 100,
    x: 25,
  });
  const imageRef = useRef(null);
  const canvasAmostraRef = useRef(null);
  const [recorteConcluido, setRecorteConcluido] = useState(null);
  const [caminhoImagem, setCaminhoImagem] = useState(null);
  const [carregando, setCarregando] = useState(false);

  const generateDownload = () => {
    setCarregando(true);
    const previewCanvas = canvasAmostraRef.current;
    const crop = recorteConcluido;
    if (!crop || !previewCanvas) {
      return;
    }

    const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

    canvas.toBlob(
      (blob) => {
        let foto = new FormData();
        foto.append("file", blob);
        caseiraApi
          .put(`fotos\\${props.id}`, foto)
          .then((res) => {
            setCarregando(false);
            setVisivel(false);
            props.aoAtualizarImagem(res.data.foto);
          })
          .catch(() => {
            setCarregando(false);
            toast.warn(
              `Houve um erro ao atualizar a foto da receita. Por favor, tente novamente mais tarde.`
            );
          });
      },
      "image/png",
      1
    );
  };

  const aoSelecionarFoto = (e) => {
    setCarregando(true);

    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setCaminhoImagem(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setCarregando(false);
    }
  };

  const onImageLoaded = useCallback((image) => {
    imageRef.current = image;
  }, []);

  useEffect(() => {
    if (!recorteConcluido || !canvasAmostraRef.current || !imageRef.current) {
      return;
    }

    const image = imageRef.current;
    const canvas = canvasAmostraRef.current;
    const crop = recorteConcluido;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [recorteConcluido]);

  return (
    <div>
      <Button variant="light" onClick={() => setVisivel(true)}>
        <FontAwesomeIcon icon={faCamera} /> editar
      </Button>
      <Modal
        show={visivel}
        onHide={() => setVisivel(false)}
        dialogClassName="uploadfoto"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faCamera} /> foto
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            accept="image/*"
            onChange={aoSelecionarFoto}
            disabled={carregando}
          />
          {carregando ? <Loading /> : null}
          {caminhoImagem && (
            <div>
              <h6>Recortando a foto</h6>
              <ReactCrop
                src={caminhoImagem}
                crop={crop}
                ruleOfThirds
                onImageLoaded={onImageLoaded}
                onComplete={(recorte) => setRecorteConcluido(recorte)}
                onChange={(recorte) => setCrop(recorte)}
              />
            </div>
          )}
          <div>
            {recorteConcluido && (
              <div>
                <h6>Resultado</h6>
                <small>Clique em Enviar para continuar...</small>
              </div>
            )}
            <canvas
              ref={canvasAmostraRef}
              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
              style={{
                width: Math.round(recorteConcluido?.width ?? 0),
                height: Math.round(recorteConcluido?.height ?? 0),
              }}
            />
          </div>
          {caminhoImagem && (
            <Button onClick={generateDownload} disabled={carregando}>
              {carregando ? <Loading /> : "Enviar"}
            </Button>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

FotoUploadReceita.propTypes = {
  id: PropTypes.string.isRequired,
  aoAtualizarImagem: PropTypes.func,
};
