export default class Plano {
  get User() {
    return this.user;
  }

  set User(value) {
    this.user = value;
  }

  get Dia() {
    return this.dia;
  }

  set Dia(value) {
    this.dia = value;
  }

  get Receitas() {
    return this.receitas;
  }

  set Receitas(value) {
    this.receitas = value;
  }
}
