import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Fab } from 'react-tiny-fab';
import { ConsultarQuantidadeTotalSacola } from '../actions/cardapio';

export default function BotaoFabSacola() {
    const history = useHistory();
    const sacola = useSelector(state => state.sacola)

    const mostrarQuantidade = () => {
        const quantidade = ConsultarQuantidadeTotalSacola(sacola)

        return quantidade > 0 ? quantidade : null
    }

    if (typeof window === "undefined")
        return null

    return (
        <Fab
            event="click"
            mainButtonStyles={{ backgroundColor: "#D35500" }}
            style={{ bottom: '40px', right: '25px' }}
            icon={<><FontAwesomeIcon icon={faShoppingBasket} />{mostrarQuantidade()}</>}
            onClick={() => { history.push("/sacola") }}
        ></Fab>
    )
}
