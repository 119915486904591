import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function ListaTipoAlimentacao(props) {
  return (
    <div className={props.className}>
      {props.values?.map((t, index) => (
        <Link key={index} className="text-white" to={`/receitas/tipos/${t.slug}`}>
          <span className="badge badge-primary p-1 m-1">
            {t.nome}
          </span>
        </Link>
      ))}
    </div>
  );
}

ListaTipoAlimentacao.propTypes = {
  className: PropTypes.string,
  values: PropTypes.array,
};

export default ListaTipoAlimentacao;
