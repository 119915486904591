import { INCLUIR_ITEM_SACOLA, LIMPAR_SACOLA, EXCLUIR_ITEM_SACOLA } from "../constants/action-types";

export const DoIncluirItemSacola = payload => ({
    type: INCLUIR_ITEM_SACOLA,
    payload
})

export const DoExcluirItemSacola = payload => ({
    type: EXCLUIR_ITEM_SACOLA,
    payload
})

export const ChecarItemPersonalizavel = item => (
    item.opcoesPeso || item.personalizavel || item.variavel
)

export const ConsultarItemSacola = (sacola, item) => (
    sacola ? sacola[item._id + (item.fornecedor._id || item.fornecedor)] : null
)

export const ConsultarQuantidadeTotalSacola = sacola => {
    const itens = Array.prototype.concat(...Object.values(sacola || {}))

    const total = itens.reduce((r, i) => r + (i.quantidade || 1), 0)

    return total
}

export const DoLimparSacola = () => ({
    type: LIMPAR_SACOLA
})