import React, { useEffect, useState } from "react";
import FormValidator from "../FormValidator";
import comidaApi from "../../services/comida.api";
import User from "../../models/user";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DoAtualizarMenu, DoLogIn, DoOcultarMenu } from "../../actions";
import queryString from "query-string";
import Message from "../message";
import Container from "../container";

export function NovaSenha(props) {
  const passwordMatch = (confirmation, state) => state.senha === confirmation;

  const validator = new FormValidator([
    {
      field: "senha",
      method: "isEmpty",
      validWhen: false,
      message: "Informe uma senha para seu acesso.",
    },
    {
      field: "confirmacaoSenha",
      method: "isEmpty",
      validWhen: false,
      message: "Digite novamente sua senha.",
    },
    {
      field: "confirmacaoSenha",
      method: passwordMatch, // notice that we are passing a custom function here
      validWhen: true,
      message: "As senhas informadas não conferem. Tente novamente.",
    },
  ]);

  const [senha, setSenha] = useState("");
  const [confirmacaoSenha, setConfirmacaoSenha] = useState("");
  const [validacao, setValidacao] = useState(validator.valid());
  const [mensagem, setMensagem] = useState("");

  const user = new User();

  useEffect(() => {
    props.DoOcultarMenu()
    return () => {
      props.DoAtualizarMenu();
    }
  }, [])

  const onSubmit = (evento) => {
    evento.preventDefault();

    const valido = validator.validate({
      senha,
      confirmacaoSenha,
    });

    setValidacao(valido);

    if (valido.isValid) {
      const codigoNovaSenha = queryString.parse(props.location.search).c;

      comidaApi
        .put(`users/${codigoNovaSenha}`, { password: senha })
        .then((retorno) => {
          user.Information = retorno.data;
          props.DoLogIn(retorno.data.user.nome, retorno.data.user.grupos);
          props.history.push("/planejamento");
        })
        .catch((erro) => {
          setMensagem(
            erro.response
              ? erro.response.data
              : "Erro ao atualizar senha. Tente novamente mais tarde."
          );
        });
    }
  };

  return (
    <Container>
      <h5>Nova Senha</h5>
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="mb-3 col-md-6">
            <label className="w-100">
              Nova Senha
              <input
                name="senha"
                type="password"
                className="form-control"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              />
            </label>
            <span className="text-danger">{validacao.senha.message}</span>
          </div>
        </div>
        <div className="row">
          <div className="mb-3 col-md-6">
            <label className="w-100">
              Confirme sua nova senha
              <input
                name="confirmacaoSenha"
                type="password"
                className="form-control"
                value={confirmacaoSenha}
                onChange={(e) => setConfirmacaoSenha(e.target.value)}
              />
            </label>
            <span className="text-danger">
              {validacao.confirmacaoSenha.message}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="mb-3 col-md-3">
            <button type="submit" className="btn btn-primary text-white">
              Cadastrar
            </button>
          </div>
        </div>
      </form>
      <Message message={mensagem} />
    </Container>
  );
}

NovaSenha.propTypes = {
  location: PropTypes.object,
  DoLogIn: PropTypes.func,
  DoOcultarMenu: PropTypes.func,
  DoAtualizarMenu: PropTypes.func,
  history: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    DoLogIn: (nome, grupos) => dispatch(DoLogIn({ loggedin: true, nome, grupos })),
    DoOcultarMenu: () => dispatch(DoOcultarMenu()),
    DoAtualizarMenu: () => dispatch(DoAtualizarMenu()),
  };
};

export default connect(null, mapDispatchToProps)(NovaSenha);
