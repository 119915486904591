import React from "react";
import comidaApi from "../../services/comida.api";
import { DoLogIn, DoAtualizarGrupos } from "../../actions";
import { connect } from "react-redux";
import User from "../../models/user";
import Email from "../login/email";
import FormValidator from "../FormValidator";
import Message from "../message";
import PropTypes from "prop-types";
import {
  validacaoNome,
  validacaoEmail,
  validacaoSenha,
  validacaoConfirmacaoSenha,
} from "./validacoes";
import SelecaoTipoAlimentacao from "../selecaoTipoAlimentacao";
import { Link } from "react-router-dom";
import BotaoFacebookLogin from "./facebook.login.button";
import Container from "../container";
import queryString from "query-string";
import SelecaoEstado from "../selecaoEstado";
import SelecaoCidade from "../selecaoCidade";

const user = new User();

function mapDispatchToProps(dispatch) {
  return {
    DoAtualizarGrupos: (payload) => dispatch(DoAtualizarGrupos(payload)),
    DoLogIn: (loggedin, nome, grupos) =>
      dispatch(DoLogIn({ loggedin, nome, grupos })),
  };
}

class ConnectedCreateLogin extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.tipoAlimentacaoSelecionada = this.tipoAlimentacaoSelecionada.bind(
      this
    );
    this.estadoEscolhido = this.estadoEscolhido.bind(this);
    this.cidadeEscolhida = this.cidadeEscolhida.bind(this);
    this.facebookResponse = this.facebookResponse.bind(this);
    const editando = this.props.location.pathname.includes("editar");

    let validacoes = [];
    validacoes = validacoes.concat(validacaoNome);
    validacoes = validacoes.concat(validacaoEmail);

    if (!editando) {
      validacoes = validacoes.concat(validacaoSenha);
      validacoes = validacoes.concat(validacaoConfirmacaoSenha);
    }

    this.validator = new FormValidator(validacoes);

    this.state = {
      email: "",
      password: "",
      passwordConfirmation: "",
      validation: this.validator.valid(),
      message: "",
      processing: true,
      tipoAlimentacao: [],
      nome: "",
      cidade: "",
      cidadeSelecionada: null,
      estado: "",
      estadoSelecionado: null,
      editando: false,
    };

    this.submitted = false;
  }

  componentDidMount() {
    this.setState(
      {
        editando: this.props.location.pathname.includes("editar"),
      },
      () => {
        if (this.state.editando) {
          comidaApi
            .get(`users/${user.Information.user._id}`)
            .then((usuario) => {
              this.setState({
                processing: false,
                nome: usuario.data.nome,
                email: usuario.data.email,
                tipoAlimentacao: usuario.data.tipoAlimentacao.map((t) => ({
                  value: t,
                  label: t,
                })),
                estadoSelecionado: {
                  value: usuario.data.estado?.ID,
                  label: usuario.data.estado?.Nome,
                },
                cidadeSelecionada: {
                  value: usuario.data.cidade?.ID,
                  label: usuario.data.cidade?.Nome,
                },
              });
            })
            .catch((error) => {
              this.setState({
                message: error.response
                  ? error.response.data
                  : "Houve um erro ao consultar o servidor. Por favor, tente novamente mais tarde.",
              });
            });
        } else {
          this.setState({
            processing: false,
          });
        }
      }
    );
  }

  tipoAlimentacaoSelecionada(evento) {
    this.setState({
      tipoAlimentacao: evento,
    });
  }

  handleInputChange(e) {
    e.preventDefault();

    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  estadoEscolhido(e) {
    this.setState({
      estado: e.label,
      estadoSelecionado: e,
    });
  }

  cidadeEscolhida(e) {
    this.setState({
      cidade: e.label,
      cidadeSelecionada: e,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      message: "",
      processing: true,
    });

    let validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      const obj = {
        email: this.state.email,
        password: this.state.password,
        nome: this.state.nome,
        estado: this.state.estado,
        cidade: this.state.cidade,
        tipoAlimentacao: this.state.tipoAlimentacao.map((t) => t.label),
      };

      if (this.state.editando) {
        comidaApi
          .put("users", obj)
          .then(() => {
            this.props.history.push("/planejamento");
          })
          .catch((erro) => {
            this.setState({
              message: erro.response
                ? erro.response.data
                : "Erro ao atualizar usuário. Tente novamente mais tarde.",
              processing: false,
            });
          });
      } else {
        this.cadastrar(obj);
      }
    } else {
      this.setState({ processing: false });
    }
  }

  cadastrar(obj) {
    comidaApi
      .post("users", obj)
      .then((res) => {
        const destino = queryString.parse(this.props.location.search).q;

        user.Information = res.data;
        this.props.DoLogIn(res.data.user.nome, res.data.user.grupos);
        if (destino) this.props.history.push(destino);
        else this.props.history.push("/planejamento");
      })
      .catch((erro) => {
        this.setState({
          message: erro.response
            ? erro.response.data
            : "Erro ao cadastrar usuário. Tente novamente mais tarde.",
          processing: false,
        });
      });
  }

  facebookResponse(resultado) {
    this.props.DoLogIn(resultado.data.user.nome, resultado.data.user.grupos);
    this.props.history.push("/usuarios/editar");
  }

  render() {
    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    return (
      <Container>
        <h5>Cadastro</h5>
        <div className={`mb-2 ${this.state.editando ? "d-none" : "d-block"}`}>
          <small>ou acesse com:</small>
          <br />
          <BotaoFacebookLogin aoLogar={this.facebookResponse} />
        </div>
        <form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="mb-3 col-md-6">
              <label className="w-100">
                Nome Completo
                <input
                  name="nome"
                  type="text"
                  className="form-control"
                  value={this.state.nome}
                  onChange={this.handleInputChange}
                />
              </label>
              <span className="text-danger">{validation.nome?.message}</span>
            </div>
          </div>
          <div className="row">
            <Email
              name="email"
              onChangeEmail={this.handleInputChange}
              email={this.state.email}
              validation={validation.email?.message}
            />
          </div>
          <div className={this.state.editando ? "d-none" : "row"}>
            <div className="mb-3 col-md-6">
              <label className="w-100">
                Senha
                <input
                  name="password"
                  type="password"
                  className="form-control"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                />
              </label>
              <span className="text-danger">
                {validation.password?.message}
              </span>
            </div>
          </div>
          <div className={this.state.editando ? "d-none" : "row"}>
            <div className="mb-3 col-md-6">
              <label className="w-100">
                Confirme sua Senha
                <input
                  name="passwordConfirmation"
                  type="password"
                  className="form-control"
                  value={this.state.passwordConfirmation}
                  onChange={this.handleInputChange}
                />
              </label>
              <span className="text-danger">
                {validation.passwordConfirmation?.message}
              </span>
            </div>
          </div>
          <SelecaoEstado
            value={this.state.estadoSelecionado}
            onChange={this.estadoEscolhido}
          />
          <SelecaoCidade
            estado={this.state.estadoSelecionado}
            value={this.state.cidadeSelecionada}
            onChange={this.cidadeEscolhida}
          />
          <div className="row">
            <SelecaoTipoAlimentacao
              className="mb-3 col-md-6"
              name="tipoAlimentacao"
              value={this.state.tipoAlimentacao}
              onChange={this.tipoAlimentacaoSelecionada}
            />
          </div>
          <div className="row">
            <div className="mb-3 col-md-6">
              <Message message={this.state.message} />
              <button
                type="submit"
                className="btn btn-primary text-white"
                disabled={this.state.processing}
              >
                {this.state.editando ? "Atualizar" : "Cadastrar"}
              </button>
              {
                this.state.editando ? <Link
                  to="/receitas"
                  type="button"
                  onClick={() => {
                    if (confirm("Tem certeza que deseja excluir sua conta?") == true) {
                      comidaApi.delete("users").then(() => {
                        this.props.DoAtualizarGrupos(null);
                        user.logOut();
                        this.props.DoLogIn(false, null, null);

                      })
                    }
                  }}
                  className="btn ms-2 btn-outline-danger"
                  disabled={this.state.processing}
                >
                  Exclir Conta
                </Link> : null
              }
            </div>
          </div>
        </form>
        <address>
          Ao clicar em {this.state.editando ? "Atualizar" : "Cadastrar"}, você
          concorda com nossa{" "}
          <Link to="/politica">política de uso de dados</Link> e{" "}
          <Link to="/termo">termos gerais</Link>.
        </address>
      </Container>
    );
  }
}

ConnectedCreateLogin.propTypes = {
  DoLogIn: PropTypes.func,
  DoAtualizarGrupos: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
};

const CreateLogin = connect(null, mapDispatchToProps)(ConnectedCreateLogin);

export default CreateLogin;
