import React from "react";
import {  toast } from "react-toastify";
import comidaApi from "../../services/comida.api";
import DescriptionReceita from "./description.receita.ingrediente";
import CardImagemReceita from "./card.imagem.receita";
import FotoUploadReceita from "./foto.upload.receita";
import PropTypes from "prop-types";
import SelecaoTipoAlimentacao from "../selecaoTipoAlimentacao";
import { Link } from "react-router-dom";
import NomeReceita from "./nomeReceita";
import ModoPreparo from "./modoPreparo";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faBook, faClock, faPencilAlt, faSave, faTimes, faTv, faUsers, faWifi } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import Container from "../container";

const estiloSalvar = {
  position: "fixed",
  bottom: 55,
  right: 0,
};

const opcoesReferencia = [
  {
    label: (
      <div>
        <FontAwesomeIcon icon={faInstagram} style={{ color: "#DD2A7B" }} />{" "}
        Instagram
      </div>
    ),
    value: "instagram",
    placeholder: "Ex.: @nome_da_referencia",
  },
  {
    label: (
      <div>
        <FontAwesomeIcon icon={faFacebook} style={{ color: "#3B5998" }} />{" "}
        Facebook
      </div>
    ),
    value: "facebook",
    placeholder: "Ex.: nome_do_perfil",
  },
  {
    label: (
      <div>
        <FontAwesomeIcon icon={faWifi} className="text-secondary" /> Internet
      </div>
    ),
    value: "internet",
    placeholder: "Ex.: https://site_da_receita.com.br",
  },
  {
    label: (
      <div>
        <FontAwesomeIcon icon={faBook} style={{ color: "#D35500" }} /> Livro
      </div>
    ),
    value: "livro",
    placeholder: "Ex.: Nome do Livro",
  },
  {
    label: (
      <div>
        <FontAwesomeIcon icon={faTv} className="text-primary" /> Programa de TV
      </div>
    ),
    value: "tv",
    placeholder: "Ex.: Nome do Programa de TV",
  },
];

export default class EditReceita extends React.Component {
  constructor(props) {
    super(props);

    this.onIngredienteAdicionado = this.onIngredienteAdicionado.bind(this);
    this.onIngredienteExcluido = this.onIngredienteExcluido.bind(this);
    this.onPreparoChange = this.onPreparoChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.aoAtualizarImagem = this.aoAtualizarImagem.bind(this);
    this.tipoAlimentacaoSelecionada = this.tipoAlimentacaoSelecionada.bind(
      this
    );

    this.state = {
      slug: null,
      nome: "",
      ingredientes: [],
      referencia: "",
      preparo: "",
      foto: null,
      rendimento: "",
      tempoPreparo: "",
      tipoAlimentacao: [],
      tipoReferencia: null,
    };
  }

  componentDidMount() {
    comidaApi.get("receitas/" + this.props.match.params.id).then((response) => {
      this.setState({
        slug: response.data.slug,
        nome: response.data.nome,
        referencia: response.data.referencia,
        ingredientes: response.data.ingredientes
          ? response.data.ingredientes
          : [],
        preparo: response.data.preparo,
        foto: response.data.foto,
        rendimento: response.data.rendimento,
        tempoPreparo: response.data.tempoPreparo,
        tipoAlimentacao: response.data.tipoAlimentacao?.map((t) => ({
          value: t.slug,
          label: t.nome,
        })),
        tipoReferencia: opcoesReferencia.find(
          (o) => o.value === response.data.tipoReferencia
        ),
      });
    });
  }

  aoAtualizarImagem(foto) {
    this.setState({
      foto,
    });
  }

  onInputChange(evento) {
    evento.preventDefault();

    this.setState({
      [evento.target.name]: evento.target.value,
    });
  }

  tipoAlimentacaoSelecionada(evento) {
    this.setState({
      tipoAlimentacao: evento,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const obj = {
      nome: this.state.nome,
      referencia: this.state.referencia,
      ingredientes: this.state.ingredientes,
      preparo: this.state.preparo,
      rendimento: this.state.rendimento,
      tempoPreparo: this.state.tempoPreparo,
      tipoAlimentacao: this.state.tipoAlimentacao?.map((t) => ({
        nome: t.label,
        slug: t.value,
      })),
      tipoReferencia: this.state.tipoReferencia?.value,
    };

    comidaApi.put("receitas/" + this.props.match.params.id, obj).then(() => {
      this.props.history.push("/receitas/" + this.state.slug);
    });
  }

  onIngredienteAdicionado(e) {
    const ingrediente = this.state.ingredientes.find((i) => i._id === e._id);
    if (ingrediente) {
      toast.warn("Ingrediente existe na receita. Verifique.");
    } else {
      this.setState((s) => ({
        ingredientes: s.ingredientes.concat(e),
      }));
    }
  }

  onIngredienteExcluido(e) {
    this.setState({
      ingredientes: this.state.ingredientes?.filter((_, i) => i !== e),
    });
  }

  onPreparoChange(e) {
    this.setState({
      preparo: e.target.value,
    });
  }

  render() {
    return (
      <Container>
        <Helmet>
          <title>{this.state.nome} - caseira.app</title>
        </Helmet>
        
        <h5>
          <FontAwesomeIcon icon={faPencilAlt} /> Receita
        </h5>
        <div className="row">
          <div className="p-3 col-lg-5 text-center">
            <CardImagemReceita imagem={this.state.foto} />
            <FotoUploadReceita
              id={this.props.match.params.id}
              aoAtualizarImagem={this.aoAtualizarImagem}
            />
          </div>
          <form onSubmit={this.onSubmit} className="col-lg-6">
            <NomeReceita
              className="mb-3"
              onChange={this.onInputChange}
              value={this.state.nome}
              disabled
            />
            <div className="mb-3">
              <label className="w-100">
                Referência
                <Select
                  value={this.state.tipoReferencia}
                  placeholder="Essa receita é sua ou viu em algum lugar?"
                  options={opcoesReferencia}
                  onChange={(e) => this.setState({ tipoReferencia: e })}
                />
              </label>
              <input
                placeholder={this.state.tipoReferencia?.placeholder}
                name="referencia"
                type="text"
                className={
                  this.state.tipoReferencia ? "form-control" : "d-none"
                }
                value={this.state.referencia || ""}
                onChange={this.onInputChange}
              />
            </div>
            <div className="mb-3">
              <label className="w-100">
                <FontAwesomeIcon icon={faClock} /> Tempo de Preparo
                <input
                  name="tempoPreparo"
                  type="number"
                  className="form-control"
                  value={this.state.tempoPreparo || ""}
                  onChange={this.onInputChange}
                  placeholder="Quantos minutos de preparo?"
                />
              </label>
            </div>
            <div className="mb-3">
              <label className="w-100">
                <FontAwesomeIcon icon={faUsers} /> Serve
                <input
                  name="rendimento"
                  type="number"
                  className="form-control"
                  value={this.state.rendimento || ""}
                  onChange={this.onInputChange}
                  placeholder="Quantas pessoas?"
                />
              </label>
            </div>
            <SelecaoTipoAlimentacao
              className="mb-3"
              name="tipoAlimentacao"
              value={this.state.tipoAlimentacao}
              onChange={this.tipoAlimentacaoSelecionada}
            />
            <div className="mb-3">
              <DescriptionReceita
                ingredientes={this.state.ingredientes}
                onIngredienteExcluido={this.onIngredienteExcluido}
                onIngredienteAdicionado={this.onIngredienteAdicionado}
              />
            </div>
            <ModoPreparo
              className="mb-3 mb-5"
              value={this.state.preparo}
              onChange={this.onPreparoChange}
            />
            <div
              className="w-100 bg-light p-2 text-center border"
              style={estiloSalvar}
            >
              <button
                type="submit"
                disabled={this.state.processing}
                className="btn btn-primary text-white"
              >
                <FontAwesomeIcon icon={faSave} /> Salvar
              </button>
              <Link
                to={`/receitas/${this.state.slug}`}
                className="btn btn-outline-secondary ms-3"
              >
                <FontAwesomeIcon icon={faTimes} /> Cancelar
              </Link>
            </div>
          </form>
        </div>
      </Container>
    );
  }
}

EditReceita.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};
