import React from "react";
import Select from "react-select";
import SelectIngredientes from "../ingredientes/select.ingredientes";
import comidaApi from "../../services/comida.api";
import { Modal, Toast, Button } from "react-bootstrap";
import Loading from "../loading";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

export default class AddIngrediente extends React.Component {
  constructor(props) {
    super(props);

    this.onChangeMedida = this.onChangeMedida.bind(this);
    this.onChangeQuantidade = this.onChangeQuantidade.bind(this);
    this.onIngredienteSelectionado = this.onIngredienteSelectionado.bind(this);
    this.ocultarModal = this.ocultarModal.bind(this);
    this.enviar = this.enviar.bind(this);

    this.state = {
      quantidade: "",
      medida: null,
      ingrediente: null,
      medidas: [],
      visivel: false,
      alerta: {
        visivel: false,
        titulo: "",
        mensagem: "",
      },
    };
  }

  componentDidMount() {
    comidaApi
      .get(`medidas`)
      .then((res) => {
        this.setState({
          medidas: res.data.map((m) => {
            return {
              value: m.abreviacao ? m.abreviacao : m.nome,
              label: m.nome,
            };
          }),
        });
      })
      .catch(() => {
        this.setState({
          alerta: {
            visivel: true,
            titulo: "Erro - Medidas",
            mensagem:
              "Houve um erro ao consultar as medidas. Tente novamente mais tarde.",
          },
        });
      });
  }

  ocultarModal() {
    this.setState({
      visivel: false,
    });
  }

  onChangeMedida(e) {
    this.setState({
      medida: e,
    });
  }

  onChangeQuantidade(e) {
    this.setState({
      quantidade: e.target.value,
    });
  }

  onIngredienteSelectionado(e) {
    this.setState({
      ingrediente: e,
    });
  }

  render() {
    return (
      <div style={this.props.style}>
        <Button
          variant="success"
          onClick={() => this.setState({ visivel: true })}
        >
          <FontAwesomeIcon icon={faPlus} /> ingrediente
        </Button>
        <Modal show={this.state.visivel} onHide={this.ocultarModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FontAwesomeIcon icon={faPlus} /> ingrediente
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Toast
              show={this.state.alerta.visivel}
              onClose={() => {
                this.setState({
                  alerta: { visivel: false },
                });
              }}
            >
              <Toast.Header>
                <strong className="me-auto">{this.state.alerta.titulo}</strong>
              </Toast.Header>
              <Toast.Body>{this.state.alerta.mensagem}</Toast.Body>
            </Toast>
            <form>
              <div className="mb-3">
                <label className="w-100">
                  Ingrediente
                  <br />
                  <small className="text-secondary">
                    <strong>
                      <span className="text-danger">Não </span>
                    </strong>{" "}
                    descreva a forma do ingrediente como:{" "}
                    <strong>em cubos, descascada, em tiras</strong>. Utilize só
                    nome do ingrediente e use o modo de preparo para orientar.
                  </small>
                  <SelectIngredientes
                    placeholder="Selecione ou digite um ingrediente..."
                    value={this.state.ingrediente}
                    onIngredienteSelectionado={this.onIngredienteSelectionado}
                  />
                </label>
              </div>
              <div className="mb-3">
                <label className="w-100">
                  Quantidade
                  <input
                    type="text"
                    placeholder="Ex.: 1, 1 1/2, 3/4"
                    className="form-control"
                    value={this.state.quantidade}
                    onChange={this.onChangeQuantidade}
                  />
                </label>
              </div>
              <div className="mb-3">
                <label className="w-100">
                  Medida
                  <Select
                    value={this.state.medida}
                    placeholder="Selecione a medida..."
                    onChange={this.onChangeMedida}
                    options={this.state.medidas}
                  />
                </label>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary text-white"
              disabled={this.state.processing}
              onClick={this.enviar}
            >
              {this.state.processing ? (
                <Loading />
              ) : (
                <span>
                  <FontAwesomeIcon icon={faCheck} /> Adicionar
                </span>
              )}
            </Button>
            <Button variant="outline-secondary" onClick={this.ocultarModal}>
              <FontAwesomeIcon icon={faTimes} /> Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  enviar() {
    if (this.state.ingrediente === null) {
      this.setState({
        alerta: {
          visivel: true,
          titulo: "Faltou o ingrediente",
          mensagem: "Selecione um ingrediente e tente novamente.",
        },
      });
      return;
    }

    const ingrediente = {
      _id: this.state.ingrediente.value,
      nome: this.state.ingrediente.label,
      quantidade: this.state.quantidade !== "" ? this.state.quantidade : 0,
      medida: this.state.medida ? this.state.medida.value : "",
    };
    this.setState(
      {
        quantidade: "",
        medida: null,
        ingrediente: null,
        visivel: false,
      },
      () => {
        if (this.props.onIngredienteAdicionado)
          this.props.onIngredienteAdicionado(ingrediente);
      }
    );
  }
}

AddIngrediente.propTypes = {
  onIngredienteAdicionado: PropTypes.func,
  style: PropTypes.object,
};
