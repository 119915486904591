import comidaApi from "../services/comida.api"

export default {
    getIngredientes(callback) {
        comidaApi.get("receitas")
            .then(response => {
                callback(response.data)
            })
            .catch(function () {
            })
    }
}