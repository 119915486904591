import React from "react";
import PropTypes from "prop-types";
import ListaTipoAlimentacao from "../listaTipoAlimentacao";
import { Card } from "react-bootstrap";
import { ChecarItemPersonalizavel } from "../../actions/cardapio";

const espacoReservadoReceita = "/espacoReservadoReceita.jpg";

function ItemProduto(props) {
  return (
    <Card className={props.className}>
      <Card.Body className="d-flex flex-column">
        <Card.Img
          src={props.item.foto || espacoReservadoReceita}
          style={{
            maxHeight: "13rem",
            maxWidth: "100%",
            objectFit: "cover",
          }}
        />
        <Card.Title style={{ fontSize: '1rem' }}>
          {props.item.nome}
        </Card.Title>
        <Card.Subtitle>
          <ListaTipoAlimentacao
            className="mb-2"
            values={props.item.tipoAlimentacao}
          />
        </Card.Subtitle>
        <Card.Text >
          {props.item.descricao && (
            <small className="d-block mb-2">{props.item.descricao}</small>
          )}
        </Card.Text>
        {props.item.personalizacao && (
          <ul className="list-group list-group-flush">
            {props.item.personalizacao.map((p) => (
              <li key={p._id} className="list-group-item">
                {p.nome}
              </li>
            ))}
          </ul>
        )}
        <div className="mt-auto text-right">
          <div>
            {ChecarItemPersonalizavel(props.item) ? "A partir de " : ""}
            <strong>
              {props.item.preco ? (
                props.item.preco.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              ) : (
                  <span className="text-info">A combinar</span>
                )}
            </strong>
          </div>
          {props.children}
        </div>
      </Card.Body>
    </Card>
  );
}

ItemProduto.propTypes = {
  item: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default ItemProduto;
