import React from "react";
import { Helmet } from "react-helmet";
import Container from "./container";

export default function Termo() {

  return (
    <Container>
      <Helmet>
        <title>Termos de Uso - caseira.app</title>
      </Helmet>
      <h3>TERMOS E CONDIÇÕES GERAIS DE USO</h3>
      <p className="text-justify">
        Estes Termos de uso e condições gerais aplicam-se ao uso dos serviços
        oferecidos por caseira.app por meio do site https://www.caseira.app.
      </p>
      <p className="text-justify">
        Qualquer pessoa, doravante nominada Usuário, que pretenda utilizar os
        Serviços do caseira.app, deverá aceitar os Termos e condições gerais e
        todas as demais políticas e princípios que o regem.
      </p>
      <p className="text-justify">
        A aceitação destes Termos e condições gerais é absolutamente
        indispensável à utilização do aplicativo e dos Serviços prestados pelo
        caseira.app.
      </p>
      <p className="text-justify">
        O Usuário deverá ler, certificar-se de haver entendido e aceitar todas
        as condições estabelecidas nos Termos de uso e condições gerais e nas
        Políticas de privacidade, assim como nos demais documentos a eles
        incorporados por referência, antes de seu cadastro como Usuário do
        caseira.app.
      </p>
      <p className="text-justify">
        Em caso de dúvidas, comentários ou sugestões sobre esses termos ou sobre
        os Serviços, entre em contato conosco pelo e-mail contato@caseira.app.
      </p>
      <h4>SEÇÃO 01 – DOS SERVIÇOS:</h4>
      <p className="text-justify">
        Os Serviços objeto dos presentes Termos e Condições Gerais de Uso
        consistem em
      </p>
      <ol type="i">
        <li>
          Ofertar uma maneira para o Usuário planejar suas refeições da semana
        </li>
        <li>
          Gerar uma lista de compras relacionada ao planejamento feito pelo
          Usuário
        </li>
        <li>
          Permitir realizar a compra dos ingredientes ou refeições planejadas
          através de nossos parceiros
        </li>
        <li>
          Disponibilizar ferramenta para cadastro e atualização de receitas
        </li>
      </ol>
      <h4>SEÇÃO 02 – DO CADASTRO DOS USUÁRIOS:</h4>
      <p className="text-justify">
        O Usuário deverá se inscrever em uma conta e selecionar uma senha e
        e-mail, devendo preencher todos os campos obrigatórios do cadastro, com
        informações exatas, precisas e verdadeiras.
      </p>
      <p className="text-justify">
        Caso o Usuário de nossos Serviços seja pessoa jurídica, esta garante
        estar regularmente representada e inscrita na Receita Federal do Brasil.
        Se pessoa natural (física), o Usuário confirma e garante ser plenamente
        capaz para assinar um contrato vinculativo (É proibido o cadastro de
        Usuários que não tenham plena capacidade civil, salvo as hipóteses
        legais de representação ou assistência).
      </p>
      <p className="text-justify">
        O caseira.app não se responsabiliza pela correção dos Dados Pessoais
        inseridos por seus Usuários. Os Usuários garantem e respondem, em
        qualquer caso, civil e criminalmente pela veracidade, exatidão e
        autenticidade, dos Dados Pessoais cadastrados.
      </p>
      <p className="text-justify">
        O Usuário deverá proteger a segurança e não compartilhará sua conta ou
        senha com ninguém, sendo pessoalmente e integralmente responsável por
        qualquer atividade associada à conta.
      </p>
      <p className="text-justify">
        O caseira.app se reserva no direito de recusar qualquer solicitação de
        cadastro e de suspender um cadastro previamente aceito, que esteja em
        desacordo com as políticas e regras dos presentes Termos e condições
        gerais.
      </p>
      <p className="text-justify">
        O Usuário reconhece que é o único responsável pela veracidade e pelo
        conteúdo por ele disponibilizado, isentando o caseira.app de qualquer
        responsabilidade nesse sentido, bem como declara, nos termos da lei,
        estarem quaisquer produtos anunciados em plena regularidade.
      </p>
      <p className="text-justify">
        O Usuário compromete-se a notificar o caseira.app imediatamente, e por
        meio seguro, a respeito de qualquer uso não autorizado de sua conta, bem
        como seu acesso não autorizado por terceiros. O Usuário será o único
        responsável pelas operações efetuadas em sua conta, uma vez que o acesso
        só será possível mediante a inclusão da senha, que deverá ser de
        conhecimento e propriedade exclusiva do Usuário.
      </p>
      <h4>SEÇÃO 03 – ALTERAÇÕES NOS TERMOS E CONDIÇÕES GERAIS DE USO</h4>
      <p className="text-justify">
        O caseira.app poderá alterar, a qualquer tempo, estes Termos e condições
        gerais de uso, visando seu aprimoramento e melhoria dos Serviços
        prestados, independentemente de notificação prévia. O Usuário deverá
        comunicar-se por e-mail no prazo de 05 (cinco) dias úteis caso não
        concorde com os termos alterados, contados a partir das alterações.
        Nesse caso, o vínculo contratual deixará de existir, desde que não haja
        contas ou dívidas em aberto. Não havendo manifestação no prazo
        estipulado, entender-se-á que o Usuário aceitou os novos Termos e
        condições gerais de uso e o contrato continuará vinculando as partes.
      </p>
      <h4>SEÇÃO 04 – DAS RECEITAS CADASTRADAS PELOS USUÁRIOS</h4>
      <p className="text-justify">
        O Usuário poderá cadastrar ou atualizar uma receita. Os dados dessa
        receita não devem violar nenhum dispositivo previsto em lei, neste
        contrato, nas demais políticas do caseira.app, devendo se
        responsabilizar por eventual infração dos direitos de terceiros.
      </p>
      <h4>SEÇÃO 06 – PRIVACIDADE DAS INFORMAÇÕES</h4>
      <p className="text-justify">
        Toda informação ou Dado Pessoal do Usuário é armazenado em servidores ou
        meios magnéticos de alta segurança. Salvo com relação às informações que
        são publicadas no aplicativo/Site, o caseira.app adotará todas as
        medidas possíveis para manter a confidencialidade e a segurança das
        informações sigilosas
        <b>
          , porém não se responsabilizará por eventuais prejuízos que sejam
          decorrentes da divulgação de tais informações por parte de terceiros
          que utilizem as redes públicas ou a internet, subvertendo os sistemas
          de segurança para acessar as informações de Usuários.
        </b>
      </p>
      <h4>SEÇÃO 07 – DAS OBRIGAÇÕES DOS USUÁRIOS</h4>
      <p className="text-justify">
        O usuário deverá utilizar somente informações verídicas que não devem
        violar nenhum dispositivo previsto em lei, neste contrato, nas demais
        políticas do caseira.app, devendo se responsabilizar por eventual
        infração dos direitos de terceiros.
      </p>
      <h4>SEÇÃO 08 – PRÁTICAS VEDADAS</h4>
      <p className="text-justify">
        Os Usuários não poderão, entre outras atitudes previstas nestes Termos e
        condições gerais e seus anexos:
      </p>
      <ol type="a">
        <li>Praticar atos ilícitos;</li>
        <li>
          Infringir ou violar nossos direitos de propriedade intelectual e
          industrial ou os direitos de propriedade intelectual e industrial de
          terceiros;
        </li>
        <li>Apresentar informações falsas ou enganosas;</li>
        <li>Agredir, caluniar, injuriar ou difamar outros Usuários.</li>
      </ol>
      <p className="text-justify">
        Estes tipos de comportamento poderão ser sancionados com o cancelamento
        ou bloqueio da conta do Usuário, sem prejuízo das ações legais que
        possam ocorrer pela configuração de delitos ou contravenções ou os
        prejuízos civis que possam causar aos outros Usuários, ao caseira.app ou
        terceiros.
      </p>
      <h4>SEÇÃO 09 – VIOLAÇÃO NO SISTEMA OU DA BASE DE DADOS</h4>
      <p className="text-justify">
        Qualquer intromissão, tentativa ou atividade que viole ou contrarie as
        leis de direito de propriedade intelectual e/ou as proibições
        estipuladas nestes Termos e condições gerais de uso, tornarão o
        responsável passível das ações legais pertinentes, bem como das sanções
        aqui previstas, sendo ainda responsável pelas indenizações por eventuais
        danos causados.
      </p>
      <h4>SEÇÃO 10 – PENALIDADES</h4>
      <p className="text-justify">
        Sem prejuízo de outras medidas cabíveis, o caseira.app poderá advertir,
        suspender, temporária ou definitivamente, a conta de um Usuário,
        cancelar os seus anúncios ou aplicar uma sanção que impacte
        negativamente em sua reputação, a qualquer tempo, iniciando as ações
        legais cabíveis e/ou suspendendo a prestação de seus Serviços.
      </p>
      <h4>SEÇÃO 11 - LIMITAÇÃO DE RESPONSABILIDADE</h4>
      <p className="text-justify">
        O caseira.app não se responsabiliza, por conseguinte, pela existência,
        quantidade ou qualidade das receitas cadastradas pelos Usuários pela
        veracidade dos dados pessoais por eles inseridos em seus cadastros.
      </p>
      <p className="text-justify">
        O caseira.app não será responsável pelo efetivo cumprimento das
        obrigações assumidas pelos Usuários.
      </p>
      <h4>SEÇÃO 12 – LEGISLAÇÃO APLICÁVEL E FORO DE ELEIÇÃO</h4>
      <p className="text-justify">
        Todos os itens destes Termos e condições gerais de uso são regidos pelas
        leis vigentes na República Federativa do Brasil. Para todos os assuntos
        referentes à interpretação, ao cumprimento ou qualquer outro
        questionamento relacionado a estes Termos e condições gerais de uso, as
        partes concordam em se submeter ao Foro da Comarca de Jundiaí - São
        Paulo.
      </p>
    </Container>
  );
}
