import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import User from "../../models/user";
import comidaApi from "../../services/comida.api";
import carregarFacebookLogin from "./carregarFacebookLogin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";

const user = new User();

function BotaoFacebookLogin(props) {
  const [scriptCarregado, setScriptCarregado] = useState(false);

  useEffect(() => {
    carregarFacebookLogin(() => {
      setScriptCarregado(true);
    });
  }, []);
  const aoLogar = (res) => {
    comidaApi
      .post("login/facebook/", { access_token: res.authResponse.accessToken })
      .then((res) => {
        user.Information = res.data;
        if (props.aoLogar) props.aoLogar(res);
      })
      .catch((erro) => {
        toast.warn(
          erro.response ? erro.response.data : "Erro ao realizar o login."
        );
      });
  };
  return (
    <div>
      
      <button
        className={"btn text-white"}
        disabled={!scriptCarregado}
        style={{ background: "#1877F2" }}
        onClick={() => {
          const facebook = typeof window != "undefined" ? window.FB : null;
          if (facebook)
            facebook.login(aoLogar, {
              scope: "public_profile,email",
            });
        }}
      >
        <FontAwesomeIcon icon={faFacebook} /> Continuar com{" "}
        <strong>Facebook</strong>
      </button>
    </div>
  );
}

BotaoFacebookLogin.propTypes = {
  scriptCarregado: PropTypes.bool,
  aoLogar: PropTypes.func,
};

export default BotaoFacebookLogin;
