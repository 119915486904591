import React from "react"
import getIngredientes from "../../models/ingrediente"
import comidaApi from "../../services/comida.api"
import { capitalizeWords } from "../stringFunctions"
import AsyncCreatable from "react-select/async-creatable"
import { Toast } from "react-bootstrap"
import PropTypes from 'prop-types'

export default class SelectIngredientes extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ingredientesList: [{ value: "", label: "" }],
            alerta: {
                visivel: false,
                titulo: "",
                mensagem: ""
            }
        }

        this.IngredienteCriado = this.IngredienteCriado.bind(this)
        this.aoCarregarReceitas = this.aoCarregarReceitas.bind(this)
    }

    componentDidMount() {
        this.atualizar()
    }

    aoCarregarReceitas(valor) {
        return new Promise((resolve) => {
            resolve(comidaApi.get(`receitas?q=${valor}`)
                .then(res => {
                    return res.data.docs.map((r) => ({ value: r._id, label: r.nome }));
                })
                .catch(() => {
                    this.setState({
                        alerta: {
                            visivel: true,
                            titulo: "Erro - Receitas",
                            mensagem: "Houve um erro ao consultar receitas. Tente novamente mais tarde."
                        }
                    })
                })
            )
        })
    }

    atualizar() {
        getIngredientes.getIngredientes(r => this.setState({
            ingredientesList: r.docs.map(i => { return { value: i._id, label: i.nome } })
        }))
    }

    IngredienteCriado(e) {
        const obj = {
            nome: capitalizeWords(e)
        }

        comidaApi.post("receitas", obj)
            .then(res => {
                this.props.onIngredienteSelectionado({
                    value: res.data._id,
                    label: obj.nome
                })

                this.atualizar();
            })
            .catch((erro) => {
                this.setState({
                    alerta: {
                        visivel: true,
                        titulo: "Erro - Receitas",
                        mensagem: erro.response ? erro.response.data : "Houve um erro ao criar ingrediente. Tente novamente mais tarde."
                    }
                })
            })
    }

    render() {
        return (
            <div>
                <Toast show={this.state.alerta.visivel} onClose={() => {
                    this.setState({
                        alerta: { visivel: false }
                    })
                }}>
                    <Toast.Header>
                        <strong className="me-auto">{this.state.alerta.titulo}</strong>
                    </Toast.Header>
                    <Toast.Body>{this.state.alerta.mensagem}</Toast.Body>
                </Toast>
                <AsyncCreatable
                    value={this.props.value}
                    loadingMessage={() => ("Procurando ingredientes...")}
                    formatCreateLabel={i => `Criar ingrediente ${i}...`}
                    defaultOptions={this.state.ingredientesList}
                    placeholder={this.props.placeholder}
                    onCreateOption={this.IngredienteCriado}
                    loadOptions={this.aoCarregarReceitas}
                    onChange={(i) => {
                        this.setState({
                            ingrediente: i
                        })
                        this.props.onIngredienteSelectionado(i)
                    }}
                />
            </div>
        )
    }
}

SelectIngredientes.propTypes = {
    onIngredienteSelectionado: PropTypes.func,
    value: PropTypes.object,
    placeholder: PropTypes.string
}
