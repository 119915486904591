import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import { createStore } from "redux";
import caseiraapp from "./reducers";
import "./theme.scss"
import "bootstrap/dist/js/bootstrap";
import "react-image-crop/dist/ReactCrop.css";
import "./styles/foto.css";
import "react-toastify/dist/ReactToastify.css";
import "react-image-crop/dist/ReactCrop.css";
import "./styles/foto.css";
import "./styles/index.css";
import "react-tiny-fab/dist/styles.css";
import IrAoTopo from "./iraotopo";

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

// Create Redux store with initial state
const store = createStore(caseiraapp, preloadedState);

ReactDOM.hydrate(
  <BrowserRouter>
    <Provider store={store}>
      <IrAoTopo />
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
