import React from "react";
import comidaApi from "../../services/comida.api";
import User from "../../models/user";
import { Toast, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Container from "../container";
import ListaTipoAlimentacao from "../listaTipoAlimentacao";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const user = new User();

export default class UserDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      alerta: {
        visivel: false,
        titulo: "",
      },
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      comidaApi
        .get(`users/${this.props.match.params.id}`)
        .then((user) => {
          this.setState({
            user: user.data,
          });
        })
        .catch(() => {
          this.setState({
            alerta: {
              visivel: true,
              titulo: "Erro - Usuário",
              mensagem: `Houve um erro ao consultar o servidor. Por favor, tente novamente mais tarde.`,
            },
          });
        });
    }
  }

  render() {
    return (
      <Container>
        <Helmet>
          <title>{this.state.user && this.state.user.nome || "Página de Usuário"} - caseira.app</title>
        </Helmet>
        <Toast
          show={this.state.alerta.visivel}
          onClose={() => this.setState({ alerta: { visivel: false } })}
        >
          <Toast.Header>
            <strong className="me-auto">{this.state.alerta.titulo}</strong>
          </Toast.Header>
          <Toast.Body>{this.state.alerta.mensagem}</Toast.Body>
        </Toast>
        <h5>
          {this.state.user.nome}
          &nbsp;
          {user.Information &&
          user.Information.user._id === this.state.user._id ? (
            <Link
              to={`/usuarios/editar/`}
              className="btn btn-light text-secondary"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Link>
          ) : null}
        </h5>
        <p>E-mail: {this.state.user.email}</p>
        <p>Estado: {this.state.user.estado?.Nome}</p>
        <p>Cidade: {this.state.user.cidade?.Nome}</p>
        <ListaTipoAlimentacao values={this.state.user.tipoAlimentacao} />
        <div className="row">
          <Card
            className="text-center m-2"
            style={{ width: "200px" }}
            bg="info"
            text="white"
          >
            <Card.Body className="display-4">
              {this.state.user.planejamentos}
            </Card.Body>
            <Card.Footer className="text-light">Planejamentos</Card.Footer>
          </Card>
          <Card
            className="text-center m-2"
            style={{ width: "200px" }}
            bg="info"
            text="white"
          >
            <Card.Body className="display-4">
              <Link
                to={`/receitas/usuarios/${this.props.match.params.id}`}
                className="text-white"
              >
                {this.state.user.receitas}
              </Link>
            </Card.Body>
            <Card.Footer className="text-light">Receitas</Card.Footer>
          </Card>
        </div>
      </Container>
    );
  }
}

UserDetail.propTypes = {
  match: PropTypes.object,
};
