import React, { useEffect, useState } from "react";
import Container from "../container";
import comidaApi from "../../services/comida.api";
import { toast } from "react-toastify";
import { Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
const espacoReservadoFornecedor = "/espacoReservadoFornecedor.jpg";
import User from "../../models/user";
import { faExclamation, faStore } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

const userLogado = new User();

function IndexFornecedores() {
  const [fornecedores, setFornecedores] = useState(new Array(12));
  const { estado, cidade } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    let url = "fornecedores";

    if (estado && cidade) url = `fornecedores/${estado}/${cidade}`;
    if (
      typeof window != "undefined"
        ? window.location.pathname.includes("parcerias")
        : null
    ) {
      url = `fornecedores/administrador`;
    }

    comidaApi
      .get(url)
      .then((resultado) => {
        setFornecedores(resultado.data);
      })
      .catch((erro) => {
        toast.warn(
          erro.response?.data ||
            "Houve um erro ao consultar parceiros. Por favor, tente novamente mais tarde."
        );
      });

    if (userLogado.IsLoggedin)
      comidaApi
        .get(`users/${userLogado.Information.user._id}`)
        .then((resultado) => {
          setUser(resultado.data);
        })
        .catch(() => {});
  }, [estado, cidade]);

  const mostrarFornecedores = fornecedores.map((fornecedor, index) => {
    if (fornecedor.nome)
      return (
        <div
          className="col-sm-6 col-md-4 col-lg-3 p-2"
          key={fornecedor._id || index}
        >
          <Link to={`/fornecedores/${fornecedor.slug}`}>
            <Card>
              <Card.Img
                src={fornecedor.foto || espacoReservadoFornecedor}
                style={{
                  height: "auto",
                  width: "auto",
                  maxHeight: "12rem",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
              <Card.Body>
                <Card.Title>{fornecedor.nome}</Card.Title>
                <p className="text-info">{fornecedor.categoria}</p>
              </Card.Body>
            </Card>
          </Link>
        </div>
      );
    else
      return (
        <div>
          <h3>
            <Skeleton></Skeleton>
          </h3>
        </div>
      );
  });
  const mostarAviso =
    user && (!user.cidade || !user.estado) ? (
      <div className="alert alert-warning">
        <FontAwesomeIcon icon={faExclamation} />{" "}
        <Link to="/usuarios/editar" className="alert-warning">
          <strong>Clique aqui</strong> e complete seu cadastro para descobrir se
          temos parceiros na sua cidade.
        </Link>
      </div>
    ) : (
      <div className="alert alert-info">
        <p>:( ainda não temos fornecedores na sua região.</p>
        <p>
          Quer nos indicar um?{" "}
          <a href="mailto:contato@caseira.app?subject=Sugestão de Parceria - caseira.app">
            Entre em contato clicando aqui.
          </a>
        </p>
      </div>
    );

  return (
    <Container>
      <Helmet>
        <title>Comprar - caseira.app</title>
      </Helmet>
      
      <h5>
            <FontAwesomeIcon icon={faStore} /> Fornecedores Parceiros
            </h5>
      {fornecedores.length > 0 ? (
        <div className="row">{mostrarFornecedores}</div>
      ) : (
        mostarAviso
      )}
    </Container>
  );
}

IndexFornecedores.propTypes = {};

export default IndexFornecedores;
