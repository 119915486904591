import React from "react";
import { Link } from "react-router-dom";
const mainCardImage = "/maincard.jpg";
const supermarketImage = "/supermarket.jpg";
const cookingImage = "/cooking.jpg";
const vegetablesImage = "/vegetables.jpg";
import { Card, CardGroup } from "react-bootstrap";
import User from "../models/user";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGooglePlay } from "@fortawesome/free-brands-svg-icons/faGooglePlay";
import {
  faCalendarAlt,
  faFileAlt,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import { faApple } from "@fortawesome/free-brands-svg-icons";

const maincard = {
  height: "auto",
  width: "auto",
  maxHeight: "27rem",
  maxWidth: "100%",
  objectFit: "contain",
};

const othercards = {
  height: "22rem",
  width: "auto",
  objectFit: "cover",
};

const user = new User();

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    if (user.IsLoggedin) props.history.push("/planejamento/menu");
  }

  render() {
    return (
      <div className="position-relative p-3 m-1 m-md-3">
        <Helmet>
          <title>Planeje sua alimentação. É grátis! - caseira.app</title>
        </Helmet>
        <Card className="mb-4 text-center">
          <Card.Img variant="top" src={mainCardImage} style={maincard} />
          <Card.Body>
            <Card.Title>Planeje sua alimentação</Card.Title>
            <Card.Text>
              O segredo para uma alimentação mais saudável é planejamento.
            </Card.Text>
            <ol
              className="font-weight-bold text-primary p-0"
              style={{
                listStyleType: "none",
                fontSize: "large",
                lineHeight: "2em",
              }}
            >
              <li className="d-lg-inline">
                <FontAwesomeIcon icon={faFileAlt} /> Cadastre-se{" "}
                <span className="text-success d-none d-lg-inline">{"> "}</span>
              </li>
              <li className="d-lg-inline">
                <FontAwesomeIcon icon={faCalendarAlt} /> Planeje suas refeições{" "}
                <span className="text-success d-none d-lg-inline">{"> "}</span>
              </li>
              <li className="d-lg-inline">
                <FontAwesomeIcon icon={faList} /> Veja a lista de compras
              </li>
            </ol>
            <Link
              className="btn btn-success btn-lg m-2"
              to="/usuarios/cadastro"
            >
              Comece agora!
            </Link>
            <a
              className="btn btn-outline-success btn-lg m-2"
              href="https://play.google.com/store/apps/details?id=app.caseira.www.twa"
            >
              <FontAwesomeIcon icon={faGooglePlay} /> Google Play
            </a>
            <a
              className="btn btn-outline-success btn-lg m-2"
              href="https://apps.apple.com/us/app/caseira-app/id1563139635"
            >
              <FontAwesomeIcon icon={faApple} /> App Store
            </a>
          </Card.Body>
        </Card>
        <CardGroup>
          <Card className="text-center">
            <Card.Img variant="top" src={supermarketImage} style={othercards} />
            <Card.Body>
              <Card.Title>Jamais se esqueça</Card.Title>
              <Card.Text>
                A gente faz sua lista de compras para não faltar nada na hora de
                preparar aquela receita.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="text-center">
            <Card.Img variant="top" src={cookingImage} style={othercards} />
            <Card.Body>
              <Card.Title>Comida de Verdade</Card.Title>
              <Card.Text>
                Planeje sua alimentação descobrindo novas receitas e ensine pra
                gente as que só você conhece.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="text-center">
            <Card.Img variant="top" src={vegetablesImage} style={othercards} />
            <Card.Body>
              <Card.Title>Compre ou venda</Card.Title>
              <Card.Text>
                Para dar ainda mais praticidade, compre os itens da sua lista
                com nossos parceiros.
              </Card.Text>
              <a
                className="btn btn-outline-success btn-lg"
                href="mailto:contato@caseira.app?subject=Quero Vender - caseira.app"
              >
                Quero Vender
              </a>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
    );
  }
}

Home.propTypes = {
  history: PropTypes.object,
};