import React from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import comidaApi from "../../services/comida.api";
import Loading from "../loading";
import { capitalizeWords } from "../stringFunctions";
import PropTypes from "prop-types";
import { Button, Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

let TIME_OUT = 0;

export default class AddReceita extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adicionando: true,
      processando: false,
      receitas: [],
      receitasSelecionadas: [],
      alerta: {
        visivel: false,
        titulo: "",
        mensagem: "",
      },
    };

    this.aoSelecionarReceita = this.aoSelecionarReceita.bind(this);
    this.onReceitaCriada = this.onReceitaCriada.bind(this);
    this.itemDeleted = this.itemDeleted.bind(this);
    this.aoCriarReceita = this.aoCriarReceita.bind(this);
    this.quantidadeAlterada = this.quantidadeAlterada.bind(this);
    this.aoCarregarReceitas = this.aoCarregarReceitas.bind(this);
  }

  componentDidMount() {
    comidaApi
      .get(`receitas`)
      .then((res) => {
        this.setState({
          receitas: res.data.docs,
        });
      })
      .catch((erro) => {
        this.setState({
          alerta: {
            visivel: true,
            titulo: "Erro - Receitas",
            mensagem: erro.response
              ? erro.response.data
              : "Erro ao consultar receitas",
          },
        });
      });

    if (this.props.receitasIniciais) {
      const receitasSelecionadas = this.state.receitasSelecionadas;
      receitasSelecionadas.push(...this.props.receitasIniciais);
      this.setState({
        receitasSelecionadas,
      });
    }
  }

  aoCriarReceita(e) {
    this.setState(
      {
        processando: true,
      },
      () => {
        const obj = {
          nome: capitalizeWords(e),
        };

        comidaApi
          .post("receitas", obj)
          .then((res) => {
            this.setState({
              processando: false,
            });
            this.aoSelecionarReceita({
              label: res.data.nome,
              value: res.data._id,
            });
          })
          .catch((erro) => {
            this.setState({
              alerta: {
                visivel: true,
                titulo: "Erro - Receitas",
                mensagem: erro.response
                  ? erro.response.data
                  : "Erro ao cadastrar receita",
              },
              processando: false,
            });
          });
      }
    );
  }

  quantidadeAlterada(indice, valor) {
    const quantidade = parseInt(valor);

    const receitasAtualizadas = this.state.receitasSelecionadas.map(
      (receita, posicao) => {
        if (posicao === indice) {
          const receitaAtualizada = {
            ...receita,
            quantidade: quantidade ? quantidade : 0,
          };
          return receitaAtualizada;
        }
        return receita;
      }
    );
    this.setState(
      {
        receitasSelecionadas: receitasAtualizadas,
      },
      () => {
        if (this.props.onChange)
          this.props.onChange(this.state.receitasSelecionadas);
      }
    );
  }

  aoCarregarReceitas(valor) {
    if (TIME_OUT) clearTimeout(TIME_OUT);

    return new Promise((resolve) => {
      TIME_OUT = setTimeout(() => {
        resolve(
          comidaApi
            .get(`receitas?q=${valor}`)
            .then((res) => {
              return res.data.docs.map((r) => ({
                value: r._id,
                label: r.nome,
              }));
            })
            .catch(() => {
              return [
                {
                  label: "Erro ao consultar receita.",
                  value: `Houve um erro ao consultar receitas com nome ${valor}. Por favor, tente novamente mais tarde.`,
                },
              ];
            })
        );
      }, 1000);
    });
  }

  aoSelecionarReceita(itemSelecionado) {
    let localizada = false;

    const receitasAtualizadas = this.state.receitasSelecionadas.map(
      (receita) => {
        if (receita.value === itemSelecionado.value) {
          const receitaAtualizada = {
            ...receita,
            quantidade: receita.quantidade + 1,
          };
          localizada = true;
          return receitaAtualizada;
        }
        return receita;
      }
    );

    if (localizada) {
      this.setState({
        receitasSelecionadas: receitasAtualizadas,
      });
    } else {
      let novaReceita = itemSelecionado;
      itemSelecionado.quantidade = 1;

      this.setState((state) => ({
        receitasSelecionadas: state.receitasSelecionadas.concat(novaReceita),
      }));
    }

    this.setState(
      {
        receitaSelecionada: null,
      },
      () => {
        if (this.props.onChange)
          this.props.onChange(this.state.receitasSelecionadas);
      }
    );
  }

  getAddReceita() {
    if (this.state.adicionando)
      return (
        <AsyncCreatableSelect
          value={this.state.receitaSelecionada}
          formatCreateLabel={(i) => `Criar receita "${i}"?`}
          noOptionsMessage={() => "Sem opções"}
          loadingMessage={() => "Procurando receitas..."}
          defaultOptions={this.state.receitas.map((r) => ({
            value: r._id,
            label: r.nome,
          }))}
          placeholder="Ex.: Filé a Parmegiana"
          onCreateOption={this.aoCriarReceita}
          loadOptions={this.aoCarregarReceitas}
          onChange={this.aoSelecionarReceita}
        />
      );
  }

  getAddReceitaButton() {
    if (!this.state.adicionando)
      return (
        <div className="text-center">
          <input
            type="button"
            value="+"
            className="btn btn-outline-primary btn-sm"
            onClick={() => {
              this.setState({
                adicionando: true,
              });
            }}
          />
        </div>
      );
  }

  onReceitaCriada(e) {
    this.setState(
      {
        adicionando: false,
        processando: true,
      },
      () => {
        const obj = {
          nome: capitalizeWords(e),
        };

        comidaApi.post("receitas", obj).then((res) => {
          if (res.data.success) {
            this.onReceitaSelecionada({
              label: obj.nome,
              value: res.data.id,
            });
          }
        });
      }
    );
  }

  itemDeleted(index) {
    this.state.receitasSelecionadas.splice(index, 1);

    this.setState(
      (state) => ({
        receitasSelecionadas: state.receitasSelecionadas,
      }),
      () => {
        if (this.props.onChange)
          this.props.onChange(this.state.receitasSelecionadas);
      }
    );
  }

  getListReceitas() {
    return this.state.receitasSelecionadas.map((receita, indice) => {
      return (
        <li
          className="list-group-item d-flex justify-content-between"
          key={receita.label + indice}
        >
          <input
            type="text"
            onChange={(e) => this.quantidadeAlterada(indice, e.target.value)}
            value={receita.quantidade}
            onFocus={(event) => {
              event.target.select();
            }}
            className="w-25 border border-secondary rounded text-center"
          />
          <span>{receita.label}</span>
          <Button
            variant="light"
            className="btn-sm"
            onClick={() => this.itemDeleted(indice)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </li>
      );
    });
  }

  render() {
    return (
      <div>
        <Toast
          show={this.state.alerta.visivel}
          onClose={() => this.setState({ alerta: { visivel: false } })}
        >
          <Toast.Header>
            <strong className="me-auto">{this.state.alerta.titulo}</strong>
          </Toast.Header>
          <Toast.Body>{this.state.alerta.mensagem}</Toast.Body>
        </Toast>
        <ul className="list-group list-group-flush">
          {this.getListReceitas()}
          <li className="list-group-item">
            {this.state.processando ? <Loading /> : null}
          </li>
        </ul>
        {this.getAddReceita()}
        {this.getAddReceitaButton()}
      </div>
    );
  }
}

AddReceita.propTypes = {
  receitasIniciais: PropTypes.array,
  onChange: PropTypes.func,
};
