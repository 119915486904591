import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Container from './container'
import SelecaoEstado from './selecaoEstado'
import SelecaoCidade from './selecaoCidade'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faSearch } from '@fortawesome/free-solid-svg-icons'

function Localizacao(props) {
    const [estado, setEstado] = useState(null)
    const [cidade, setCidade] = useState(null)
    
    return (
        <Container>
            <h5><FontAwesomeIcon icon={faMapMarkedAlt} /> Onde você está?</h5>
            <small>Para podermos te mostrar quais parceiros estão na sua região.</small>
            <form onSubmit={(evento) => {
                evento.preventDefault()

                props.history.push(`/fornecedores/${estado.label}/${cidade.label}`)
            }}>
                <SelecaoEstado onChange={(valor) => setEstado(valor)} /> 
                <SelecaoCidade estado={estado} onChange={(valor) => setCidade(valor)}/>
                <Button type="submit" disabled={!estado || !cidade}><FontAwesomeIcon icon={faSearch} className="me-3" />Pesquisar</Button>
            </form>
        </Container>
    )
}

Localizacao.propTypes = {
    history: PropTypes.object
}

export default Localizacao

