import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import TituloIngrediente from "../ingredientes/titulo.ingrediente";

export default class IngredienteReceita extends React.Component {
  render() {
    const item = this.props.ingrediente;

    return (
      <div className={this.props.className || "flex-row"}>
        <TituloIngrediente item={item} />
        {this.mostrarQuando(item)}
        {this.mostrarOnde(item)}
      </div>
    );
  }

  mostrarOnde(item) {
    if (item.receitas)
      return (
        <div>
          <small>Receitas: {item.receitas.join(", ")}</small>
        </div>
      );
    else return null;
  }

  mostrarQuando(item) {
    if (item.dia)
      return (
        <div>
          <small>
            Para:{" "}
            {dayjs(item.dia.substring(0, 10)).locale("pt-br").format("DD/MMM")}
          </small>
        </div>
      );
    else return null;
  }
}

IngredienteReceita.propTypes = {
  ingrediente: PropTypes.object,
  className: PropTypes.string,
};