import React, { useEffect, useState } from "react";
import comidaApi from "../../services/comida.api";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCashRegister,
  faEnvelope,
  faEnvelopeOpenText,
  faHome,
  faIdCard,
  faPhoneAlt,
  faShoppingBasket,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Container from "../container";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types"
import queryString from "query-string";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function DetalhesPedidos(props) {
  const [pedido, setPedido] = useState({});
  const [itemAtual, setItemAtual] = useState({});
  const [situacoes, setSituacoes] = useState([]);
  const [alterando, setAlterando] = useState(false);
  const [administrador, setAdministrador] = useState(false);
  const { numero, cliente } = useParams();
  const [mensagensWhatsApp, setMensagensWhatsApp] = useState([])

  useEffect(() => {
    comidaApi
      .get(`pedidos/situacoes`)
      .then((resultado) => {
        setSituacoes(
          resultado.data.map((s) => ({
            label: s,
            value: s,
          }))
        );
      })
      .catch((erro) => {
        toast.warn(
          erro.response?.data ||
          "Não foi possível consultar as situações. Por favor, tente novamente mais tarde."
        );
      });
  }, []);

  useEffect(() => {
    comidaApi
      .get(`pedidos/${numero}/${cliente || ""}`)
      .then((resultado) => {
        const { novo } = queryString.parse(props.location.search)
        if (novo === 's')
          gerarMensagensWhatsApp(resultado.data)

        setPedido(resultado.data);
        setAdministrador(
          resultado.data.administrador
        );
      })
      .catch((erro) => {
        toast.warn(
          erro.response?.data ||
          "Não foi possível consultar seu pedido. Por favor, tente novamente mais tarde.",
        );
      });
  }, [numero, cliente, alterando, itemAtual]);

  const gerarMensagensWhatsApp = (pedido) => {
    const itens = pedido.itens.concat(pedido.produtos)
    const fornecedores = itens.reduce((total, item) => {
      const { _id } = item.fornecedor;

      const existe = total[_id]
      if (!existe)
        total[_id] = item.fornecedor;

      return total
    }, {})

    const mensagens = []

    for (let i = 0; i < Object.values(fornecedores).length; i++) {
      const fornecedor = Object.values(fornecedores)[i];

      const { telefone } = fornecedor
      const { host, protocol, pathname } = window.location

      const link = `${protocol}//${host}${pathname}`

      if (telefone) {
        const numero = telefone.replace(/[^0-9]+/g, "")
        mensagens.push({
          fornecedor,
          mensagem: `https://api.whatsapp.com/send?phone=%2B55${numero}&text=Oi%21%20Fiz%20uma%20nova%20encomenda%20contigo.%0A%0APara%20consultar%20acesse%3A%20%0A${link}%0A%0AFico%20no%20aguardo%20do%20seu%20retorno.`
        })
      }
    }

    setMensagensWhatsApp(mensagens)
  }

  const mapearItens = (elemento) => (
    <li className="list-group-item" key={elemento._id}>
      <h6>
        <Link to={`/fornecedores/${elemento.fornecedor.slug}`}>
          {elemento.fornecedor.nome}
        </Link>
      </h6>
      <div>
        {elemento.quantidade}x {elemento.nome}
        <br />
        {elemento.peso ? (
          <span>
            {elemento.peso} <small>gramas</small>
          </span>
        ) : null}
        <ul className="list-group list-group-flush">
          {elemento.personalizacao?.map((p) => {
            return (
              <li key={p._id} className="list-group-item">
                {p.nome}
              </li>
            );
          })}
        </ul>
      </div>
      <div>
        <strong>
          {elemento.preco.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
        </strong>
      </div>
      <div className="alert alert-info">
        Situação: <strong>{elemento.status}</strong>
      </div>
      <div>
        {administrador ? (
          itemAtual._id === elemento._id ? (
            alterarStatusItem(elemento)
          ) : (
              <Button
                variant="success"
                size="sm"
                className="ms-2"
                onClick={() => setItemAtual(elemento)}
              >
                Alterar Situação
              </Button>
            )
        ) : (
            <><a
              className="btn btn-outline-primary ms-2"
              href={`mailto:${elemento.fornecedor.email}?subject=AJUDA - Pedido ${pedido.numero} - caseira.app`}>
              <FontAwesomeIcon icon={faEnvelopeOpenText} /> Ajuda
            </a>
              {elemento.fornecedor.telefone && <a
                className="btn btn-outline-primary ms-2"
                href={`https://api.whatsapp.com/send?phone=%2B55${elemento.fornecedor.telefone.replace(/[^0-9]+/g, "")}`}
                target="_blank"
                rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWhatsapp} /> Ajuda
            </a>}
            </>
          )}
      </div>
    </li>
  );

  const ocultarMensagemWhatsapp = () => {
    setMensagensWhatsApp([]);
  };

  return (
    <Container>
      <Helmet>
        <title>Pedido {pedido.numero || ""} - caseira.app</title>
      </Helmet>
      
      <Modal show={mensagensWhatsApp.length > 0} keyboard={false} onHide={ocultarMensagemWhatsapp}>
        <Modal.Header closeButton>
          <Modal.Title>Para continuar, envie um WhatsApp para os fornecedores do seu pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mensagensWhatsApp.map((mensagem) => {
            return <a className="lead d-block m-2"
              style={{ color: "#25D366" }}
              key={mensagem.fornecedor._id}
              href={mensagem.mensagem}
              target="_blank"
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} /> {mensagem.fornecedor.nome}
            </a>
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={ocultarMensagemWhatsapp}>
            <FontAwesomeIcon icon={faTimes} /> Cancelar
        </Button>
        </Modal.Footer>
      </Modal>
      <h5>
        <FontAwesomeIcon icon={faShoppingBasket} /> Pedido - {pedido.numero}
      </h5>
      <div className="mb-3">
        {administrador &&
          (alterando ? (
            alterarStatus()
          ) : (
              <Button variant="success" onClick={() => setAlterando(true)}>
                Alterar Situação Pedido
              </Button>
            ))}
      </div>
      {administrador ? (
        <div className="my-3">
          <div className="alert alert-info">
            <h5><FontAwesomeIcon icon={faIdCard} /> Cliente - {pedido.cliente?.nome}</h5>
            <p>{pedido.documento}</p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              <a
                href={`mailto:${pedido.cliente?.email}?subject=Atualização - Pedido ${pedido.numero} - caseira.app`}
              >
                {pedido.cliente?.email}
              </a>
            </p>
            <p>
              <FontAwesomeIcon icon={faPhoneAlt} /> {pedido.telefone}
            </p>
            <p>
              <FontAwesomeIcon icon={faHome} /> {pedido.endereco?.cep}
              <br />
              {pedido.endereco?.logradouro},{" "}
              {pedido.endereco?.numero} {pedido.endereco?.complemento} -{" "}
              {pedido.endereco?.bairro}
              <br />
              {pedido.endereco?.cidade}/{pedido.endereco?.estado}
              <br />
              {pedido.endereco?.referencia &&
                `Ponto de Referência: ${pedido.endereco?.referencia}`}
            </p>
            <p>
              <FontAwesomeIcon icon={faCashRegister} /> {pedido.formaPagamento}
            </p>
          </div>
        </div>
      ) : null}
      <ul className="list-group list-group-flush">
        {pedido.itens?.map(mapearItens)}
        {pedido.produtos?.map(mapearItens)}
      </ul>
      <p className="p-3">
        <small>Total: </small>
        <strong>
          {pedido.total?.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
        </strong>
      </p>
    </Container>
  );

  function alterarStatusItem(elemento) {
    return (
      <Select
        placeholder="Selecione uma opção..."
        noOptionsMessage={() => "Sem opções"}
        name="situacao"
        value={{ label: elemento.status, value: elemento.status }}
        options={situacoes}
        onChange={(e) => {
          comidaApi
            .put(
              `pedidos/${pedido.numero}/${pedido.cliente._id}/${elemento._id}/${e.value}`
            )
            .then(() => {
              setItemAtual({});
            })
            .catch((erro) => {
              toast.warn(
                erro.response?.data ||
                "Não foi possível atualizar a situação deste item. Por favor, tente novamente mais tarde.",
              );
            });
        }}
      />
    );
  }

  function alterarStatus() {
    const alterarStatusPedido = (e) => {
      comidaApi
        .put(
          `pedidos/fornecedor/${pedido.numero}/${pedido.cliente._id}/${e.value}`
        )
        .then(() => {
          setItemAtual({});
          setAlterando(false);
        })
        .catch((erro) => {
          toast.warn(
            erro.response?.data ||
            "Não foi possível atualizar a situação deste pedido. Por favor, tente novamente mais tarde.",
          );
        });
    };

    return (
      <Select
        placeholder="Selecione uma opção..."
        noOptionsMessage={() => "Sem opções"}
        name="situacao"
        options={situacoes}
        onChange={alterarStatusPedido}
      />
    );
  }
}

DetalhesPedidos.propTypes = {
  location: PropTypes.object
}

export default DetalhesPedidos;
