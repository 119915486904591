import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

function OpcoesPeso(props) {
  const [validacao, setValidacao] = useState({});
  const [valor, setValor] = useState({});

  useEffect(() => {
    if (props.value) {
      setValor(props.value);
    }
  }, [props.value]);

  return (
    <div className={props.className}>
      <label className="w-100">
        Diferentes Tamanhos?
        <small className="d-block">
          O cliente pode escolher o tamanho dessa refeição?
        </small>
        <select
          className="d-block"
          value={props.value ? true : false}
          onChange={(e) => {
            const sim = e.target.selectedIndex === 0;
            if (props.onChange) props.onChange(sim ? {} : null);
          }}
        >
          <option value={true}>Sim</option>
          <option value={false}>Não</option>
        </select>
      </label>
      {props.value ? (
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">
                Peso <small>em gramas</small>
              </th>
              <th scope="col">Preço</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                P<span className="d-none d-sm-inline">equeno</span>
              </td>
              <td>
                <input
                  type="number"
                  name="pequeno"
                  step={1}
                  className="form-control"
                  value={valor.pequeno?.peso}
                  onChange={aoAlterarPeso}
                />
                <span className="text-danger">{validacao.pequeno?.peso}</span>
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  name="pequeno"
                  value={valor.pequeno?.preco}
                  step={0.01}
                  onChange={aoAlterarPreco}
                />
                <span className="text-danger">{validacao.pequeno?.preco}</span>
              </td>
            </tr>
            <tr>
              <td>
                M<span className="d-none d-sm-inline">édio</span>
              </td>
              <td>
                <input
                  type="number"
                  name="medio"
                  className="form-control"
                  step={1}
                  value={valor.medio?.peso}
                  onChange={aoAlterarPeso}
                />
                <span className="text-danger">{validacao.medio?.peso}</span>
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  name="medio"
                  step={0.01}
                  value={valor.medio?.preco}
                  onChange={aoAlterarPreco}
                />
                <span className="text-danger">{validacao.medio?.preco}</span>
              </td>
            </tr>
            <tr>
              <td>
                G<span className="d-none d-sm-inline">rande</span>
              </td>
              <td>
                <input
                  type="number"
                  name="grande"
                  step={1}
                  className="form-control"
                  value={valor.grande?.peso}
                  onChange={aoAlterarPeso}
                />
                <span className="text-danger">{validacao.grande?.peso}</span>
              </td>
              <td>
                <input
                  type="number"
                  step={0.01}
                  className="form-control"
                  name="grande"
                  value={valor.grande?.preco}
                  onChange={aoAlterarPreco}
                />
                <span className="text-danger">{validacao.grande?.preco}</span>
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}
    </div>
  );

  function aoAlterarPeso(e) {
    alterarValor(e, "peso", "preco");
  }

  function aoAlterarPreco(e) {
    alterarValor(e, "preco", "peso");
  }

  function alterarValor(e, alterado, validado) {
    const tamanho = e.target.name;
    let novoValor = { ...valor };
    let novaValidacao = { ...validacao };
    let isValid = true;

    if (!novoValor[tamanho]) novoValor[tamanho] = {};
    if (!novaValidacao[tamanho]) novaValidacao[tamanho] = {};

    novoValor[tamanho][alterado] = e.target.value;

    if (novoValor[tamanho].preco && !novoValor[tamanho].peso) {
      novaValidacao[tamanho][validado] = `Informe o peso`;
    } else if (!novoValor[tamanho].preco && novoValor[tamanho].peso) {
      novaValidacao[tamanho][validado] = `Informe o preço`;
    } else {
      novaValidacao[tamanho].preco = "";
      novaValidacao[tamanho].peso = "";
    }

    ["pequeno", "medio", "grande"].every((t) => {
      if (
        novaValidacao[t] &&
        (novaValidacao[t].preco !== "" || novaValidacao[t].peso !== "")
      ) {
        isValid = false;
        return isValid;
      } else return true;
    });

    setValidacao(novaValidacao);
    setValor(novoValor);

    if (props.onChange) props.onChange({ ...novoValor, isValid });
  }
}

OpcoesPeso.propTypes = {
  value: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default OpcoesPeso;
