import React, { useState } from "react";
import { Link } from "react-router-dom";
import extrairPrimeiroNome from "../primeiroNome";
import Navegacao from "./navegacao";
import Compartilhar from "../compartilhamento/compartilhar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faShoppingCart, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

function MenuPagina(props) {
  const nomePlanejamentoDe = extrairPrimeiroNome(props.planejamentoDe?.nome);
  const [compartilhando, setCompartilhando] = useState(false);

  return (
    <div>
      <div
        className="d-flex justify-content-between p-2 shadow"
        style={{ background: "#D35500" }}
      >
        <h5 className="text-white my-1">
          <Link to="/planejamento/menu" className="text-white">
          <FontAwesomeIcon icon={faCalendarAlt} className="me-3" />
            {props.user
              ? `Planejamento ${
                  nomePlanejamentoDe ? "de " + nomePlanejamentoDe : ""
                }`
              : "Meu Planejamento"}
          </Link>
        </h5>
        <div className="dropdown">
          <button
            type="button"
            className="btn dropdown-toggle text-white"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Opções
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <Navegacao className="dropdown-item" 
              defaultValue={props.dataBase}
              onChange={props.aoAlterarData}/>
            <Link
              className="dropdown-item"
              to={`/ingredientes/lista/${props.dataBase}/${props.user || ""}`}
            >
              <FontAwesomeIcon icon={faShoppingCart} className="me-3" />Lista de Compras
            </Link>
            <span
              className={props.user ? "d-none" : "dropdown-item"}
              onClick={() => {
                setCompartilhando(true);
              }}
            >
              <FontAwesomeIcon icon={faUserFriends} className="me-3" />
              Compartilhar
            </span>
            <Compartilhar
              visivel={compartilhando}
              aoCompartilhar={() => {
                setCompartilhando(false);
              }}
              ocultar={() => {
                setCompartilhando(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

MenuPagina.propTypes = {
  planejamentoDe: PropTypes.object,
  user: PropTypes.object,
  dataBase: PropTypes.string,
  aoAlterarData: PropTypes.func,
};

export default MenuPagina;
