import React from 'react'
import PropTypes from 'prop-types'

function Container(props) {
    return (
        <div className="position-relative p-3 m-1 m-md-3 rounded shadow bg-white">
            {props.children}
        </div>
    )
}

Container.propTypes = {
    children: PropTypes.any
}

export default Container

