import React from "react";
import comidaApi from "../../services/comida.api";
import update from "immutability-helper";
import User from "../../models/user";
import ItemPlanejamento from "./item.planejamento";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { DragDropContext } from "react-beautiful-dnd";
import Axios from "axios";
import {  toast } from "react-toastify";
import MenuPagina from "./menuPagina";
import { Helmet } from "react-helmet";

const usuarioLogado = new User();
const dias = [0, 1, 2, 3, 4, 5, 6];

export default class IndexPlanejamento extends React.Component {
  constructor(props) {
    super(props);

    const data = dayjs().format("YYYY-MM-DD");

    this.state = {
      planejamentos: [],
      refeicoes: [],
      user: this.props.match.params.user,
      planejamentoDe: {},
      dataBase: data,
      quantidadesPlanejadas: {},
      compartilhando: false,
    };

    this.receitaDeletada = this.receitaDeletada.bind(this);
    this.planoCriado = this.planoCriado.bind(this);
    this.aoSelecionarReceita = this.aoSelecionarReceita.bind(this);
    this.semanaAlterada = this.semanaAlterada.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.aoAtualizarQuantidade = this.aoAtualizarQuantidade.bind(this);
  }

  planoCriado() {
    this.consultarPlanejamento(this.state.dataBase);
  }

  componentDidMount() {
    this.consultarUsuario();
    this.consultarRefeicoes();
    this.consultarPlanejamento(this.state.dataBase);
  }

  consultarUsuario() {
    if (this.state.user)
      comidaApi
        .get(`users/nome/${this.state.user}`)
        .then((resultado) => {
          this.setState({
            planejamentoDe: resultado.data,
          });
        })
        .catch(() => {});
    else
      this.setState({
        planejamentoDe: usuarioLogado.Information.user,
      });
  }

  consultarPlanejamento(dataBase) {
    comidaApi
      .get(`planejamento/${dataBase}/${this.state.user || ""}`)
      .then((response) => {
        let quantidadesPlanejadas = {};
        response.data.map((plano) => {
          plano.receitas.map((receita) => {
            quantidadesPlanejadas[receita.nome] =
              receita.quantidade +
              (quantidadesPlanejadas[receita.nome]
                ? quantidadesPlanejadas[receita.nome]
                : 0);

            return receita;
          });

          return plano;
        });

        this.setState({
          planejamentos: response.data,
          quantidadesPlanejadas,
        });
      })
      .catch((erro) => {
          toast.warn(erro.response?.data || "Houve um erro ao consultar seu planejamento");
      });
  }

  consultarRefeicoes() {
    comidaApi
      .get("refeicoes/")
      .then((response) => {
        this.setState({
          refeicoes: response.data,
        });
      })
      .catch((error) => {
        if (error.response?.status === 401) this.props.history.push("/");
        else
          toast.warn("Houve um erro ao consultar seu planejamento");
      });
  }

  semanaAlterada(data) {
    this.setState(
      {
        dataBase: data,
      },
      () => {
        this.consultarPlanejamento(this.state.dataBase);
      }
    );
  }

  aoSelecionarReceita(id) {
    this.props.history.push(`/receitas/${id}`);
  }

  receitaDeletada(e, dia) {
    if (!e)
      toast.warn("Não foi possível retirar essa receita do planejamento");
    else {
      let planejamento = this.state.planejamentos.find((pl) => pl.dia === dia);
      let indicePlanejamento = this.state.planejamentos.findIndex(
        (pl) => pl.dia === dia
      );
      let indiceReceita = planejamento.receitas.findIndex(
        (r) => r._id === e._id && r.refeicao === e.refeicao
      );

      let planejamentoAtualizado = update(this.state.planejamentos, {
        [indicePlanejamento]: {
          receitas: { $splice: [[indiceReceita, 1]] },
        },
      });

      let quantidadesPlanejadas = this.state.quantidadesPlanejadas;
      quantidadesPlanejadas[e.nome] -= e.quantidade;

      this.setState({
        planejamentos: planejamentoAtualizado,
        quantidadesPlanejadas,
      });
    }
  }

  onDragEnd(resultado) {
    const { destination, source, draggableId } = resultado;
    
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const idReceita = draggableId.split("_")[0];
    const refeicaoOrigem = source.droppableId.split("_")[0];
    const diaOrigem = source.droppableId.split("_")[1];
    const refeicaoDestino = destination.droppableId.split("_")[0];
    const diaDestino = destination.droppableId.split("_")[1];
    const user = this.state.user || usuarioLogado.Information.user._id;

    let indiceOrigem = 0;
    let planoOrigem = this.state.planejamentos.find((p, i) => {
      indiceOrigem = i;
      return p.dia === diaOrigem;
    });

    if (!planoOrigem) {
      indiceOrigem = -1;
      planoOrigem = {
        dia: diaOrigem,
        user,
        receitas: [],
      };
    }

    const receitasOrigem = Array.from(planoOrigem.receitas);

    const receitaExcluida = receitasOrigem.splice(source.index, 1)[0];

    let indiceDestino = 0;
    let planoDestino = this.state.planejamentos.find((p, i) => {
      indiceDestino = i;
      return p.dia === diaDestino;
    });

    if (!planoDestino) {
      indiceDestino = -1;
      planoDestino = {
        dia: diaDestino,
        user,
        receitas: [],
      };
    }

    const receitasDestino = Array.from(
      indiceOrigem === indiceDestino ? receitasOrigem : planoDestino.receitas
    );

    receitaExcluida.refeicao = refeicaoDestino;

    const duplicidade = receitasDestino.findIndex(
      (receita) =>
        receita.refeicao === refeicaoDestino && receita._id._id === idReceita
    );

    if (duplicidade >= 0) {
      const receitaAtualizada = receitasDestino[duplicidade];
      receitaAtualizada.quantidade += receitaExcluida.quantidade;
      receitasDestino.splice(duplicidade, 1, receitaAtualizada);
    } else receitasDestino.splice(destination.index, 0, receitaExcluida);

    const novoPlanoDestino = {
      ...planoDestino,
      receitas: receitasDestino,
    };

    const novoPlanoOrigem = {
      ...planoOrigem,
      receitas: receitasOrigem,
    };

    const novoPlanejamento = this.state.planejamentos;

    novoPlanejamento.splice(indiceOrigem, 1, novoPlanoOrigem);
    novoPlanejamento.splice(
      indiceDestino,
      indiceDestino >= 0 ? 1 : 0,
      novoPlanoDestino
    );

    const exclusao = comidaApi.delete(
      `planejamento/${planoOrigem._id}/${refeicaoOrigem}/${idReceita}/${user}`
    );
    const inclusao = comidaApi.post(`planejamento/`, {
      ...novoPlanoDestino,
      receitas: [{ ...receitaExcluida, _id: receitaExcluida._id._id }],
    });

    this.setState((state) => ({
      ...state,
      planejamentos: novoPlanejamento,
    }));

    Axios.all([exclusao, inclusao])
      .then((resultado) => {
        if (!novoPlanoDestino._id) {
          novoPlanoDestino._id = resultado[1].data._id;
          this.setState((state) => ({
            ...state,
            planejamentos: novoPlanejamento,
          }));
        }
      })
      .catch((erro) => {
        toast.warn(erro.response?.data  || "Houve um erro ao atualizar este item. Por favor, tente novamente mais tarde.");
        this.consultarPlanejamento(this.state.dataBase);
      });
  }

  render() {

    return (
      <div>
        <Helmet>
          <title>Planejamento Semanal - caseira.app</title>
        </Helmet>
        <MenuPagina
          dataBase={this.state.dataBase}
          user={this.state.user}
          planejamentoDe={this.state.planejamentoDe} 
          aoAlterarData={this.semanaAlterada} />
        <div>
          <div className="container-fluid" style={{ overflowX: "scroll" }}>
            <div className="row flex-nowrap">
              <DragDropContext onDragEnd={this.onDragEnd}>
                {dias.map((d) => {
                  const dia = new Date(this.state.dataBase);
                  dia.setDate(dia.getDate() + d);
                  const plano = this.state.planejamentos.find(
                    (p) => p.dia === dia.toISOString()
                  );

                  return (
                    <ItemPlanejamento
                      className="col-10 col-md-4 col-lg-3 ms-2 me-1 mt-2 mb-2 card shadow"
                      plano={plano}
                      dia={dia.toISOString()}
                      user={this.state.planejamentoDe}
                      planoCriado={this.planoCriado}
                      refeicoes={this.state.refeicoes}
                      quantidadesPlanejadas={this.state.quantidadesPlanejadas}
                      key={d}
                      receitaDeletada={this.receitaDeletada}
                      aoSelecionarReceita={(r) => this.aoSelecionarReceita(r)}
                      aoAtualizarQuantidade={this.aoAtualizarQuantidade(plano)}
                    />
                  );
                })}
              </DragDropContext>
            </div>
          </div>
        </div>
      </div>
    );
  }

  aoAtualizarQuantidade(plano) {
    return (receita, quantidade) => {
      if (quantidade === 0) {
        toast.warn("Não foi possível atualizar a quantidade");
        return;
      }

      let quantidadesPlanejadas = this.state.quantidadesPlanejadas;
      quantidadesPlanejadas[receita.nome] += quantidade - receita.quantidade;

      let indicePlanejamento = this.state.planejamentos.findIndex(
        (pl) => pl.dia === plano.dia
      );
      let indiceReceita = this.state.planejamentos[
        indicePlanejamento
      ].receitas.findIndex(
        (re) => re.refeicao === receita.refeicao && receita.nome === re.nome
      );

      let planejamentos = update(this.state.planejamentos, {
        [indicePlanejamento]: {
          receitas: {
            [indiceReceita]: {
              quantidade: {
                $set: quantidade,
              },
            },
          },
        },
      });

      this.setState({
        quantidadesPlanejadas,
        planejamentos,
      });
    };
  }
}

IndexPlanejamento.propTypes = {
  history: PropTypes.object,
  onIngredientAdicionado: PropTypes.func,
  ingredientes: PropTypes.array,
  match: PropTypes.object,
};
