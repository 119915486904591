import React from "react";
import comidaApi from "../../services/comida.api";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
const semlactose = "/semlactose.jpg";
const semgluten = "/semgluten.jpg";
const diet = "/diet.jpg";
const vegana = "/vegana.jpg";
const vegetariana = "/vegetariana.jpg";
const semrestricoes = "/semrestricoes.jpg";
const lowcarb = "/lowcarb2.jpg";
const semfrutose = "/semfrutose.jpg";
const cetogenica = "/cetogenica2.jpg";
import { Link } from "react-router-dom";
import Container from "../container";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import Busca from "../busca";
import BotaoFabReceita from "../botao.fab.receita";

const imagens = {
  semlactose,
  semgluten,
  diet,
  vegana,
  vegetariana,
  semrestricoes,
  lowcarb,
  cetogenica,
  semfrutose
};

export default class IndexReceitas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alimentacao: [],
      receitas: [],
    };
  }

  componentDidMount() {
    this.atualizarDados();
  }

  atualizarDados() {
    comidaApi
      .get("alimentacao")
      .then((response) => {
        this.setState({ alimentacao: response.data });
      })
      .catch(() => {
        toast.warn(
          "Houve um erro ao consultar os tipos de alimentaçao. Por favor, tente novamente mais tarde.",
        );
      });
  }

  render() {
    return (
      <Container>
        <Helmet>
          <title>Tipos de Receitas - caseira.app</title>
        </Helmet>
        <h5>
          <FontAwesomeIcon icon={faBook} /> Receitas
        </h5>
        <Busca className="d-block d-lg-none" />
        
        <div className="row">
          {this.state.alimentacao.map((alimentacao) => (
            <div
              className="col-6 col-md-4 col-lg-3 p-2"
              key={alimentacao.slug}
            >
              <Link to={`/receitas/tipos/${alimentacao.slug}`}>
                <Card>
                  <Card.Img
                    variant="top"
                    src={imagens[alimentacao.slug.replace("-", "")]}
                    style={{
                      objectFit: "cover",
                      height: "17rem",
                    }}
                  ></Card.Img>
                  <Card.Header>{alimentacao.nome}</Card.Header>
                </Card>
              </Link>
            </div>
          ))}
        </div>
        <BotaoFabReceita />
      </Container>
    );
  }
}

IndexReceitas.propTypes = {
  history: PropTypes.object,
};
