export const validacaoNome = [{
    field: "nome",
    method: (valor) => (valor.length > 1 && valor.length < 255),
    validWhen: true,
    message: "Informe o nome"
}]

export const validacaoEmail = [
    {
        field: "email",
        method: "isEmpty",
        validWhen: false,
        message: "Informe seu e-mail"
    },
    {
        field: "email",
        method: "isEmail",
        validWhen: true,
        message: "Informe um e-mail válido"
    }]

export const validacaoSenha =
[{
    field: "password",
    method: "isEmpty",
    validWhen: false,
    message: "Informe uma senha para seu acesso"
}]

export const validacaoConfirmacaoSenha = [
    {
        field: "passwordConfirmation",
        method: "isEmpty",
        validWhen: false,
        message: "Digite novamente sua senha"
    },
    {
        field: "passwordConfirmation",
        method: (confirmation, state) => (state.password === confirmation),
        validWhen: true,
        message: "As senhas informadas não conferem. Tente novamente"
    }]