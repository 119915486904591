import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import comidaApi from "../../services/comida.api"
import User from '../../models/user';
import {  toast } from 'react-toastify';
import Axios from 'axios';
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faTimes } from '@fortawesome/free-solid-svg-icons';

let TIME_OUT = 0

const user = new User()

function Compartilhar(props) {
    const [permissao, setPermissao] = useState({ value: "Visualizar", label: "Visualizar" })
    const [usuarios, setUsuarios] = useState([])
    const [processando, setProcessando] = useState(false)

    const aoSelecionarUsuarios = (valor) => {
        setUsuarios(valor)
    }

    const aoMudarPermissao = (valor) => {
        setPermissao(valor)
    }

    const aoCarregarPermissoes = () => {
        return [{ value: "Visualizar", label: "Visualizar" }, { value: "Editar", label: "Editar" }];
    }

    const aoCompartilhar = () => {
        
        setProcessando(true)

        let novoCompartilhamento = []

        for (let index = 0; index < usuarios.length; index++) {
            const usuario = usuarios[index];
            const compartilhamento = {
                user: user.Information.user._id,
                compartilhaCom: usuario.value,
                permissao: permissao.value,
            }

            novoCompartilhamento.push(comidaApi.post(`compartilhamentos/`, compartilhamento))
        }

        Axios.all(novoCompartilhamento)
            .then((resultado) => {
                setProcessando(false)
                if (props.aoCompartilhar)
                    props.aoCompartilhar(resultado)
            })
            .catch(() => {
                toast.warn("Erro ao cadastrar compartilhamento")
                setProcessando(false)
            })
    }

    const aoConsultarUsuarios = (valor) => {
        if (TIME_OUT)
            clearTimeout(TIME_OUT)

        return new Promise((resolve) => {

            TIME_OUT = setTimeout(() => {
                resolve(comidaApi.get(`users/?q=${valor}`)
                    .then(res => {
                        return res.data.docs.map((r) => ({ value: r._id, label: r.nome }));
                    })
                    .catch(() => {
                        return [{
                            label: "Erro ao consultar usuário.",
                            value: `Houve um erro ao consultar usuários com nome ${valor}. Por favor, tente novamente mais tarde.`
                        }]
                    })
                )
            }, 1000)
        })
    }

    return (
        <div>
            <Modal
                show={props.visivel}
                onHide={props.ocultar}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title><FontAwesomeIcon icon={faShare} /> compartilhar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="mb-3">
                            <label className="w-100">Usuários
                            <AsyncSelect
                                    isMulti={true}
                                    noOptionsMessage={() => ("Nenhum usuário localizado :(")}
                                    loadingMessage={() => ("Procurando usuários...")}
                                    placeholder="Digite o nome de um usuário..."
                                    loadOptions={aoConsultarUsuarios}
                                    onChange={aoSelecionarUsuarios}
                                />
                            </label>
                        </div>
                        <div className="mb-3">
                            <label className="w-100">Permissão
                            <Select
                                    defaultValue={aoCarregarPermissoes()[0]}
                                    options={aoCarregarPermissoes()}
                                    onChange={aoMudarPermissao}
                                /></label>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={processando}
                        variant="primary text-white"
                        onClick={aoCompartilhar}>
                        <FontAwesomeIcon icon={faShare} /> Compartilhar
                    </Button>
                    <Button variant="outline-secondary" onClick={props.ocultar}><FontAwesomeIcon icon={faTimes} /> Cancelar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

Compartilhar.propTypes = {
    aoCompartilhar: PropTypes.func,
    visivel: PropTypes.bool,
    ocultar: PropTypes.func,
}

export default Compartilhar
