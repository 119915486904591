import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Toast } from "react-bootstrap";
import Loading from "../loading";
import comidaApi from "../../services/comida.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faIdCard, faTimes } from "@fortawesome/free-solid-svg-icons";
import MaskedInput from "react-text-mask";

function EditarFornecedor(props) {
  const [alerta, setAlerta] = useState({ visivel: false });
  const [processando, setProcessando] = useState(false);
  const [item, setItem] = useState({ fornecedor: props.fornecedor });

  useEffect(() => {
    if (props.value) {
      setItem({
        ...props.value,
      });
    }
  }, [props.value]);

  const mascaraTelefone = (valor) => {
    if (valor.length <= 14)
      return [
        "(",
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];

    else
      return [
        "(",
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
  };
  return (
    <Modal show={props.visivel} onHide={props.aoOcultar} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon icon={faIdCard} /> Editar Informações
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Toast
          show={alerta.visivel}
          onClose={() => {
            setAlerta({ visivel: false });
          }}
        >
          <Toast.Header>
            <strong className="me-auto">Erro</strong>
          </Toast.Header>
          <Toast.Body>{alerta.mensagem}</Toast.Body>
        </Toast>
        <form onSubmit={enviar}>
          <div className="mb-3">
            <label className="w-100">
              E-mail
              <input
                type="text"
                className="form-control"
                value={item.email}
                onChange={(evento) => {
                  setItem({ ...item, email: evento.target.value });
                }}
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="w-100">
              WhatsApp
              <MaskedInput
                mask={mascaraTelefone}
                name="telefone"
                guide={false}
                className="form-control"
                value={item.telefone}
                placeholder="Ex.: (11) 99999-9999"
                onChange={(evento) => {
                  setItem({
                    ...item,
                    telefone: evento.target.value,
                  });
                }}
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="w-100">
              Descrição
              <textarea
                className="form-control"
                value={item.descricao}
                onChange={(evento) => {
                  setItem({ ...item, descricao: evento.target.value });
                }}
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="w-100">
              Categoria
              <input
                type="text"
                step="any"
                className="form-control"
                value={item.categoria}
                onChange={(evento) => {
                  setItem({ ...item, categoria: evento.target.value });
                }}
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="w-100">
              Valor Mínimo do Pedido
              <input
                type="number"
                step="any"
                className="form-control"
                value={item.pedidoMinimo}
                onChange={(evento) => {
                  setItem({ ...item, pedidoMinimo: evento.target.value });
                }}
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="w-100">
              Dia de Entrega
              <textarea
                type="text"
                className="form-control"
                value={item.diaEntrega}
                onChange={(evento) => {
                  setItem({ ...item, diaEntrega: evento.target.value });
                }}
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="w-100">
              Observação
              <textarea
                type="text"
                className="form-control"
                value={item.observacao}
                onChange={(evento) => {
                  setItem({ ...item, observacao: evento.target.value });
                }}
              />
            </label>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary text-white" disabled={processando} onClick={enviar}>
          {processando ? (
            <Loading />
          ) : (
              <span>
                <FontAwesomeIcon icon={faCheck} /> {" "}
              Atualizar
              </span>
            )}
        </Button>
        <Button variant="outline-secondary" onClick={props.aoOcultar}>
          <FontAwesomeIcon icon={faTimes} /> Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function enviar(evento) {
    evento.preventDefault();

    setProcessando(true);

    comidaApi.put(`fornecedores/${props.value._id}`, { ...item, produtos: undefined, cardapio: undefined })
      .then((resultado) => {
        setProcessando(false);
        if (props.aoAtualizar) props.aoAtualizar(resultado.data);
      })
      .catch((erro) => {
        setProcessando(false);
        setAlerta({
          visivel: true,
          mensagem:
            erro.response?.data ||
            "Houve um erro ao atualizar seu cadastro. Por favor, tente novamente mais tarde.",
        });
      });
  }
}

EditarFornecedor.propTypes = {
  visivel: PropTypes.bool,
  aoOcultar: PropTypes.func,
  fornecedor: PropTypes.string,
  aoAtualizar: PropTypes.func,
  value: PropTypes.object,
};

export default EditarFornecedor;
