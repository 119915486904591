import fetch from "isomorphic-fetch";
import { ChecarItemPersonalizavel } from "../actions/cardapio";
import {
  ATUALIZAR_GRUPOS,
  OCULTAR_MENU,
  EXCLUIR_ITEM_SACOLA,
  INCLUIR_ITEM_SACOLA,
  LIMPAR_SACOLA,
  LOG_IN,
  ATUALIZAR_MENU,
} from "../constants/action-types";
import User from "../models/user";

const user = new User();

const menuLogado = {
  mobile: "d-lg-none navbar fixed-bottom navbar-expand-sm navbar-light bg-primary",
  pc: "d-none d-lg-flex navbar navbar-expand-lg navbar-light bg-white"
};

export const menuAnonimo = {
  mobile: "d-none",
  pc: "navbar navbar-expand-lg navbar-light bg-white"
};

const menuOculto = {
  mobile: "d-none",
  pc: "d-none"
};

const initialState = {
  loggedin: user.IsLoggedin,
  nome: user.Information.user?.nome,
  grupos: null,
  sacola: {},
  receita: {},
  fornecedor: {},
  menu: menuAnonimo
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case LOG_IN:
      state = {
        ...state,
        nome: action.payload.nome,
        loggedin: action.payload.loggedin,
        grupos: action.payload.grupos,
        menu: action.payload.loggedin ? menuLogado : menuAnonimo
      };
      return state;
    case ATUALIZAR_MENU:
      {
        return { ...state, menu: state.loggedin ? menuLogado : menuAnonimo }
      }
    case LIMPAR_SACOLA:
      return { ...state, sacola: {} };
    case OCULTAR_MENU:
      return { ...state, menu: menuOculto }
    case INCLUIR_ITEM_SACOLA: {
      const novaSacola = { ...state.sacola }

      const fornecedor = action.payload.fornecedor._id || action.payload.fornecedor

      let item = novaSacola[action.payload._id + fornecedor]

      if (!item || item.length === 0)
        novaSacola[action.payload._id + fornecedor] = [{
          ...action.payload,
          quantidade: 1,
          adicionadoEm: new Date()
        }]
      else {
        const amostra = item[0]

        if (!ChecarItemPersonalizavel(amostra)) {
          amostra.quantidade++
          item = [amostra]
        } else {
          item.push({
            ...action.payload,
            adicionadoEm: new Date()
          })
        }

        novaSacola[action.payload._id + fornecedor] = item
      }

      return { ...state, sacola: novaSacola }
    }
    case EXCLUIR_ITEM_SACOLA: {
      const novaSacola = { ...state.sacola }
      const fornecedor = action.payload.fornecedor._id || action.payload.fornecedor

      let item = novaSacola[action.payload._id + fornecedor]

      if (item && item.length > 0) {
        const amostra = item[0]

        if (!ChecarItemPersonalizavel(amostra)) {
          amostra.quantidade--

          if (amostra.quantidade <= 0)
            item = null
          else
            item = [amostra]
        } else {
          const posicao = item.findIndex((i) => i.adicionadoEm === action.payload.adicionadoEm)

          if (posicao >= 0)
            item.splice(posicao, 1)
        }

        if (item)
          novaSacola[action.payload._id + fornecedor] = item
        else
          delete novaSacola[action.payload._id + fornecedor]
      }

      return { ...state, sacola: novaSacola }
    }
    case ATUALIZAR_GRUPOS: {
      {
        state = {
          ...state,
          grupos: action.payload,
        };

        return state;
      }
    }
    case "receita/receitaConsultada": {
      {
        state = {
          ...state,
          receita: action.payload,
        };

        return state;
      }
    }
    case "fornecedor/fornecedorConsultado": {
      {
        state = {
          ...state,
          fornecedor: action.payload,
        };

        return state;
      }
    }
    default:
      return state;
  }
}

function consultarApi() {
  if (process.env.NODE_ENV !== "production") return "http://caseira_api_1:5500";
  else return "https://api.caseira.app";
}

export function consultarReceita(dispatch, getState) {
  return fetch(`${consultarApi()}/receitas/${getState().idReceita}`)
    .then((resposta) => {
      if (resposta.status >= 400) {
        console.log(`status ${resposta.status} para receita ${getState().idReceita}: ${resposta.statusText}`);
        return null;
      } else return resposta.json();
    })
    .then((receita) => {
      return dispatch({
        type: "receita/receitaConsultada",
        payload: receita,
      });
    });
}

export function consultarFornecedor(dispatch, getState) {
  return fetch(`${consultarApi()}/fornecedores/${getState().idFornecedor}`)
    .then((resposta) => {
      if (resposta.status >= 400) {
        console.log(`status ${resposta.status}: ${resposta.statusText}`);
        return null;
      } else return resposta.json();
    })
    .then((fornecedor) => {
      return dispatch({
        type: "fornecedor/fornecedorConsultado",
        payload: fornecedor,
      });
    });
}

export default rootReducer;
