import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Accordion, Button, Modal } from "react-bootstrap";
import Loading from "../loading";
import { validacaoNome } from "../login/validacoes";
import FormValidator from "../FormValidator";
import SelecaoTipoAlimentacao from "../selecaoTipoAlimentacao";
import comidaApi from "../../services/comida.api";
import OpcoesPeso from "./opcoesPeso";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTasks, faTimes } from "@fortawesome/free-solid-svg-icons";
import CreatableSelect from "react-select/creatable";

function AdicionarCardapio(props) {
  let validacoes = [];
  validacoes = validacoes.concat(validacaoNome);

  const [processando, setProcessando] = useState(false);
  const [item, setItem] = useState({
    fornecedor: props.fornecedor,
    nome: "",
    preco: "",
  });
  const [validacao, setValidacao] = useState({});
  const [enviado, setEnviado] = useState(false);
  const [opcoesPeso, setOpcoesPeso] = useState(null);
  const [validacaoPreco, setValidacaoPreco] = useState({});

  if (!opcoesPeso)
    validacoes = validacoes.concat({
      field: "preco",
      method: "isEmpty",
      validWhen: false,
      message: "Informe o preço",
    });

  let validador = new FormValidator(validacoes);
  let validation = enviado ? validador.validate(item) : validacao;

  useEffect(() => {
    if (props.value) {
      setOpcoesPeso(props.value.opcoesPeso);
      setItem({
        ...props.value,
        tipoAlimentacao: props.value.tipoAlimentacao?.map((t) => ({
          label: t.nome,
          value: t.slug,
        })),
        grupo: {
          label: props.value.grupo,
          value: props.value.grupo,
        },
      });
    }
  }, [props.value]);

  return (
    <Modal show={props.visivel} onHide={props.aoOcultar} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Novo Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <form onSubmit={enviar}>
          <div className="mb-3">
            <label className="w-100">
              Nome
              <input
                name="nome"
                type="text"
                className="form-control"
                value={item.nome}
                placeholder="Ex.: Executivo de Frango..."
                onChange={(evento) => {
                  setItem({ ...item, nome: evento.target.value });
                }}
              />
            </label>
            <span className="text-danger">{validation.nome?.message}</span>
          </div>
          <div className="mb-3">
            <label className="w-100">
              Descrição
              <br />
              <small>Conte um pouco sobre esse item</small>
              <input
                name="descricao"
                type="text"
                className="form-control"
                value={item.descricao}
                placeholder="Ex.: Excelente para dias quentes..."
                onChange={(evento) => {
                  setItem({ ...item, descricao: evento.target.value });
                }}
              />
            </label>
            <span className="text-danger">{validation.descricao?.message}</span>
          </div>
          {item.personalizavel ? null : (
            <div>
              <div className="mb-3">
                <label className="w-100">
                  Ingredientes
                  <br />
                  <small>
                    Quanto mais informação, melhor. Lembre-se de preferências e
                    alergias
                  </small>
                  <textarea
                    name="ingredientes"
                    type="text"
                    className="form-control"
                    value={item.ingredientes}
                    placeholder="Ex.: Arroz, alho, cebola..."
                    onChange={(evento) => {
                      setItem({ ...item, ingredientes: evento.target.value });
                    }}
                  />
                </label>
                <span className="text-danger">
                  {validation.descricao?.message}
                </span>
              </div>
              <SelecaoTipoAlimentacao
                className="mb-3"
                name="tipoAlimentacao"
                value={item.tipoAlimentacao}
                onChange={(evento) => {
                  setItem({ ...item, tipoAlimentacao: evento });
                }}
              />
            </div>
          )}
          <Accordion
            className="mb-3"
            defaultActiveKey={props.value?.opcoesPeso ? "0" : "1"}
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <FontAwesomeIcon icon={faTasks} /> Opções
              </Accordion.Header>
              <Accordion.Body>
                <div className="mb-3">
                  <label className="w-100">
                    Grupo <br />
                    <small>Selecione ou crie um grupo para esse item.</small>
                  </label>
                  <CreatableSelect
                    value={item.grupo}
                    formatCreateLabel={(i) => `Criar grupo "${i}"...`}
                    placeholder={"Selecione ou Digite..."}
                    noOptionsMessage={() => "Crie seu primeiro grupo..."}
                    options={props.grupos}
                    onCreateOption={(valor) => {
                      comidaApi
                        .post("gruposcardapio", {
                          nome: valor,
                          fornecedor: props.fornecedor,
                        })
                        .then((resposta) => {
                          const novoGrupo = {
                            label: resposta.data.nome,
                            value: resposta.data._id,
                          };

                          const gruposAtualizados = [
                            ...props.grupos,
                            novoGrupo,
                          ];

                          setItem({
                            ...item,
                            grupo: novoGrupo,
                          });

                          if (props.aoAtualizarGrupos)
                            props.aoAtualizarGrupos(gruposAtualizados);
                        })
                        .catch((erro) => {
                          toast.warn(
                            erro.response?.data ||
                            "Houve um erro ao criar esse grupo. Por favor, tente novamente mais tarde."
                          );
                        });
                    }}
                    onChange={(i) => {
                      setItem({
                        ...item,
                        grupo: i,
                      });
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label className="w-100">
                    Personalizável?
                    <small className="d-block">
                      O cliente pode escolher{" "}
                      <strong className="text-info">o que quiser</strong>{" "}
                      nessa refeição?
                    </small>
                    <select
                      value={item.personalizavel ? true : false}
                      className="d-block"
                      onChange={(e) => {
                        setItem({
                          ...item,
                          tipoAlimentacao: null,
                          ingredientes: null,
                          personalizavel: e.target.selectedIndex === 0,
                        });
                      }}
                    >
                      <option value={true}>Sim</option>
                      <option value={false}>Não</option>
                    </select>
                  </label>
                </div>
                <OpcoesPeso
                  name="opcoesPeso"
                  value={item.opcoesPeso}
                  className="mb-3"
                  onChange={(e) => {
                    setOpcoesPeso(e);
                    setItem({
                      ...item,
                      opcoesPeso: e,
                    });
                  }}
                />
                <span className="text-danger">
                  {validacaoPreco.opcoesPeso?.message}
                </span>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {!opcoesPeso ? mostrarPreco() : null}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary text-white" disabled={processando} onClick={enviar}>
          {processando ? (
            <Loading />
          ) : (
            <span>
              <FontAwesomeIcon icon={faCheck} />
              {!props.value ? "Adicionar" : "Atualizar"}
            </span>
          )}
        </Button>
        <Button variant="outline-secondary" onClick={props.aoOcultar}>
          <FontAwesomeIcon icon={faTimes} /> Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function mostrarPreco() {
    return (
      <div className="mb-3">
        <label className="w-100">
          Preço
          <input
            name="preco"
            type="number"
            step="0.01"
            className="form-control"
            value={item.preco}
            onChange={(evento) => {
              setItem({
                ...item,
                preco: evento.target.value,
              });
            }}
          />
        </label>
        <span className="text-danger">{validation.preco?.message}</span>
      </div>
    );
  }

  function enviar(evento) {
    evento.preventDefault();

    let validation = validador.validate(item);
    let validationPreco = {};

    if (item.opcoesPeso && !item.opcoesPeso.isValid) {
      validationPreco.opcoesPeso = {
        isInvalid: true,
        message: "Informe pelo menos um peso e preço",
      };
      validation.isInvalid = false;
    } else {
      validationPreco.opcoesPeso = { isInvalid: false, message: "" };
    }

    setValidacaoPreco(validationPreco);
    setValidacao({ validation });
    setEnviado(true);

    if (validation.isValid) {
      setProcessando(true);

      let novoItem = {
        ...item,
        tipoAlimentacao: item.tipoAlimentacao?.map((t) => ({
          nome: t.label,
          slug: t.value,
        })),
      };

      if (item.grupo) {
        novoItem.grupo = item.grupo.label;
      }

      incluirOuAtualizar(novoItem)
        .then((resultado) => {
          setProcessando(false);
          if (props.aoAdicionar) props.aoAdicionar(resultado.data);
        })
        .catch((erro) => {
          setProcessando(false);
          toast.warn(
            erro.response?.data ||
            "Houve um erro ao incluir este item. Por favor, tente novamente mais tarde."
          );
        });
    }

    function incluirOuAtualizar(novoItem) {
      if (!props.value) return comidaApi.post("cardapios", novoItem);
      else return comidaApi.put(`cardapios/${props.value._id}`, novoItem);
    }
  }
}

AdicionarCardapio.propTypes = {
  visivel: PropTypes.bool,
  aoOcultar: PropTypes.func,
  fornecedor: PropTypes.string,
  aoAdicionar: PropTypes.func,
  value: PropTypes.object,
  grupos: PropTypes.array,
  aoAtualizarGrupos: PropTypes.func,
};

export default AdicionarCardapio;
