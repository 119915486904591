import React, { useEffect, useState } from "react";
import Container from "../container";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, withRouter } from "react-router-dom";
import comidaApi from "../../services/comida.api";
import User from "../../models/user";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const user = new User();

const mapStateToProps = (state) => {
  return {
    grupos: state.grupos,
  };
};

function ConnectedIndexPedidos(props) {
  const [pedidos, setPedidos] = useState([]);

  useEffect(() => {
    let url = `pedidos/usuarios/${user.Information.user._id}`;

    if (props.grupos?.includes("FORNECEDORES")) {
      url = `pedidos/parceiro`;
    }

    comidaApi
      .get(url)
      .then((resultado) => {
        setPedidos(resultado.data);
      })
      .catch((erro) => {
        toast.warn(
          erro.response?.data ||
            "Houve um erro seus pedidos. Por favor, tente novamente mais tarde.",
        );
      });
  }, [props.grupos]);

  function mostrarPedidos() {
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Número</th>
            <th scope="col" className="d-none d-sm-table-cell">Data</th>
            <th scope="col" className="d-none d-sm-table-cell">Valor</th>
            <th scope="col">Situação</th>
          </tr>
        </thead>
        <tbody>
          {pedidos.map((item, index) => {
            const data = dayjs(item.criadoEm.substring(0, 10));
            return (
              <tr key={index} scope="row">
                <td>
                  <Link
                    className="text-dark"
                    to={`/pedidos/detalhes/${item.numero}/${
                      item.cliente || ""
                    }`}
                  >
                    <strong>{item.numero}</strong>
                  </Link>
                </td>
                <td className="d-none d-sm-table-cell">
                  {data.locale("pt-br").format("DD")}/
                  <small>{data.locale("pt-br").format("MMM")}</small>
                </td>
                <td className="d-none d-sm-table-cell">
                  {item.total?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </td>
                <td>
                  <Link
                    className="text-dark"
                    to={`/pedidos/detalhes/${item.numero}/${
                      item.cliente || ""
                    }`}
                  >
                    {item.status}
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  function mostrarMensagemSemPedidos() {
    return (
      <div className="alert alert-info">
        Você não tem nenhum pedido. Conheça nossos parceiros e faça seu primeiro
        pedido. <Link to="/fornecedores">Clique aqui.</Link>
      </div>
    );
  }

  return (
    <Container>
      <Helmet>
        <title>Meus Pedidos - caseira.app</title>
      </Helmet>
      
      <h5>
        <FontAwesomeIcon icon={faShoppingBasket} /> Meus Pedidos
      </h5>
      <div>
        {pedidos.length ? mostrarPedidos() : mostrarMensagemSemPedidos()}
      </div>
    </Container>
  );
}

ConnectedIndexPedidos.propTypes = {
  grupos: PropTypes.array,
};

const IndexPedidos = connect(
  mapStateToProps,
  null
)(withRouter(ConnectedIndexPedidos));

export default IndexPedidos;
