import React from "react"
import PropTypes from "prop-types";

const Loading = (props) => (
    <div className="d-inline">
        <div className="spinner-border" role="status">
            <span className="sr-only">Carregando...</span>
        </div>
        {props.children}
    </div>
)

Loading.propTypes = {
    children: PropTypes.any
};

export default Loading