import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import CardImagemReceita from "./card.imagem.receita";

function CardReceita(props) {
  if (props.receita._id)
    return (
      <Card className={props.className} onClick={props.onClick}>
        <CardImagemReceita
          imagem={
            props.receita.foto ? props.receita.foto : props.receita._id.foto
          }
        />
        <Card.Header>{props.receita.nome}</Card.Header>
      </Card>
    );
  else
    return (
      <div className={props.className}>
        <Skeleton height={225} />
        <Skeleton height={55} />
      </div>
    );
}

CardReceita.propTypes = {
  receita: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default CardReceita
