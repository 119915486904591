import React, { useState } from "react"
import ItemReceita from "../receitas/item.receita"
import PropTypes from 'prop-types'
import { Droppable } from "react-beautiful-dnd"
import AdicionarPlano from "../planejamento/add.plano"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"

export default function ItemRefeicao(props) {
    const [adicionando, setAdicionando] = useState(false)
    return (
        <div key={props.refeicao._id} className={props.className}>
            <div className="d-flex justify-content-between align-items-center">
                <small className="text-secondary font-weight-bold">{props.refeicao.nome}</small>
                <button type="button" className="btn btn-sm btn-light text-primary" onClick={() => { setAdicionando(true) }}><FontAwesomeIcon icon={faPlus} /> planejar</button>
            </div>
            <Droppable
                key={props.refeicao.nome}
                droppableId={[props.refeicao.nome, props.dia].join("_")} >
                {(provided) =>
                    (<div
                        className="bg-light"
                        style={{ minHeight: "67px" }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}>
                        <AdicionarPlano
                            visivel={adicionando}
                            diaSelecionado={props.dia}
                            user={props.user}
                            refeicoes={props.refeicoes}
                            refeicao={props.refeicao.nome}
                            aoOcultar={() => { setAdicionando(false) }}
                            planoCriado={() => {
                                setAdicionando(false)
                                props.planoCriado()
                            }}
                            className="col mt-2"
                        />
                        {props.receitas.map((r, i) => {
                            return (
                                <ItemReceita
                                    key={props.refeicao.nome + r._id._id + i}
                                    dia={props.dia}
                                    receita={r}
                                    user={props.user}
                                    index={r.index}
                                    refeicao={props.refeicao.nome}
                                    planejamento={props.planejamento}
                                    quantidadePlanejada={props.quantidadesPlanejadas[r.nome]}
                                    aoSelecionarReceita={() => props.aoSelecionarReceita(r._id.slug)}
                                    aoAtualizarQuantidade={props.aoAtualizarQuantidade}
                                    receitaDeletada={props.receitaDeletada}>
                                </ItemReceita>
                            )
                        })}
                        {provided.placeholder}
                    </div>)
                }
            </Droppable></div>)
}

ItemRefeicao.propTypes = {
  refeicao: PropTypes.object,
  planejamento: PropTypes.string,
  receitas: PropTypes.array,
  aoSelecionarReceita: PropTypes.func,
  quantidadesPlanejadas: PropTypes.object,
  aoAtualizarQuantidade: PropTypes.func,
  receitaDeletada: PropTypes.func,
  dia: PropTypes.string,
  user: PropTypes.object,
  refeicoes: PropTypes.array,
  planoCriado: PropTypes.func,
  className: PropTypes.string,
};
