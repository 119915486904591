import React, { useState } from 'react'
import Email from './email'
import { Modal, Button } from 'react-bootstrap'
import comidaApi from "../../services/comida.api"
import FormValidator from '../FormValidator';
import Message from '../message';

export default function RecuperarSenha() {
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState("");
    const [valido, setValido] = useState(null)
    const [mensagem, setMensagem] = useState("")

    const validador = new FormValidator([
        {
            field: "email",
            method: "isEmpty",
            validWhen: false,
            message: "Informe seu e-mail."
        },
        {
            field: "email",
            method: "isEmail",
            validWhen: true,
            message: "Informe um e-mail válido."
        },
    ])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const solicitarNovaSenha = () => {
        setMensagem("")
        const emailOK = validador.validate({ email })

        setValido(emailOK)

        if (emailOK.isValid) {
            comidaApi
                .post(`users/senhas/${email}`)
                .then((resultado) => { 
                    setMensagem(resultado.data + "\n\nIMPORTANTE: Para encontrar o e-mail, utilize a busca por 'caseira.app'."); 
                    setEmail("");
                })
                .catch((erro) => setMensagem(erro.response ? erro.response.data : "Erro ao solicitar nova senha."))
        }
    }

    return (
        <div className="d-inline">
            <Button variant="outline-secondary" onClick={handleShow} className="ms-3">
                Esqueci minha senha
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Esqueci minha senha</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Email name="email"
                        onChangeEmail={(e) => setEmail(e.target.value)}
                        email={email}
                        validation={valido ? valido.email.message : ""} />
                    <Message message={mensagem} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary text-white" onClick={solicitarNovaSenha}>
                        Solicitar nova senha
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
