import React from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
const logo = "/logo.png";
import PropTypes from "prop-types";
import { capitalizeWords } from "./stringFunctions";
import extrairPrimeiroNome from "./primeiroNome";
import { withRouter } from "react-router-dom";
import { DoAtualizarGrupos, DoAtualizarMenu, DoLogIn } from "../actions/index";
import User from "../models/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBook, faCalendarAlt, faClipboardList, faStore } from "@fortawesome/free-solid-svg-icons";
import caseiraApi from "../services/comida.api";
import BotaoSacola from "./botao.sacola";
import dayjs from "dayjs";
import Busca from "./busca";
import { menuAnonimo } from "../reducers";

const mapStateToProps = (state) => {
  return {
    loggedin: state.loggedin,
    nome: state.nome,
    sacola: state.sacola,
    grupos: state.grupos,
    menu: state.menu,
  };
};

const user = new User();

function mapDispatchToProps(dispatch) {
  return {
    DoLogIn: () => dispatch(DoLogIn({ loggedin: false })),
    DoAtualizarGrupos: (payload) => dispatch(DoAtualizarGrupos(payload)),
    DoAtualizarMenu: () => dispatch(DoAtualizarMenu()),
  };
}

const cadastreSe = "Cadastre-se";
const planejar = "Planejar";
const pedir = "Comprar";
const receitas = "Receitas";
const termo = "Termo de Uso";
const politica = "Política de Privacidade";
const perfil = "Meu perfil";

class SecureMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pesquisa: "",
      atual: ""
    };
  }

  componentDidMount() {
    this.props.DoAtualizarMenu();

    if (this.props.loggedin && !this.props.grupos) {
      caseiraApi
        .get(`users/grupos/${user.Information.user._id}`)
        .then((resultado) => {
          this.props.DoAtualizarGrupos(resultado.data);
        })
        .catch(() => {
          this.props.DoAtualizarGrupos(null);
        });
    }
  }

  render() {
    const linkPlanejamento = "/planejamento";
    const linkReceitas = "/receitas/";
    const linkCompras = `/ingredientes/lista/${dayjs().format("YYYY-MM-DD")}`
    const linkFornecedores = () => {
      if (this.props.loggedin)
        return "/fornecedores"
      else
        return "/localizacao"
    }
    const linkMenu = "/menu";

    const classeMenu = this.props.menu || menuAnonimo

    return (
      <>
        <nav className={classeMenu.mobile}>
          <ul className="nav justify-content-between w-100">
            <NavItemBottomMenu link={linkPlanejamento}>
              <FontAwesomeIcon icon={faCalendarAlt} size='lg' />
              <span>Planejamento</span>
            </NavItemBottomMenu>
            <NavItemBottomMenu link={linkReceitas}>
              <FontAwesomeIcon icon={faBook} size='lg' />
              <span>Receitas</span>
            </NavItemBottomMenu>
            <NavItemBottomMenu link={linkCompras}>
              <FontAwesomeIcon icon={faClipboardList} size='lg' />
              <span>Lista</span>
            </NavItemBottomMenu>
            <NavItemBottomMenu link={linkFornecedores()}>
              <FontAwesomeIcon icon={faStore} size='lg' />
              <span>Parceiros</span>
            </NavItemBottomMenu>
            <NavItemBottomMenu link={linkMenu}>
              <FontAwesomeIcon icon={faBars} size='lg' />
              <span>Menu</span>
            </NavItemBottomMenu>
          </ul>
        </nav >
        <nav className={classeMenu.pc}>
          <Link to={"/"} className="navbar-brand nav-link">
            <img
              src={logo}
              alt="Logotipo caseira.app"
              style={{ height: "28px" }}
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            />
          </Link>
          <div className="d-lg-none">
            <BotaoSacola />
          </div>
          <button
            className="navbar-toggler" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#navbarSupportedContent" 
            aria-controls="navbarSupportedContent" 
            aria-expanded="false" 
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto">
              {!this.props.loggedin ? (
                <ItemMenu>
                  <NavLink
                    to={"/usuarios/cadastro"}
                    className="nav-link"
                    activeClassName="text-primary font-weight-bold"
                  >
                    {cadastreSe}
                  </NavLink>
                </ItemMenu>
              ) : null}
              <ItemMenu>
                <NavLink
                  to={linkPlanejamento}
                  className={`nav-link`}
                  activeClassName="text-primary font-weight-bold"
                >
                  {planejar}
                </NavLink>
              </ItemMenu>
              <ItemMenu>
                {this.props.loggedin ? (
                  <NavLink
                    to={"/fornecedores"}
                    className="nav-link"
                    activeClassName="text-primary font-weight-bold"
                  >
                    {pedir}
                  </NavLink>
                ) : (
                  <NavLink
                    to={"/localizacao"}
                    className="nav-link"
                    activeClassName="text-primary font-weight-bold"
                  >
                    {pedir}
                  </NavLink>
                )}
              </ItemMenu>
              <ItemMenu>
                <NavLink
                  to={linkReceitas}
                  className="nav-link"
                  activeClassName="text-primary font-weight-bold"
                >
                  {receitas}
                </NavLink>
              </ItemMenu>
              {this.props.loggedin ? (
                <ItemMenu>
                  <NavLink to={"/receitas/criar"} className="nav-link">
                    Criar Receita
                  </NavLink>
                </ItemMenu>
              ) : null}
              <ItemMenu>
                <a
                  href="mailto:contato@caseira.app?subject=Contato - caseira.app"
                  className="nav-link"
                >
                  Contato
                </a>
              </ItemMenu>
              <li className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"
                >
                  Sobre
                </Link>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                >
                  <NavLink
                    className="dropdown-item"
                    to={"/termo"}
                    activeClassName="text-primary font-weight-bold"
                  >
                    {termo}
                  </NavLink>
                  <NavLink
                    className="dropdown-item"
                    to={"/politica"}
                    activeClassName="text-primary font-weight-bold"
                  >
                    {politica}
                  </NavLink>
                </div>
              </li>
            </ul>
            <Busca />
            <div className="d-none d-lg-inline">
              <BotaoSacola />
            </div>
            {this.props.loggedin ? (
              <ul className="navbar-nav ms-auto">
                <li className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"
                  >
                    {capitalizeWords(extrairPrimeiroNome(this.props.nome))}
                  </Link>
                  <div
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="navbarDropdown"
                  >
                    <NavLink
                      to={"/usuarios/editar"}
                      className="dropdown-item"
                      activeClassName="text-primary font-weight-bold"
                    >
                      {perfil}
                    </NavLink>
                    <NavLink
                      to={"/pedidos"}
                      className="dropdown-item"
                      activeClassName="text-primary font-weight-bold"
                    >
                      Meus Pedidos
                    </NavLink>
                    {this.props.grupos?.includes("FORNECEDORES") ? (
                      <NavLink
                        to={"/parcerias"}
                        className="dropdown-item"
                        activeClassName="text-primary font-weight-bold"
                      >
                        Minhas Parcerias
                      </NavLink>
                    ) : null}
                    <Link
                      to={"/"}
                      className="dropdown-item"
                      onClick={() => {
                        this.props.DoAtualizarGrupos(null);
                        user.logOut();
                        this.props.DoLogIn();
                      }}
                    >
                      Sair
                    </Link>
                  </div>
                </li>
              </ul>
            ) : null}
            {this.props.loggedin ? <div className="d-inline"></div> : null}
            <div className="d-inline d-lg-inline">
              {!this.props.loggedin ? (
                <div data-toggle="collapse" data-target=".navbar-collapse.show">
                  <Link to={"/login/"} className="btn btn-outline-primary mx-2">
                    Acesse
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
        </nav >
      </>
    );
  }
}

SecureMenu.propTypes = {
  loggedin: PropTypes.bool,
  menu: PropTypes.object,
  nome: PropTypes.string,
  grupos: PropTypes.array,
  DoAtualizarGrupos: PropTypes.func,
  sacola: PropTypes.object,
  history: PropTypes.object,
  DoLogIn: PropTypes.func,
  DoAtualizarMenu: PropTypes.func,
  children: PropTypes.any,
  location: PropTypes.object,
};

const NavItemBottomMenuStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  fontSize: "0.75rem",
  minWidth: "50px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  color: "#444444",
  textDecoration: "none",
}

function NavItemBottomMenu({ link, children }) {
  return <li className="nav-item">
    <NavLink
      style={NavItemBottomMenuStyle}
      className="text-white"
      activeClassName="text-light"
      to={link}>
      {children}
    </NavLink>
  </li>
}

function ItemMenu(props) {
  return (
    <li
      className="nav-item"
      data-toggle="collapse"
      data-target=".navbar-collapse.show"
    >
      {props.children}
    </li>
  );
}

NavItemBottomMenu.propTypes = {
  link: PropTypes.string,
  children: PropTypes.any,
}

ItemMenu.propTypes = {
  children: PropTypes.any,
};

const Menu = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SecureMenu));

export default Menu;
