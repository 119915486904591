import React from "react";
import PropTypes from "prop-types";

const Message = ({ message }) => {

    if (message) {

        return <div className="alert alert-warning" role="alert">
            {!message.includes("\n") ? message : message.split("\n").map((m, i) => {
                return <span key={i}>{m}<br /></span>
            })}
        </div>
    }
    else return null
}

Message.propTypes = {
    message: PropTypes.string
}

export default Message