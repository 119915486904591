import React from "react";
import { Link } from "react-router-dom";
import TableRow from "../ingredientes/tableRow.ingredientes";
import comidaApi from "../../services/comida.api";
import Container from "../container";

export default class IndexIngredientes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ingredientes: [] };
    this.ingredienteExcluido = this.ingredienteExcluido.bind(this);
  }

  componentDidMount() {
    this.atualizarDados();
  }

  atualizarDados() {
    comidaApi
      .get("ingredientes")
      .then((response) => {
        this.setState({ ingredientes: response.data });
      })
      .catch(() => {});
  }

  ingredienteExcluido(id) {
    comidaApi
      .delete("ingredientes/" + id)
      .then(() => {
        this.atualizarDados();
      })
      .catch(() => {});
  }

  tabRow(handler) {
    return this.state.ingredientes.map(function (object, i) {
      return <TableRow obj={object} key={i} ingredienteExcluido={handler} />;
    });
  }

  render() {
    return (
      <Container>
        <h5>Ingredientes</h5>
        <div>
          <Link className="btn btn-primary text-white" to={"/ingredientes/create"}>
            Novo Ingrediente
          </Link>
        </div>
        <table className="table table-striped" style={{ marginTop: 20 }}>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Tipo</th>
              <th colSpan="2"></th>
            </tr>
          </thead>
          <tbody>{this.tabRow(this.ingredienteExcluido)}</tbody>
        </table>
      </Container>
    );
  }
}
