import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import FormValidator from "../FormValidator";
import comidaApi from "../../services/comida.api";
import User from "../../models/user";
import SelecaoEstado from "../selecaoEstado";
import { toast } from "react-toastify";
import SelecaoCidade from "../selecaoCidade";
import Loading from "../loading";
import Select from "react-select";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const user = new User();

function InformacoesAdicionais(props) {
  let validacoes = [];
  validacoes = validacoes.concat({
    field: "telefone",
    method: "isEmpty",
    validWhen: false,
    message: "Informe o número do seu telefone fixo ou celular",
  });
  validacoes = validacoes.concat({
    field: "documento",
    method: "isEmpty",
    validWhen: false,
    message: "Informe seu CPF",
  });
  validacoes = validacoes.concat({
    field: "cep",
    method: "isEmpty",
    validWhen: false,
    message: "Informe o CEP do seu endereço",
  });
  validacoes = validacoes.concat({
    field: "logradouro",
    method: "isEmpty",
    validWhen: false,
    message: "Informe seu endereço",
  });
  validacoes = validacoes.concat({
    field: "numero",
    method: "isEmpty",
    validWhen: false,
    message: "Informe o número do seu endereço",
  });
  validacoes = validacoes.concat({
    field: "bairro",
    method: "isEmpty",
    validWhen: false,
    message: "Informe o bairro do seu endereço",
  });
  validacoes = validacoes.concat({
    field: "estado",
    method: "isEmpty",
    validWhen: false,
    message: "Informe o estado do seu endereço",
  });
  validacoes = validacoes.concat({
    field: "cidade",
    method: "isEmpty",
    validWhen: false,
    message: "Informe o cidade do seu endereço",
  });
  validacoes = validacoes.concat({
    field: "formaPagamento",
    method: "isEmpty",
    validWhen: false,
    message: "Informe a forma de pagamento",
  });

  const validador = new FormValidator(validacoes);

  const [informacoes, setInformacoes] = useState({});
  const [validacao] = useState(validador.valid());
  const [enviado, setEnviado] = useState(false);
  const [processando, setProcessando] = useState(false);
  const [formasPagamentos, setFormasPagamentos] = useState([]);
  const [erroPedido, setErroPedido] = useState(null)

  useEffect(() => {
    if (user.IsLoggedin)
      comidaApi
        .get(`users/${user.Information.user._id}`)
        .then((resultado) => {
          const estado = resultado.data.endereco?.estado;
          const cidade = resultado.data.endereco?.cidade;

          setInformacoes({
            telefone: resultado.data.telefone,
            documento: resultado.data.documento,
            cep: resultado.data.endereco?.cep || "",
            logradouro: resultado.data.endereco?.logradouro || "",
            numero: resultado.data.endereco?.numero || "",
            complemento: resultado.data.endereco?.complemento || "",
            bairro: resultado.data.endereco?.bairro || "",
            referencia: resultado.data.endereco?.referencia || "",
            estado: estado ? { value: estado, label: estado } : null,
            cidade: cidade ? { value: cidade, label: cidade } : null,
            formaPagamento: "",
          });
        })
        .catch((erro) => {
          toast.warn(
            erro.response?.data ||
            "Houve um erro ao consultar seus dados. Por favor, tente novamente mais tarde.",
          );
        });

    comidaApi
      .get(`pedidos/formaspagamentos`)
      .then((resultado) => {
        setFormasPagamentos(
          resultado.data.map((f) => ({
            label: f,
            value: f,
          }))
        );
      })
      .catch((erro) => {
        toast.warn(
          erro.response?.data ||
          "Houve um erro ao consultar formas de pagamentos. Por favor, tente novamente mais tarde.",
        );
      });
  }, []);

  function enviar(evento) {
    evento.preventDefault();

    setEnviado(true);
    setErroPedido(null);

    const informacoesValidas = validador.validate(informacoes);

    if (informacoesValidas.isValid) {
      setProcessando(true);

      let obj = {
        cliente: props.user,
        telefone: informacoes.telefone,
        formaPagamento: informacoes.formaPagamento,
        salvar: informacoes.salvar,
        documento: informacoes.documento,
        endereco: {
          cep: informacoes.cep,
          logradouro: informacoes.logradouro,
          numero: informacoes.numero,
          complemento: informacoes.complemento,
          bairro: informacoes.bairro,
          cidade: informacoes.cidade.label,
          estado: informacoes.estado.label,
          referencia: informacoes.referencia,
        },
        itens: props.itens.map((item) => ({
          item: item._id,
          variacao: item.variacao,
          tipo: item.tipo,
          quantidade: item.quantidade,
          peso: item.peso,
          tamanho: item.tamanho,
          fornecedor: item.fornecedor,
          personalizacao: item.personalizacao,
        })),
      };

      comidaApi
        .post("/pedidos", obj)
        .then((resposta) => {
          props.DoLimparSacola();
          props.history.push(`/pedidos/detalhes/${resposta.data.numero}?novo=s`);
        })
        .catch((erro) => {
          setProcessando(false);
          setErroPedido(
            erro.response?.data ||
            "Houve um erro seu pedido. Por favor, tente novamente mais tarde."
          )
        });
    }
  }

  let validation = enviado ? validador.validate(informacoes) : validacao;

  return (
    <Modal show={props.visivel} onHide={props.aoOcultar} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Entrega e Forma de Pagamento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        <form onSubmit={enviar}>
          <div className="mb-3">
            <label className="w-100">
              CPF
              <MaskedInput
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                ]
                }
                name="documento"
                guide={false}
                className="form-control"
                value={informacoes.documento}
                placeholder="Ex.: 333.333.333-33"
                onChange={(evento) => {
                  setInformacoes({
                    ...informacoes,
                    documento: evento.target.value,
                  });
                }}
              />
            </label>
            <span className="text-danger">{validation.documento?.message}</span>
          </div>
          <div className="mb-3">
            <label className="w-100">
              Telefone
              <MaskedInput
                mask={(valor) => {
                  if (valor.length <= 14)
                    return [
                      "(",
                      /\d/,
                      /\d/,
                      ")",
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ];
                  else
                    return [
                      "(",
                      /\d/,
                      /\d/,
                      ")",
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ];
                }}
                name="telefone"
                guide={false}
                className="form-control"
                value={informacoes.telefone}
                placeholder="Ex.: (11) 99999-9999"
                onChange={(evento) => {
                  setInformacoes({
                    ...informacoes,
                    telefone: evento.target.value,
                  });
                }}
              />
            </label>
            <span className="text-danger">{validation.telefone?.message}</span>
          </div>
          <div className="mb-3">
            <label className="w-100">
              CEP
              <MaskedInput
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                ]
                }
                name="cep"
                guide={false}
                className="form-control"
                value={informacoes.cep}
                placeholder="Ex.: 11111-111"
                onChange={(evento) => {
                  setInformacoes({
                    ...informacoes,
                    cep: evento.target.value,
                  });
                }}
              />
            </label>
            <span className="text-danger">{validation.cep?.message}</span>
          </div>
          <div className="mb-3">
            <label className="w-100">
              Endereço
              <input
                name="logradouro"
                type="text"
                className="form-control"
                placeholder="Ex.: Rua ..."
                value={informacoes.logradouro}
                onChange={(evento) => {
                  setInformacoes({
                    ...informacoes,
                    logradouro: evento.target.value,
                  });
                }}
              />
            </label>
            <span className="text-danger">
              {validation.logradouro?.message}
            </span>
          </div>
          <div className="mb-3">
            <label className="w-100">
              Número
              <input
                placeholder="Ex.: 45"
                name="numero"
                type="number"
                step="1"
                className="form-control"
                value={informacoes.numero}
                onChange={(evento) => {
                  setInformacoes({
                    ...informacoes,
                    numero: evento.target.value,
                  });
                }}
              />
            </label>
            <span className="text-danger">{validation.numero?.message}</span>
          </div>
          <div className="mb-3">
            <label className="w-100">
              Complemento
              <input
                placeholder="Ex.: Bloco 3 Apartamento 33"
                name="complemento"
                type="text"
                className="form-control"
                value={informacoes.complemento}
                onChange={(evento) => {
                  setInformacoes({
                    ...informacoes,
                    complemento: evento.target.value,
                  });
                }}
              />
            </label>
            <span className="text-danger">
              {validation.complemento?.message}
            </span>
          </div>
          <div className="mb-3">
            <label className="w-100">
              Bairro
              <input
                name="bairro"
                type="text"
                placeholder="Ex.: Bela Vista"
                className="form-control"
                value={informacoes.bairro}
                onChange={(evento) => {
                  setInformacoes({
                    ...informacoes,
                    bairro: evento.target.value,
                  });
                }}
              />
            </label>
            <span className="text-danger">{validation.bairro?.message}</span>
          </div>
          <div className="mb-3">
            <label className="w-100">
              Ponto de Referência
              <input
                placeholder="Ex.: Loja A ou Restaurante B"
                name="referencia"
                type="text"
                className="form-control"
                value={informacoes.referencia}
                onChange={(evento) => {
                  setInformacoes({
                    ...informacoes,
                    referencia: evento.target.value,
                  });
                }}
              />
            </label>
            <span className="text-danger">
              {validation.referencia?.message}
            </span>
          </div>
          <SelecaoEstado
            className="mb-3 col"
            value={informacoes.estado}
            onChange={(e) => {
              setInformacoes({
                ...informacoes,
                estado: e,
              });
            }}
          />
          <SelecaoCidade
            className="mb-3 col"
            estado={informacoes.estado}
            value={informacoes.cidade}
            onChange={(e) => {
              setInformacoes({
                ...informacoes,
                cidade: e,
              });
            }}
          />
          <div className="mb-3">
            <label className="w-100">
              Forma de Pagamento
              <Select
                placeholder="Como você vai pagar?"
                noOptionsMessage={() => "Sem opções"}
                name="formaPagamento"
                options={formasPagamentos}
                onChange={(f) => {
                  setInformacoes({
                    ...informacoes,
                    formaPagamento: f.value,
                  });
                }}
              />
            </label>
            <span className="text-danger">
              {validation.formaPagamento?.message}
            </span>
          </div>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Salvar estas informações para futuros pedidos"
              onChange={(e) => {
                setInformacoes({
                  ...informacoes,
                  salvar: e.target.checked,
                });
              }}
            />
          </Form.Group>
        </form>
      </Modal.Body>
      <Modal.Footer>
        {erroPedido && <div className="alert alert-danger">
          {erroPedido}
        </div>}
        <Button variant="primary text-white" disabled={processando} onClick={enviar}>
          {processando ? (
            <Loading />
          ) : (
            <span>
              <FontAwesomeIcon icon={faCheck} /> {"Enviar"}
            </span>
          )}
        </Button>
        <Button variant="outline-secondary" onClick={props.aoOcultar}>
          <FontAwesomeIcon icon={faTimes} /> Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

InformacoesAdicionais.propTypes = {
  visivel: PropTypes.bool,
  aoOcultar: PropTypes.func,
  user: PropTypes.string,
  itens: PropTypes.array,
  history: PropTypes.object,
  DoLimparSacola: PropTypes.func,
};

export default InformacoesAdicionais;
