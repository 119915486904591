import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import ItemCardapio from '../cardapios/item.cardapio'
import AdicionarSacola from '../cardapios/adicionar.sacola'
import { ConsultarItemSacola, DoIncluirItemSacola } from '../../actions/cardapio'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../services/comida.api'
import Skeleton from 'react-loading-skeleton'

function SelecionaVariacao(props) {

    const sacola = useSelector(state => state.sacola)
    const [itens, setItens] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        const consultar = async (item) => {
            const { _id, fornecedor } = item
            if (_id && fornecedor) {
                const retorno = await api.get(`/produtos/${_id}/fornecedores/${fornecedor}/variacoes`)
                setItens(retorno.data)
            }
        }

        consultar(props.item)

        return () => {
            setItens(null)
        }
    }, [props.item])

    return (
        <Modal show={props.visivel} onHide={props.aoOcultar} keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Selecione uma opção</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {mostrarItens(itens)}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary text-white"
                    onClick={props.aoOcultar}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    )

    function quantidadeAlterada(item, valor) {
        if (valor > 0)
            dispatch(DoIncluirItemSacola(item))

        props.aoOcultar && props.aoOcultar()
    }

    function mostrarItens(itens) {
        if (!itens)
            return <Skeleton count={4} height={200} width="100%" />
        else
            return itens.map((item, index) => {

                item.tipo = props.item.tipo

                const aoAdicionarItemCardapio = (valor) => {
                    if (valor <= 0) {
                        props.aoIniciarRemover && props.aoIniciarRemover(ConsultarItemSacola(sacola, item))
                    } else {
                        quantidadeAlterada(item, valor);
                    }
                };

                return (
                    <div key={index} className="p-2">
                        <ItemCardapio item={item} className="h-100">
                            <AdicionarSacola
                                onChange={aoAdicionarItemCardapio}
                            />
                        </ItemCardapio>
                    </div>
                );
            })
    }
}


SelecionaVariacao.propTypes = {
    visivel: PropTypes.bool,
    aoOcultar: PropTypes.func,
    item: PropTypes.any,
    enviar: PropTypes.func,
    aoIniciarRemover: PropTypes.func,
}

export default SelecionaVariacao

