const token = "token";
const expires = "expires";
const id = "id";
const nome = "nome";
const email = "email";
const cliente = typeof window != "undefined";

export default class User {
  get IsLoggedin() {
    if (this.Information.token) return true;
    else return false;
  }

  get Information() {
    return {
      token: cliente ? localStorage.getItem(token) : null,
      expires: cliente ? localStorage.getItem(expires) : null,
      user: {
        _id: cliente ? localStorage.getItem(id) : null,
        nome: cliente ? localStorage.getItem(nome) : null,
        email: cliente ? localStorage.getItem(email) : null,
      },
    };
  }
  set Information(value) {
    if (cliente) {
      localStorage.setItem(token, value.token);
      localStorage.setItem(expires, value.expires);
      localStorage.setItem(id, value.user._id);
      localStorage.setItem(nome, value.user.nome);
      localStorage.setItem(email, value.user.email);
    }
  }

  logOut() {
    localStorage.removeItem(token);
    localStorage.removeItem(expires);
    localStorage.removeItem(id);
    localStorage.removeItem(nome);
    localStorage.removeItem(email);
  }
}
