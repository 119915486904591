import React from "react"
import ItemRefeicao from "../refeicoes/item.refeicao"
import PropTypes from 'prop-types'
import { capitalizeWords } from "../stringFunctions"
import Skeleton from "react-loading-skeleton"
import dayjs from "dayjs"
import "dayjs/locale/pt-br"

export default function ItemPlanejamento(props) {
    const dia = dayjs(props.dia.substring(0, 10))
    return (
        <div className={props.className} key={props.dia}>
            <p><span className="text-primary font-weight-bold">{capitalizeWords(dia.locale("pt-br").format("dddd"))}</span> <small className="text-secondary">{dia.locale("pt-br").format("DD/MMMM")}</small></p>
            {props.refeicoes.length === 0 ? <Skeleton count={6} height={100} /> : null}
            {
                props.refeicoes.map((refeicao) => {
                    const receitas = []

                    if (props.plano?.receitas)
                        for (let index = 0; index < props.plano.receitas.length; index++) {
                            const receita = props.plano.receitas[index];
                            if (receita.refeicao === refeicao.nome)
                                receitas.push({ ...receita, index })
                        }

                    return (
                        <ItemRefeicao
                            className="mt-2"
                            refeicao={refeicao}
                            refeicoes={props.refeicoes}
                            receitas={receitas}
                            planoCriado={props.planoCriado}
                            user={props.user}
                            dia={props.dia}
                            quantidadesPlanejadas={props.quantidadesPlanejadas}
                            key={[refeicao.nome, props.dia].join("_")}
                            planejamento={props.plano?._id}
                            aoSelecionarReceita={(r) => props.aoSelecionarReceita(r)}
                            aoAtualizarQuantidade={props.aoAtualizarQuantidade}
                            receitaDeletada={(r) => props.receitaDeletada(r, props.dia)} />)
                })
            }
        </div>
    )
}

ItemPlanejamento.propTypes = {
    dia: PropTypes.string,
    receitas: PropTypes.array,
    refeicoes: PropTypes.array,
    planejamento: PropTypes.string,
    quantidadesPlanejadas: PropTypes.object,
    aoSelecionarReceita: PropTypes.func,
    className: PropTypes.string,
    aoAtualizarQuantidade: PropTypes.func,
    receitaDeletada: PropTypes.func,
    plano: PropTypes.object,
    user: PropTypes.object,
    planoCriado: PropTypes.func,
}