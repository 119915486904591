import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";

export default function Navegacao(props) {
  const inicioSemana = dayjs(props.defaultValue);
  const fimSemana = dayjs(props.defaultValue).add(6, "day");

  const proximaSemana = dayjs(props.defaultValue)
    .add(7, "day")
    .format("YYYY-MM-DD");
  const semanaAnterior = dayjs(props.defaultValue)
    .add(-7, "day")
    .format("YYYY-MM-DD");

  return (
    <div className={props.className}>
      <FontAwesomeIcon icon={faCalendarAlt} className="me-3" />
      {inicioSemana.locale("pt-br").format("DD/MMM")} a{" "}
      {fimSemana.locale("pt-br").format("DD/MMM")}
      <Button
        variant="outline-light text-primary "
        onClick={() => props.onChange(semanaAnterior)}
      >
        <FontAwesomeIcon icon={faChevronCircleLeft} />
      </Button>
      <Button
        variant="outline-light text-primary "
        onClick={() => props.onChange(proximaSemana)}
      >
        <FontAwesomeIcon icon={faChevronCircleRight} />
      </Button>
    </div>
  );
}

Navegacao.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};
