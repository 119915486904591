import React from "react";
import IngredienteReceita from "./receita.ingrediente";
import PropTypes from "prop-types";

export default class TableRowIngredientesReceita extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      processing: false
    }
  }
  render() {
    return (            
      <li className={this.props.className}><IngredienteReceita ingrediente={this.props.obj} /></li>
    )
  }
}

TableRowIngredientesReceita.propTypes = {
  className: PropTypes.string,
  obj: PropTypes.object,
}