import React, { useRef, useState } from "react";
import caseiraApi from "../../services/comida.api";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Loading from "../loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";

export default function FotoUploadCardapio(props) {
  const [carregando, setCarregando] = useState(false)
  const [visivel, setVisivel] = useState(false);

  const el = useRef(); // accesing input element    
  const handleChange = (e) => {
    setCarregando(true)

    const file = e.target.files[0]; // accesing file
    const foto = new FormData();
    foto.append('file', file); // appending file

    caseiraApi
      .put(`fotos\\cardapios\\${props.id}`, foto)
      .then((res) => {
        props.aoAtualizarImagem(res.data.foto);
        setVisivel(false)
      })
      .catch(() => {
        toast.warn(
          `Houve um erro ao atualizar a foto deste item. Por favor, tente novamente mais tarde.`,
        );
      })
      .finally(() => {
        setCarregando(false)
      });
  }

  return (
    <div className={props.className || "d-inline"}>
      <Button className="m-2" variant="light" onClick={() => setVisivel(true)}>
        <FontAwesomeIcon icon={faCamera} /> editar
      </Button>
      <Modal
        show={visivel}
        onHide={() => setVisivel(false)}
        dialogClassName="uploadfoto"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faCamera} /> foto
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="file" ref={el} onChange={handleChange} />
          {carregando && <Loading />}
        </Modal.Body>
      </Modal>
    </div>
  );
}

FotoUploadCardapio.propTypes = {
  id: PropTypes.string.isRequired,
  aoAtualizarImagem: PropTypes.func,
  className: PropTypes.string,
};
