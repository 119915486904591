import React, { useState, useEffect } from "react";
import comidaApi from "../../services/comida.api";
import { Card } from "react-bootstrap";
import {  toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import extrairPrimeiroNome from "../primeiroNome";
import Container from "../container";
import { Helmet } from "react-helmet";

export default function MenuPlanejamento() {
  const [compartilhamentos, setCompartilhamentos] = useState([]);
  const history = useHistory();

  useEffect(() => {
    comidaApi
      .get("compartilhamentos/comigo/")
      .then((resultado) => {
        if (resultado.data.length === 0) history.push("/planejamento");
        else setCompartilhamentos(resultado.data);
      })
      .catch(() => {
        toast.warn(
          "Houve um erro ao consultar os planejamentos comparitlhados com você."
        );
      });
  }, [history]);

  return (
    <Container>
      <Helmet>
        <title>Planejamentos - caseira.app</title>
      </Helmet>
      
      <div className="row">
        <div className="col-6 col-md-3 p-2">
          <Link to={`/planejamento`} className="text-light">
            <Card bg="success">
              <Card.Header>Editar</Card.Header>
              <Card.Body>Meu Planejamento</Card.Body>
            </Card>
          </Link>
        </div>
        {compartilhamentos.map((compartilhamento) => (
          <div className="col-6 col-md-3 p-2" key={compartilhamento.user}>
            <Link
              to={`/planejamento/${compartilhamento.user._id}`}
              className="text-light"
            >
              <Card
                bg={
                  compartilhamento.permissao === "Editar"
                    ? "primary"
                    : "secondary"
                }
              >
                <Card.Header>{compartilhamento.permissao}</Card.Header>
                <Card.Body>
                  Planejamento de{" "}
                  {extrairPrimeiroNome(compartilhamento.user.nome)}
                </Card.Body>
              </Card>
            </Link>
          </div>
        ))}
      </div>
    </Container>
  );
}
