import React, { useEffect, useState } from "react";
import comidaApi from "../services/comida.api";
import PropTypes from "prop-types";
import Select from "react-select";
//import { propTypes } from "react-bootstrap/esm/Image";

function SelecaoCidade(props) {
  const [cidades, setCidades] = useState([]);
  const [carregado, setCarregado] = useState(false);

  useEffect(() => {
    if (!props.estado || !props.estado.value)
      return

    comidaApi.get(`cidades/${props.estado.value}`).then((cidades) => {
      if (cidades.data.length > 0) {
        setCidades(cidades.data);
        setCarregado(true);
      }
    });
  }, [props.estado]);

  return (
    <div className="row">
      <div className={props.className || "mb-3 col-md-6"}>
        <label className="w-100">
          Cidade
          <Select
            isDisabled={!carregado}
            placeholder="Em qual cidade você mora?"
            noOptionsMessage={() => "Sem opções"}
            value={props.value}
            name="cidade"
            options={cidades.map((c) => ({
              label: c.Nome,
              value: c.ID,
            }))}
            onChange={props.onChange}
          />
        </label>
      </div>
    </div>
  );
}

SelecaoCidade.propTypes = {
  estado: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default SelecaoCidade;
