import React from "react";
import { Helmet } from "react-helmet";
import Container from "./container";

export default function Politica() {
  return (
    <Container>
      <Helmet>
        <title>Política de Privacidade - caseira.app</title>
      </Helmet>
      <h3>POLÍTICA DE PRIVACIDADE</h3>
      <h4>SEÇÃO 1 - INFORMAÇÕES GERAIS</h4>
      <p className="text-justify">
        A presente Política de Privacidade contém informações sobre coleta, uso,
        armazenamento, tratamento e proteção dos dados pessoais dos usuários e
        visitantes do aplicativo caseira.app, com a finalidade de demonstrar
        absoluta transparência quanto ao assunto e esclarecer a todos
        interessados sobre os tipos de dados que são coletados, os motivos da
        coleta e a forma como os usuários podem gerenciar ou excluir as suas
        informações pessoais.
      </p>
      <p className="text-justify">
        Esta Política de Privacidade aplica-se a todos os usuários e visitantes
        do aplicativo caseira.app e integra os Termos e Condições Gerais de Uso
        do aplicativo caseira.app
      </p>
      <p className="text-justify">
        O presente documento foi elaborado em conformidade com a Lei Geral de
        Proteção de Dados Pessoais (
        <a href="https://www.jusbrasil.com.br/legislacao/612902269/lei-13709-18">
          Lei 13.709/18
        </a>
        ), o{" "}
        <a href="https://www.jusbrasil.com.br/legislacao/117197216/lei-12965-14">
          Marco Civil da Internet
        </a>{" "}
        (
        <a href="https://www.jusbrasil.com.br/legislacao/117197216/lei-12965-14">
          Lei 12.965/14
        </a>
        ) (e o Regulamento da UE n. 2016/6790). Ainda, o documento poderá ser
        atualizado em decorrência de eventual atualização normativa, razão pela
        qual se convida o usuário a consultar periodicamente esta seção.
      </p>
      <h4>
        SEÇÃO 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO E DO VISITANTE?
      </h4>
      <p className="text-justify">
        Os dados pessoais do usuário e visitante são recolhidos pela plataforma
        da seguinte forma:
      </p>
      <ul>
        <li>
          Quando o usuário cria uma conta/perfil na plataforma caseira.app esses
          dados são os dados de identificação básicos, como e-mail, nome
          completo, cidade e estado de residência. A partir deles, podemos
          identificar o usuário e o visitante, além de garantir uma maior
          segurança e bem-estar às suas necessidades. Ficam cientes os usuários
          e visitantes de que seu perfil na plataforma estará acessível a todos
          demais usuários da plataforma caseira.app.
        </li>
        <li>
          Quando um usuário e visitante acessa as páginas do da plataforma caseira.app: as informações sobre interação e acesso são
          coletadas pela empresa para garantir uma melhor experiência ao usuário
          e visitante. Estes dados podem tratar sobre as palavras-chaves
          utilizadas em uma busca, o compartilhamento de um documento
          específico, comentários, visualizações de páginas, perfis, a URL de
          onde o usuário e visitante provêm, o navegador que utilizam e seus IPs
          de acesso, dentre outras que poderão ser armazenadas e retidas.
        </li>
        <li>
          Por intermédio de terceiro: a plataforma caseira.app recebe dados de
          terceiros, como Google, Facebook e Twitter, quando um usuário faz
          login com o seu perfil de um desses sites. A utilização desses dados é
          autorizada previamente pelos usuários junto ao terceiro em questão.
        </li>
      </ul>
      <h4>
        SEÇÃO 3 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USUÁRIO E VISITANTE?
      </h4>
      <p className="text-justify">
        Os dados pessoais do usuário e visitante recolhidos são os seguintes:
      </p>
      <ul>
        <li>
          Dados para a criação da conta/perfil na plataforma caseira.app:
          e-mail, nome completo, cidade e estado de residência e tipo de
          alimentação.
        </li>
        <li>
          Dados para otimização da navegação: acesso as páginas, palavras-chave
          utilizadas na busca, recomendações, comentários, interação com outros
          perfis e usuários, perfis seguidos, endereço de IP.
        </li>
        <li>
          Newsletter: o e-mail cadastrado pelo visitante que optar por se
          inscrever na Newsletter será coletado e armazenado até que o usuário
          solicite o descadastro.
        </li>
      </ul>
      <h4>
        SEÇÃO 4 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO USUÁRIO E
        VISITANTE?
      </h4>
      <p className="text-justify">
        Os dados pessoais, do usuário e do visitante, coletados e armazenados
        pela plataforma caseira.app tem por finalidade:
      </p>
      <ul>
        <li>
          Bem-estar do usuário e visitante: aprimorar o produto e/ou serviço
          oferecido, facilitar, agilizar e cumprir os compromissos estabelecidos
          entre o usuário e a empresa, melhorar a experiência dos usuários e
          fornecer funcionalidades específicas a depender das características
          básicas do usuário.
        </li>
        <li>
          Melhorias da plataforma: compreender como o usuário utiliza os
          serviços da plataforma, para ajudar no desenvolvimento de negócios e
          técnicas.
        </li>
        <li>
          Comercial: os dados são usados para personalizar o conteúdo oferecido
          e gerarsubsídio à plataforma para a melhora da qualidade no
          funcionamento dos serviços.
        </li>
        <li>
          Previsão do perfil do usuário: tratamento automatizado de dados
          pessoais para avaliar o uso na plataforma.
        </li>
        <li>
          Dados de cadastro: para permitir o acesso do usuário a determinados
          conteúdos da plataforma, exclusivo para usuários cadastrados
        </li>
      </ul>
      <p className="text-justify">
        O tratamento de dados pessoais para finalidades não previstas nesta
        Política de Privacidade somente ocorrerá mediante comunicação prévia ao
        usuário, de modo que os direitos e obrigações aqui previstos permanecem
        aplicáveis.
      </p>
      <h4>SEÇÃO 5 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?</h4>
      <p className="text-justify">
        Os dados pessoais do usuário e visitante são armazenados pela plataforma
        durante o período necessário para a prestação do serviço ou o
        cumprimento das finalidades previstas no presente documento, conforme o
        disposto no inciso{" "}
        <font color="#4f81bd">
          <a href="https://www.jusbrasil.com.br/topicos/200399061/inciso-i-do-artigo-15-da-lei-n-13709-de-14-de-agosto-de-2018">
            I
          </a>
        </font>{" "}
        do artigo{" "}
        <font color="#4f81bd">
          <a href="https://www.jusbrasil.com.br/topicos/200399064/artigo-15-da-lei-n-13709-de-14-de-agosto-de-2018">
            15
          </a>
        </font>{" "}
        da Lei{" "}
        <font color="#4f81bd">
          <a href="https://www.jusbrasil.com.br/legislacao/612902269/lei-13709-18">
            13.709
          </a>
        </font>
        /18.
      </p>
      <p className="text-justify">
        Os dados podem ser removidos ou anonimizados a pedido do usuário,
        excetuando os casos em que a lei oferecer outro tratamento.
      </p>
      <p className="text-justify">
        Ainda, os dados pessoais dos usuários apenas podem ser conservados após
        o término de seu tratamento nas seguintes hipóteses previstas no artigo
        16 da referida lei:
      </p>
      <ol type="I">
        <li>Cumprimento de obrigação legal ou regulatória pelo controlador;</li>
        <li>
          Estudo por órgão de pesquisa, garantida, sempre que possível, a
          anonimização dos dados pessoais;
        </li>
        <li>
          Transferência a terceiro, desde que respeitados os requisitos de
          tratamento de dados dispostos nesta Lei;
        </li>
        <li>
          Uso exclusivo do controlador, vedado seu acesso por terceiro, e desde
          que anonimizados os dados.
        </li>
      </ol>
      <h4>SEÇÃO 6 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS</h4>
      <p className="text-justify">
        A plataforma se compromete a aplicar as medidas técnicas e organizativas
        aptas a proteger os dados pessoais de acessos não autorizados e de
        situações de destruição, perda, alteração, comunicação ou difusão de
        tais dados.
      </p>
      <p className="text-justify">
        Todos os dados são criptografados usando a tecnologia &quot;secure
        socket layer&quot; (SSL) que garante a transmissão de dados de forma
        segura e confidencial, de modo que a transmissão dos dados entre o
        servidor e o usuário ocorre de maneira cifrada e encriptada.
      </p>
      <p className="text-justify">
        A plataforma se exime de responsabilidade por culpa exclusiva de
        terceiro, como em caso de ataque de hackers ou crackers, ou culpa
        exclusiva do usuário, como no caso em que ele mesmo transfere seus dados
        a terceiros. O site se compromete a comunicar o usuário em caso de
        alguma violação de segurança dos seus dados pessoais.
      </p>
      <p className="text-justify">
        Os dados pessoais armazenados são tratados com confidencialidade, dentro
        dos limites legais. No entanto, podemos divulgar suas informações
        pessoais caso sejamos obrigados pela lei para fazê-lo ou se você violar
        nossos Termos de Serviço.
      </p>
      <h4>SEÇÃO 7 - COMPARTILHAMENTO DOS DADOS</h4>
      <p className="text-justify">
        Tendo em vista a preservação de sua privacidade, o caseira.app não compartilhará seus dados pessoais com nenhum terceiro não autorizado.
        O compartilhamento de dados do usuário ocorre com os dados
        referentes a publicações realizadas pelo próprio usuário, tais ações são
        compartilhadas publicamente com os outros usuários e visitantes da plataforma.
        Para a execução das atividades de pedido e entrega de produtos, sempre que necessário, nós poderemos compartilhar os seus dados pessoais com parceiros ou com órgãos reguladores.
        Jamais comercializamos dados pessoais.
      </p>
      <p className="text-justify">
        Os dados do perfil do usuário são compartilhados publicamente em
        sistemas de busca e dentro da plataforma.
      </p>
      <p className="text-justify">
        Os dados do perfil do usuário, informações pessoas, endereço, itens do pedido são compartilhados com parceiros para realização do processamento e entrega do pedido.
      </p>
      <h4>
        SEÇÃO 8 - OS DADOS PESSOAIS ARMAZENADOS SERÃO TRANSFERIDOS A TERCEIROS?
      </h4>
      <p className="text-justify">
        Somente os dados necessários para processamento e entrega de pedidos feitos pelo usuário para parceiros específicos serão transferidos.
      </p>
      <h4>SEÇÃO 9 – COOKIES OU DADOS DE NAVEGAÇÃO</h4>
      <p className="text-justify">
        Os cookies referem-se a arquivos de texto enviados pela plataforma ao
        computador do usuário e visitante e que nele ficam armazenados, com
        informações relacionadas à navegação no site. Tais informações são
        relacionadas aos dados de acesso como local e horário de acesso e são
        armazenadas pelo navegador do usuário e visitante para que o servidor da
        plataforma possa lê-las posteriormente a fim de personalizar os serviços
        da plataforma.
      </p>
      <p className="text-justify">
        O usuário e o visitante da plataforma caseira.app manifesta conhecer e
        aceitar que pode ser utilizado um sistema de coleta de dados de
        navegação mediante a utilização de cookies.
      </p>
      <p className="text-justify">
        O cookie persistente permanece no disco rígido do usuário e visitante
        depois que o navegador é fechado e será usado pelo navegador em visitas
        subsequentes ao site. Os cookies persistentes podem ser removidos
        seguindo as instruções do seu navegador. Já o cookie de sessão é
        temporário e desaparece depois que o navegador é fechado. É possível
        redefinir seu navegador da web para recusar todos os cookies, porém
        alguns recursos da plataforma podem não funcionar corretamente se a
        capacidade de aceitar cookies estiver desabilitada.
      </p>
      <h4>SEÇÃO 10 - CONSENTIMENTO</h4>
      <p className="text-justify">
        Ao utilizar os serviços e fornecer as informações pessoais na
        plataforma, o usuário está consentindo com a presente Política de
        Privacidade.
      </p>
      <p className="text-justify">
        O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar seus
        direitos de cancelar seu cadastro, acessar e atualizar seus dados
        pessoais e garante a veracidade das informações por ele
        disponibilizadas.
      </p>
      <p className="text-justify">
        O usuário tem direito de retirar o seu consentimento a qualquer tempo,
        para tanto deve entrar em contato através do e-mail dados@caseira.app.
      </p>
      <h4>SEÇÃO 11 – EXCLUSÃO DE DADOS</h4>
      <p className="text-justify">
        O usuário, caso deseje excluir seus dados da plataforma, pode realizar
        a solicitação através da página de seu perfil. Para solicitar a exclusão de dados
        utilize uma das opções abaixo:</p>
      <p>1. Acessar: <a href="https://www.caseira.app/usuarios/editar">https://www.caseira.app/usuarios/editar</a> e clicar em &quot;Excluir Conta&quot;.
        <br />
        2. Enviar um email para: <a href="mailto:dados@caseira.app">dados@caseira.app</a> solicitando a exclusão de dados.
      </p>
      <p className="text-justify">
        Ao selecionar esta opção, seus dados serão excluídos imediatamente e não será mais
        possível realizar acesso personalizado na plataforma nem utilizar os recursos
        exclusivos de usuários cadastrados.
      </p>
      <h4>SEÇÃO 12 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</h4>
      <p className="text-justify">
        Reservamos o direito de modificar essa Política de Privacidade a
        qualquer momento, então, é recomendável que o usuário e visitante
        revise-a com frequência.
      </p>
      <p className="text-justify">
        As alterações e esclarecimentos vão surtir efeito imediatamente após sua
        publicação na plataforma. Quando realizadas alterações os usuários serão
        notificados. Ao utilizar o serviço ou fornecer informações pessoais após
        eventuais modificações, o usuário e visitante demonstra sua concordância
        com as novas normas.
      </p>
      <p className="text-justify">
        Diante da fusão ou venda da plataforma à outra empresa os dados dos
        usuários podem ser transferidas para os novos proprietários para que a
        permanência dos serviços oferecidos.
      </p>
      <h4>SEÇÃO 13 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS</h4>
      <p className="text-justify">
        Para a solução de controvérsias decorrentes do presente instrumento será
        aplicado integralmente o Direito brasileiro.
      </p>
      <p className="text-justify">
        Os eventuais litígios deverão ser apresentados no foro da comarca em que
        se encontra a sede da empresa.
      </p>
      <p className="text-justify">Jundiaí, São Paulo</p>
    </Container>
  );
}
