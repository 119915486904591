import React from "react";
import comidaApi from "../../services/comida.api";
import TableRow from "./tableRow.receita.ingredientes";
import { Link } from "react-router-dom";
import CardImagemReceita from "./card.imagem.receita";
import PropTypes from "prop-types";
import {  toast } from "react-toastify";
import { Button } from "react-bootstrap";
import AdicionarPlano from "../planejamento/add.plano";
import User from "../../models/user";
import {
  faCalendarAlt,
  faCarrot,
  faClock,
  faListOl,
  faPencilAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from "../container";
import ListaTipoAlimentacao from "../listaTipoAlimentacao";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { consultarReceita } from "../../reducers";

const user = new User();

class DetailReceita extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adicionando: false,
      receita: { ...this.props.dadosIniciais },
      refeicoes: [],
    };
    this.planoCriado = this.planoCriado.bind(this);
  }

  componentDidMount() {
    if (!this.props.dadosIniciais._id) this.atualizarDados();
  }

  atualizarDados() {
    comidaApi
      .get("receitas/" + this.props.match.params.id)
      .then((response) => {
        this.setState({ receita: response.data });
      })
      .catch(() => {
        toast.warn(
          `Houve um erro ao consultar a receita. Por favor, tente novamente mais tarde.`
        );
      });

    comidaApi
      .get("refeicoes/")
      .then((response) => {
        this.setState({
          refeicoes: response.data,
        });
      })
      .catch((error) => {
        if (error.response?.status === 401) this.props.history.push("/");
        else
          toast.warn("Houve um erro ao consultar seu planejamento");
      });
  }

  getTableRow() {
    if (this.state.receita.ingredientes)
      return this.state.receita.ingredientes.map(function (object, i) {
        return <TableRow className="list-group-item" obj={object} key={i} />;
      });
    else return null;
  }

  planoCriado() {
    this.props.history.push("/planejamento");
  }

  getReceita() {
    let tempoPreparo = null;
    let criadoPor = null;
    let modificadoPor = null;
    let rendimento = null;
    let detalhes = null;

    if (this.state.receita.referencia) {
      const site = this.transformarReferencia();
      criadoPor = (
        <p>
          Receita por:{" "}
          <a href={site.url} target="_blank" rel="noopener noreferrer">
            {site.texto}
          </a>
        </p>
      );
    } else if (this.state.receita.criadoPor)
      criadoPor = (
        <p>
          Receita por:{" "}
          <Link to={`/usuarios/${this.state.receita.criadoPor?._id}`}>
            {this.state.receita.criadoPor?.nome ||
              this.state.receita.criadoPor?.email}
          </Link>
        </p>
      );
    if (this.state.receita.modificadoPor)
      modificadoPor = (
        <p>
          Atualizada por:{" "}
          <Link to={`/usuarios/${this.state.receita.modificadoPor?._id}`}>
            {this.state.receita.modificadoPor?.nome ||
              this.state.receita.modificadoPor?.email}
          </Link>
        </p>
      );
    if (this.state.receita.tempoPreparo)
      tempoPreparo = (
        <p>
          <FontAwesomeIcon icon={faClock} /> Tempo de Preparo:{" "}
          {this.state.receita.tempoPreparo} <small>minutos</small>
        </p>
      );
    if (this.state.receita.rendimento)
      rendimento = (
        <p>
          <FontAwesomeIcon icon={faUsers} /> Serve:{" "}
          {this.state.receita.rendimento} <small>pessoas</small>
        </p>
      );

    if (this.state.receita.ingredientes?.length > 0)
      detalhes = (
        <div>
          <h6>
            <FontAwesomeIcon icon={faCarrot} /> Ingredientes
          </h6>
          <ul className="list-group list-group-flush">{this.getTableRow()}</ul>
          {this.state.receita.preparo &&
          this.state.receita.preparo.length > 0 ? (
            <div>
              <h6>
                <FontAwesomeIcon icon={faListOl} /> Modo de Preparo{" "}
              </h6>
              <ol>
                {this.state.receita.preparo.split("\n").map((p, i) => {
                  return <li key={i}>{p}</li>;
                })}
              </ol>
            </div>
          ) : null}
        </div>
      );
    else
      detalhes = (
        <div className="bg-primary text-light p-3">
          Sabe preparar este item? Então compartilha com a gente.{" "}
          <Link
            className="text-warning"
            to={`/receitas/editar/${this.state.receita.slug}`}
          >
            Clique aqui
          </Link>
        </div>
      );

    return (
      <div className="col-lg-7">
        <Button
          variant="success"
          className={`m-2`}
          onClick={() => {
            if (user.IsLoggedin) this.setState({ adicionando: true });
            else
              this.props.history.push(
                `/login?q=${this.props.location.pathname}`
              );
          }}
        >
          <FontAwesomeIcon icon={faCalendarAlt} className="me-3" />
          Planejar
        </Button>
        <AdicionarPlano
          className="col mt-2"
          visivel={this.state.adicionando}
          refeicoes={this.state.refeicoes}
          user={user.Information.user}
          receitasIniciais={[
            {
              value: this.state.receita._id,
              label: this.state.receita.nome,
              quantidade: 1,
            },
          ]}
          aoOcultar={() => {
            this.setState({
              adicionando: false,
            });
          }}
          planoCriado={this.planoCriado}
        />
        {criadoPor}
        {modificadoPor}
        {tempoPreparo}
        {rendimento}
        {detalhes}
      </div>
    );
  }

  transformarReferencia() {
    const regexRedeSocial = /(@|\/)?([\w.]+$)/gm;
    const regexDominio = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
    const protocolo = /https?:\/\//;
    let referencia = this.state.receita.referencia;
    const regexReferenciaSocial = regexRedeSocial.exec(referencia);
    const referenciaInternet = regexDominio.exec(referencia);

    const referenciaSocial = regexReferenciaSocial
      ? regexReferenciaSocial[2]
      : "";

    switch (this.state.receita.tipoReferencia) {
      case "instagram":
        return {
          url: `https://instagram.com/${referenciaSocial}`,
          texto: referenciaSocial,
        };
      case "facebook":
        return {
          url: `https://facebook.com/${referenciaSocial}`,
          texto: referenciaSocial,
        };
      case "internet":
        var uri = {
          url: `http://${referencia.replace(protocolo, "")}`,
          texto: referenciaInternet,
        };
        return uri;
      default:
        if (referencia.includes("@"))
          return {
            url: `https://instagram.com/${referenciaSocial}`,
            texto: referenciaSocial,
          };
        if (referencia.includes("facebook"))
          return {
            url: `https://facebook.com/${referenciaSocial}`,
            texto: referenciaSocial,
          };
        return {
          url: `https://duckduckgo.com/?q=${referencia}`,
          texto: referencia,
        };
    }
  }

  render() {
    const mensagemCompartilhamento = `Experimente essa receita de ${this.state.receita.nome}`;
    const hashtagCompartilhamento = `#caseiraapp ${
      this.state.receita.nome && "#" + this.state.receita.nome.replace(" ", "")
    }`;
    const descricao =
      this.state.receita.preparo ||
      "Aprenda essa e outras receitas, planeje sua alimentação e tenha sua lista de compras gerada automaticamente. É grátis.";

    return (
      <Container>
        <Helmet>
          <meta
            property="og:title"
            content={`Receita de ${this.state.receita?.nome} - caseira.app`}
          />
          <meta name="description" content={descricao} />
          <meta property="og:description" content={descricao} />
          <meta
            property="og:image"
            content={
              this.state.receita.foto || "https://www.caseira.app/maincard.jpg"
            }
          />
          <meta
            property="og:image:alt"
            content={`Foto da receita de ${this.state.receita?.nome}`}
          />
          <meta property="og:image:type" content="image/jpeg" />
          <meta
            property="og:url"
            content={`https://caseira.app${this.props.location.pathname}`}
          />
          <title>
            {this.state.receita
              ? `Receita de ${this.state.receita.nome} - caseira.app`
              : `Receita - caseira.app`}
          </title>
        </Helmet>
        
        <h5>
          {this.state.receita.nome}{" "}
          <Link
            to={`/receitas/editar/${this.state.receita.slug}`}
            className="btn btn-light text-secondary"
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Link>
        </h5>
        <div>
          <small className="text-secondary">Compartilhar </small>
          <FacebookShareButton
            url={typeof window != "undefined" ? window.location.href : null}
            quote={mensagemCompartilhamento}
            hashtag={hashtagCompartilhamento}
          >
            <FacebookIcon size="24" round={true} />
          </FacebookShareButton>
          <WhatsappShareButton
            url={typeof window != "undefined" ? window.location.href : null}
            title={mensagemCompartilhamento}
          >
            <WhatsappIcon size="24" round={true} />
          </WhatsappShareButton>
        </div>
        <ListaTipoAlimentacao values={this.state.receita.tipoAlimentacao} />
        <div className="row">
          <div className="p-3 col-lg-5 text-center">
            <CardImagemReceita
              imagem={this.state.receita.foto}
              style={{
                objectFit: "cover",
                maxHeight: "100%",
                maxWidth: "100%",
              }}
            />
          </div>
          {this.getReceita()}
        </div>
      </Container>
    );
  }
}

DetailReceita.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  dadosIniciais: PropTypes.object,
};

DetailReceita.consultarServidor = consultarReceita;

const mapStateToProps = (state) => ({
  dadosIniciais: state.receita,
});

const mapDispatchToProps = { consultarReceita };

export default connect(mapStateToProps, mapDispatchToProps)(DetailReceita);
